.dieu-khoan
	.wrap-dieu-khoan
		ul
			margin-top: 15px
			margin-bottom: 15px
			li
				&+li
					margin-top: 8px
		p
			strong
				font-size: r(20px)
				color: #974806
