.hop-tac
	background-color: #fff
	.tabslet-tab
		display: flex
		align-items: center
		justify-content: center
		list-style: none
		margin: 0
		padding: 0
		@media screen and ( max-width: 767.67px )
			overflow: auto
			justify-content: normal
		li
			&.active
				a
					background-color: $main
					color: white
			&+li
				margin-left: 30px
				@media screen and ( max-width: 575px )
					margin-left: 5px
			a
				width: 250px
				height: 50px
				border-radius: 40px 0
				background-color: rgba($main, .1)
				color: #00aeef
				font-size: 1rem
				font-weight: 400
				display: flex
				align-items: center
				justify-content: center
				transition: .3s all ease-in-out
				&:hover
					background-color: rgba($main, 1)
					color: white
	.wrap-box-select
		display: flex
		margin-top: 33px
		justify-content: space-between
		border-bottom: 1px solid #e1e1e1
		padding-bottom: 2px
		@media screen and ( max-width: 1023.67px )
			flex-wrap: wrap
			padding-bottom: 10px
		@media screen and ( max-width: 575px )
			border: 1px dashed $green
			padding: 10px
		.wrap-left
			display: flex
			@media screen and ( max-width:1023.67px )
				flex: 0 0 100%
				order: 2
				margin-top: 5px
			.get-dir-button
				display: none
			.wrap-form,.wrap-select
				display: flex
				align-items: baseline
				@media screen and ( max-width: 575px )
					flex-wrap: wrap
				.select-city
					position: relative
					margin-right: 100px
					padding-right: 20px
					&::before
						content: 'arrow_drop_down'
						font-family: "Material Icons"
						color: #999999
						font-size: 24px
						position: absolute
						top: 50%
						transform: translateY(-50%)
						right: 0
					select
						color: $text
						font-size: r(14px)
						font-weight: 600
						border: 0px
						padding-right: 20px
						appearance: none
				.select-quan
					position: relative
					padding-right: 20px
					&::before
						content: 'arrow_drop_down'
						font-family: "Material Icons"
						color: #999999
						font-size: 24px
						position: absolute
						top: 50%
						transform: translateY(-50%)
						right: 0
					select
						color: #999999
						font-size: r(14px)
						font-weight: 400
						border: 0
						outline: 0
						padding-right: 20px
						appearance: none
		.wrap-right
			@media screen and ( max-width:1023.67px )
				flex: 0 0 100%
				margin-top: 15px
				order: 1
			a
				display: flex
				em,span
					color: $green
				em
					font-size: r(18px)
					font-weight: 400
					padding-top: 3px
				span
					color: $green
					font-size: r(18px)
					font-weight: 700
					margin-left: 8px
					text-transform: uppercase
					@media screen and ( max-width: 575px )
						font-size: r(14px)
	.wrap-address
		.full-content
			overflow: hidden
			position: relative
			&>ul
				display: flex!important
				flex-wrap: wrap
				margin: 0 -15px
				padding: 0
				list-style: none
				&>li
					flex: 0 0 33.33%
					max-width: 33.33%
					padding: 0 15px
					@media screen and ( max-width: 1280px )
						flex: 0 0 50%
						max-width: 50%
					@media screen and ( max-width: 767.67px )
						flex: 0 0 100%
						max-width: 100%
		.box-address,.item
			height: 110px
			box-shadow: -8px 0 46px rgba(0, 0, 0, 0.09)
			border: 1px dashed $green
			background-color: #ffffff
			display: flex
			margin-top: 30px
			@media screen and ( max-width: 575px )
				flex-wrap: wrap
				height: auto
				padding: 7px 0px
				padding-top: 0
			.img
				flex: 0 0 100px
				max-width: 100px
				display: flex
				align-items: center
				justify-content: center
				padding: 5px
				background-color: #f3f3f3
				@media screen and ( max-width: 575px )
					flex: 0 0 100%
					max-width: 100%
					padding: 10px 0px
					margin-bottom: 15px
				img
					max-width: 100%
					max-height: 100%
					object-fit: contain
			.content
				display: flex
				flex-direction: column
				justify-content: center
				padding-left: 18px
				@media screen and ( max-width: 575px )
					flex: 0 0 100%
					max-width: 100%
				ul
					margin: 0
					padding: 0
					list-style: none
			.title
				color: $main
				font-size: 1rem
				font-weight: 700
				text-transform: uppercase
				transition: .3s all ease-in-out
				margin-bottom: 5px
				cursor: pointer
				@media screen and ( max-width: 575px )
					font-size: 0.9rem
				&:hover
					color: $green
			li
				display: flex
				align-items: baseline
				&+li
					margin-top: 5px
				em
					color: #00aeef
					font-size: r(14px)
					font-weight: 400
					margin-right: 10px
				span
					color: #666666
					font-size: 1rem
					font-weight: 400
	#map
		display: none
