.home-4
	padding-top: 13px
	padding-bottom: 80px
	position: relative
	background-color: #fff
	z-index: 2
	&::before
		content: ''
		background-image: url(../img/h4-4.png)
		position: absolute
		left: 0
		top: -40%
		width: 183px
		height: 241px
		pointer-events: none
		z-index: -1
		@media screen and ( max-width: 1600px )
			top: 0

	&::after
		content: ''
		width: 509px
		height: 509px
		background-image: url(../img/h4-3.png)
		position: absolute
		right: 0
		bottom: 0
		pointer-events: none
		z-index: -1
	.desc
		color: $text
		font-size: r(18px)
		text-align: center
		font-style: italic
		line-height: 24px
		line-height: normal
	.wrap-title-icon
		.icon
			margin-bottom: 12px
	.wrap-product-main
		margin-top: 40px
		.col-lg-6
			&:last-child
				.box-text
					color: white
		.box-product
			border-radius: 0 0 60px
			overflow: hidden
			position: relative
			margin-bottom: 30px
			.box-text
				color: #042070
				font-size: r(18px)
				font-weight: 700
				font-style: italic
				line-height: 24px
				position: absolute
				top: 25%
				left: 43px
				text-shadow: 2px 2px 5px #00000026
				max-width: 203px
				@media screen and ( max-width: 575px )
					left: 25px
			.icon-la
				width: 80px
				height: 80px
				border-radius: 60px 0
				background-color: $green
				display: flex
				align-items: center
				justify-content: center
				position: absolute
				bottom: 0
				cursor: pointer
				right: 0
				transition: .3s all ease-in-out
				&:hover
					background-color: $main
				em
					color: #ffffff
					font-size: 28px
					font-weight: 400
					transform: rotate(-45deg)
			.img
				a
					display: block
					height: 260px
					@media screen and ( max-width: 767.67px )
						height: 200px
					img
						width: 100%
						height: 100%
						object-fit: cover
