.home-7
	background-color: #fff
	@media screen and ( min-width: 1280px )
		padding-top: r(42px)
	.swiper-wrapper
		align-items: flex-end!important
	.desc
		color: $text
		font-size: r(18px)
		font-weight: 400
		text-align: center
		display: block
	.wrap-title
		padding-bottom: 30px
		// margin-bottom: 50px
		.title-36
			margin-bottom: 5px
		@media screen and ( max-width: 767.67px )
			margin-bottom: 25px
	.wrap-slide
		border-bottom: 1px solid #ebebeb
		padding-top: 40px
		padding-bottom: 54px
		border-top: 1px solid #ebebeb
		@media screen and ( max-width: 767.67px )
			padding-bottom: 20px
			padding-top: 20px
		.swiper-slide
			.img
				// &:hover
				// 	img
				// 		filter: grayscale(0)
				img
					// filter: grayscale(100%)
					transition: .3s all ease-in-out
