.home-2
	.wrap-box-flex
		display: flex
		flex-wrap: wrap
		.box-left,.box-right
			flex: 0 0 50%
			max-width: 50%
			height: 540px
		.box-left
			position: relative
			[class*="Module"]
				height: 100%
			@media screen and ( max-width: 1280px )
				flex: 0 0 30%
				max-width: 30%
			@media screen and ( max-width: 767.67px )
				flex: 0 0 100%
				max-width: 100%
				order: 2
				height: auto
			.img
				height: 100%
				position: relative
				&::before
					content: ''
					position: absolute
					left: 0
					pointer-events: none
					top: 0
					width: 100%
					height: 100%
					background-color: #00000080
				a
					display: block
					height: 100%
					width: 100%
					img
						width: 100%
						height: 100%
						object-fit: cover
			.wrap-icon
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%, -50%)
				display: flex
				flex-direction: column
				align-items: center
				width: 70%
				padding: 0 15px
				pointer-events: none
				@media screen and ( max-width: 1280px )
					width: 100%
				em
					color: #ffffff
					font-size: r(80px)
					font-weight: 400
				.text
					color: #ffffff
					font-size: r(24px)
					font-weight: 400
					line-height: 1.2
					text-align: center
					margin-top: 10px
		.box-right
			display: flex
			align-items: center
			padding-left: 90px
			padding-right: 210px
			[class*="Module"]
				width: 100%
			@media screen and ( max-width: 1280px )
				flex: 0 0 70%
				max-width: 70%
			@media screen and ( max-width: 1600px )
				padding-right: 90px
			@media screen and ( max-width: 1023.67px )
				padding-left: 30px
				padding-right: 30px
			@media screen and ( max-width: 767.67px )
				flex: 0 0 100%
				max-width: 100%
				order: 1
				height: auto
				padding: 30px 20px
				padding-right: 20px!important
	.wrap-slide
		max-width: 660px
		width: 100%
		@media screen and ( max-width: 767.67px )
			max-width: 100%
			padding: 0 15px
		.title-36
			margin-bottom: 22px
			text-align: left
		.box-content
			height: 280px
			border-radius: 35px
			background-color: #ffffff
			position: relative
			@media screen and ( max-width: 575px )
				height: 220px
			.img
				height: 67%
				display: flex
				align-items: center
				justify-content: center
				padding: 0 20px
				img
					width: 100%
					height: 70px
					object-fit: contain
			.text
				color: $text
				font-size: r(18px)
				font-weight: 400
				text-align: center
				line-height: 1.3
				padding: 0 15%
				@media screen and ( max-width: 575px )
					font-size: 1.1rem
					padding: 0 5px
			.icon-la
				width: 40px
				height: 40px
				border-radius: 35px 0
				background-color: $main
				display: flex
				align-items: center
				justify-content: center
				position: absolute
				bottom: 0
				right: 0
				transition: .3s all ease-in-out
				&:hover
					background-color: $green
				em
					transform: rotate(-45deg)
