.page-tai-lieu
	background-color: #fff
	.box-right
		@media screen and ( max-width: 1023.67px )
			border-top: 1px dashed #eaeaea
			margin-top: 30px
			padding-top: 15px
	.content
		box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.08)
		border: 1px solid #eeeeee
		background-color: #ffffff
		padding: 0 30px
		margin-top: 30px
		padding-top: 5px
		ul
			margin: 0
			padding: 0
			list-style: none
		li
			padding-left: 19px
			a
				display: flex
				justify-content: space-between
				align-items: center
				padding: 11px 0px
				&:hover
					span
						color: $green
					em
						color: $green
			&+li
				border-top: 1px solid #e1e1e1
			span
				color: #111111
				font-size: r(18px)
				font-weight: 400
				transition: .3s all ease-in-out
			em
				color: #999999
				font-size: r(18px)
				transition: .3s all ease-in-out
