.tin-tuc-ct
	background-color: #fff
	.title-post
		color: #111111
		font-size: r(30px)
		font-weight: 700
		padding-bottom: 15px
		border-bottom: 1px solid $main
	.full-content
		p
			color: $text
			font-size: 1rem
			font-weight: 400
			line-height: 24px
			margin-top: 20px
		a
			color: #00a7e6
		strong
			*
				font-size: r(20px)!important
				font-weight: 700
		h1,h2,h3,h4,h5
			color: $main
			margin-top: 20px
		h1
			font-size: r(30px)  !important
		h2
			font-size: r(26px) !important
		h3
			font-size: r(22px) !important
		h4
			font-size: r(20px) !important
		h5
			font-size: r(18px) !important
	.box-right
		@media screen and ( max-width: 1023.67px )
			margin-top: 20px
			border-top: 1px dashed #eaeaea
			padding-top: 20px
		.title-other
			color: $main
			font-size: r(22px)
			font-weight: 700
			text-transform: uppercase
			margin-bottom: 30px
		ul
			margin: 0
			padding: 0
			list-style: none
		.box-post-other
			box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.08)
			background-color: #f8f8f8
			padding: 20px
			margin-bottom: 20px
			display: flex
			.img
				flex: 0 0 90px
				max-width: 90px
				a
					display: block
					width: 100%
					height: 100%
					img
						width: 100%
						height: 100%
						object-fit: cover
			.content
				padding-left: 15px
			.date
				color: #999999
				font-size: r(12px)
				font-weight: 400
				letter-spacing: 1.2px
				display: block
				margin-bottom: 10px
			.title
				color: #00001a
				font-size: 1rem
				font-weight: 700
				line-height: 1.2
				+rgtext(3)
				transition: .3s all ease-in-out
				&:hover
					color: $green
