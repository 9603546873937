.home-6
	background-color: rgba( #e6f8ff, 1)
	@media screen and ( min-width: 1280px )
		padding-top: r(44px)
	.swiper-container
		.swiper-wrapper
			padding: 0 20px
			@media screen and ( max-width: 575px )
				padding: 0px
	.wrap-box-flex
		display: flex
		margin-top: 40px
		@media screen and ( max-width: 575px )
			flex-wrap: wrap
		.box-img
			flex: 0 0 180px
			@media screen and ( max-width: 575px )
				flex: 0 0 100%
				margin-bottom: 40px
				display: flex
				justify-content: center
			.img
				width: 140px
				height: 140px
				border-radius: 100%
				position: relative
				z-index: 2
				&::before
					content: ''
					position: absolute
					top: 56px
					left: -31px
					width: 162px
					height: 88px
					transform: rotate(-147deg)
					background-color: white
					border: 6px solid $main
					border-top-left-radius: 100%
					border-top-right-radius: 100%
					border-bottom: 0px
					border-top-left-radius: 100px
					border-top-right-radius: 100px

					z-index: -1
				img
					width: 100%
					height: 100%
					border: 2px solid $main
					border-radius: 100%
					object-fit: cover
		.content
			flex: 1
			@media screen and ( max-width: 575px )
				flex: 0 0 100%
			.title-name
				margin-bottom: 15px
				.title
					font-size: r(24px)
					font-weight: 700
					color: $main
				.pos
					font-size: r(16px)
					font-weight: 400
					color: $main
			.desc
				color: $text
				font-size: r(18px)
				font-weight: 400
				line-height: 24px
				padding-right: 20px
