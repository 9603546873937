.tuyen-dung-ds-2
	background-color: #e2f3ff
	.full-content
		overflow: hidden
	.title-36
		@media screen and ( max-width: 575px)
			text-align: right
	.desc
		color: $text
		font-size: r(18px)
		font-weight: 400
		line-height: 24px
		max-width: 80%
		margin: 0 auto
		text-align: center
		margin-top: 10px
		margin-bottom: 20px
		@media screen and ( max-width: 767.67px )
			max-width: 100%
		@media screen and ( max-width: 575px )
			text-align: right
	.box-tuyen-dung
		padding: 13px 20px
		height: 110px
		// box-shadow: -8px 0 46px rgba(0, 0, 0, 0.09)
		border: 1px dashed #00aeef
		background-color: #ffffff
		margin-bottom: 20px
		@media screen and ( max-width: 575px )
			height: auto
		&:hover
			.title-td
				color: $main
			.icon-view
				em
					color: $main!important
		.title-td
			color: $text
			font-size: 1rem
			font-weight: 700
			text-transform: uppercase
			transition: .3s all ease-in-out
			margin-bottom: 5px
			display: block
			&:hover
				color: $main
		.wrap-location
			display: flex
			align-items: center
			margin-bottom: 6px
			@media screen and ( max-width: 575px )
				flex-wrap: wrap
			.location
				display: flex
				align-items: center
				@media screen and ( max-width: 575px )
					flex: 0 0 100%
				em
					color: #999999
					font-size: 1rem
					font-weight: 400
				span
					color: #999999
					font-size: r(15px)
					font-weight: 400
					margin-left: 6px
			.date
				display: flex
				align-items: center
				margin-left: 40px
				@media screen and ( max-width: 575px )
					flex: 0 0 100%
					margin-left: 0px
					margin-top: 6px
				em
					color: #999999
					font-size: 16px
					font-weight: 400
				span
					color: #999999
					font-size: r(15px)
					margin-left: 6px
		.wrap-salary
			display: flex
			justify-content: space-between
			align-items: center
			.icon-view
				em
					padding-top: 6px
					color: $green
					font-size: 16px
					font-weight: 400
					transition: .3s all ease-in-out
			.salary
				display: flex
				align-items: center
				em
					color: #00aeef
					font-size: 1rem
					font-weight: 400
				span
					color: #00aeef
					font-size: r(15px)
					font-weight: 400
					margin-left: 6px
	.wrap-button-drop
		margin-top: 14px
