ol.breadcrumb
	display: flex
	padding: 10px 0
	align-items: center
	list-style: none
	padding-left: 0
	border-bottom: 1px solid #e1e1e1
	li
		display: flex
		position: relative
		align-items: center
		padding: 0 10px
		a
			font-weight: 400
			font-size: r(14px)
			color: rgba(255, 255, 255, 0.7)!important
			&.active
				color: #999999
		&:first-child
			padding-left: 0
			a
				font-size: 0
				&::before
					font-family: "Material Icons"
					content: 'home'
					font-size: r(24px)
					color: rgba(255, 255, 255, 0.7)
					text-transform: none
		+ li
			&::before
				font-family: "Material Icons"
				content: "arrow_right"
				top: 50%
				transform: translateY(-50%)
				left: -9px
				position: absolute
				color: rgba(255, 255, 255, 0.7)
				font-size: r(20px)
