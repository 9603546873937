.tuyen-dung-ct
	background-color: #fff
	.wrap-button-ut
		margin-top: 30px
		display: flex
		@media screen and ( max-width: 575px )
			flex-wrap: wrap
		.button-ut
			padding-right: 48px
			padding-left: 30px
			min-width: 210px
			height: 50px
			border-radius: 40px 0
			background-color: #d8efff
			display: flex
			align-items: center
			justify-content: center
			position: relative
			cursor: pointer
			transition: .3s all ease-in-out
			@media screen and ( max-width: 575px )
				flex: 0 0 100%
				width: 100%
			&:hover
				transform: translateY(-3px)
			&:last-child
				background-color: #eaf5e3
				.icon-la
					background-color: $green
			&+.button-ut
				margin-left: 30px
				@media screen and ( max-width: 575px )
					margin-left: 0
					margin-top: 30px
			span
				color: #111111
				font-size: 18px
				font-weight: 400
			.icon-la
				width: 40px
				height: 40px
				border-radius: 35px 0
				background-color: $main
				position: absolute
				right: 5px
				top
					0
				em
					transform: rotate(-45deg)
	.section-title
		color: #111111
		font-size: r(30px)
		font-weight: 700
		padding-bottom: 17px
		border-bottom: 1px solid $main
	.box-infomation
		tr
			td
				&:first-child
					color: #0a0a0a
					font-size: 1rem
					font-weight: 700
			td
				&:last-child
					color: #666666
					font-size: 1rem
					font-weight: 400
	.full-content
		margin-top: 30px
		.img
			width: 100%
			height: 100%

			img
				width: 100%
				height: 100%
				object-fit: cover
		.box-infomation
			padding-left: 26px
			margin-top: -9px
			@media screen and ( max-width: 1023.67px )
				margin-top: 15px
				border-top: 1px dashed #eaeaea
				border-bottom: 1px dashed #eaeaea
				padding: 10px 0px
			table
				td
					padding: 6px 0px
					@media screen and ( max-width: 1023.67px )
						padding: 3px 0px
					&:last-child
						padding-left: 53px
		.content
			margin-top: 66px
			@media screen and ( max-width: 1023.67px )
				margin-top: 30px
			.section-title
				margin-top: 60px
				@media screen and ( max-width: 1023.67px )
					margin-top: 30px
			ul
				margin: 0
				padding: 0
				padding-top: 23px
				padding-left: 18px
				li
					color: $text
					font-size: 1rem
					font-family: 'Libre Franklin', sans-serif
					font-weight: 400
					letter-spacing: 0.8px
					&+li
						margin-top: 10px
					&::marker
						color: #999999
						font-size: 20px
	.box-left
		position: sticky
		top: 120px
		@media screen and ( max-width: 1280px )
			border-top: 1px dashed #eaeaea
			padding-top: 20px
			margin-top: 20px
		.title-sec
			color: $main
			font-size: r(22px)
			font-weight: 700
			text-transform: uppercase
			margin-bottom: 15px
		.box-tuyen-dung
			height: 110px
			box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.08)
			background-color: #f8f8f8
			padding: 20px
			margin-bottom: 20px
			.title
				color: $text
				font-size: 1rem
				font-weight: 700
				text-transform: uppercase
				margin-bottom: 5px
				display: block
				transition: .3s all ease-in-out
				&:hover
					color: $main
			&+.box-tuyen-dung
				margin-top: 20px
			.wrap-share-date
				display: flex
				justify-content: space-between
				em
					color: #999999
					font-size: 1rem
					font-weight: 400
				span
					color: #999999
					font-size: r(15px)
					font-weight: 400
					margin-left: 6px
				.location
					display: flex
					align-items: center
				.date
					display: flex
					align-items: center
			.salary
				display: flex
				align-items: center
				margin-top: 6px
				em
					color: #00aeef
					font-size: 1rem
					font-weight: 400
				span
					color: $main
					color: #00aeef
					font-size: r(15px)
					margin-left: 6px
					font-weight: 400
