.button-hotline
	display: flex
	width: 170px
	height: 40px
	border-radius: 30px 0
	background-color: rgba(#72bf44, .15)
	align-items: center
	padding-right: 20px
	padding-left: 4px
	overflow: hidden
	margin-left: 19px
	position: relative
	z-index: 2
	// &:hover
	// 	&::before
	// 		width: 100%
	// &::before
	// 	content: ''
	// 	position: absolute
	// 	top: 0
	// 	left: 0
	// 	width: 0
	// 	height: 100%
	// 	z-index: -1
	// 	background-color: #d8efff
	// 	transition: .3s all ease-in-out
	&.blue
		background-color: #d8efff
		.icon-la
			background-color: $main
	a
		color: #333333
		font-size: 1rem
		font-weight: 700
		margin-left: 11px
.icon-la
	width: 32px
	height: 32px
	border-radius: 25px 0
	display: flex
	align-items: center
	justify-content: center
	background-color: #72bf44
	em
		color: #ffffff
		font-size: 16px
.button-xemthem
	width: r(170px)
	height: r(50px)
	border-radius: 40px 0
	background-color: rgba(255, 255, 255, 0.9)
	display: flex
	align-items: center
	justify-content: flex-end
	position: relative
	overflow: hidden
	z-index: 2
	color: #111111
	font-size: r(18px)
	font-weight: 400
	transition: .5s all ease-in-out
	span
		padding-right: 10px
	&::before
		content: ''
		position: absolute
		left: 0
		top: 0
		width: 0
		z-index: -1
		height: 100%
		background-color: rgba($main, 0.9)
		transition: all 600ms cubic-bezier(0.825, 0.09, 0.085, 1.01)
	&:hover
		color: white
		.icon-la
			background-color: #fff
			em
				color: #111
		&::before
			width: 100%
	.icon-la
		width: r(44px)
		height: r(44px)
		border-radius: 40px 0
		background-color: $main
		margin-right: 3px
		margin-left: 16px
		transition-delay: .2s
		transition: .4s all ease-in-out
		em
			color: #ffffff
			font-size: r(16px)
			font-weight: 400
			transform: rotate(-45deg)
			transition-delay: .2s
			transition: .4s all ease-in-out
.button-190
	width: 190px
	height: 50px
	border-radius: 40px 0
	background-color: #d8efff
	display: flex
	align-items: center
	justify-content: flex-end
	margin-top: 25px
	padding-right: 5px
	overflow: hidden
	transition: .5s all ease-in-out
	position: relative
	z-index: 2
	&.btn-buy
		background-color: rgba(114,191,68,.15)
		&::before
			background-color: rgba($green , .9)
		.icon-la
			background-color: $green
	&.white
		background-color: #fff
	&.center
		margin-left: auto
		margin-right: auto
		margin-top: 30px
	&:hover
		color: white
		span
			color: white
		.icon-la
			background-color: #fff
			em
				color: #111
		&::before
			width: 100%
	&::before
		content: ''
		position: absolute
		left: 0
		top: 0
		width: 0
		z-index: -1
		height: 100%
		background-color: rgba($main, 0.9)
		transition: all 600ms cubic-bezier(0.825, 0.09, 0.085, 1.01)
	span
		color: #111111
		font-size: 18px
		font-weight: 400
		transition: .6s all ease-in-out
	.icon-la
		width: 40px
		height: 40px
		border-radius: 35px 0
		background-color: $main
		margin-left: 20px
		transition: .6s all ease-in-out
		em
			transform: rotate(-45deg)
			transition: .3s all ease-in-out
.wrap-button-slide
	display: flex
	align-items: center
	justify-content: center
	margin-top: 30px
	.button-next, .button-prev
		width: 40px
		height: 40px
		box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.05)
		background-color: #ffffff
		border-radius: 100%
		display: flex
		align-items: center
		justify-content: center
		margin: 0 5px
		transition: .3s all ease-in-out
		em
			color: #999999
			font-size: 24px
			font-weight: 400
			transition: .3s all ease-in-out
		&:hover
			background-color: $main
			em
				color: white
.wrap-slide
	position: relative
.wrap-button-slide-2
	display: flex
	align-items: center
	justify-content: center
	@media screen and ( max-width: 1400px )
		margin-top: 40px
	&.min
		.button-prev,.button-next
			width: 50px
			height: 50px
			&:hover
				background-color: $main
	.button-prev,.button-next
		width: 60px
		height: 60px
		box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.05)
		background-color: #ffffff
		display: flex
		align-items: center
		justify-content: center
		border-radius: 100%
		em
			color: #999999
			font-size: 24px
			font-weight: 400
	.button-prev
		position: absolute
		top: 50%
		transform: translateY(-50%)
		right: 100%
		margin-right: 30px
		transition: .3s all ease-in-out
		z-index: 99
		@media screen and ( max-width: 1400px )
			position: static
			transform: translateY(0)
			margin-right: 5px
		&:hover
			background-color: $green
			em
				color: white
	.button-next
		position: absolute
		top: 50%
		transform: translateY(-50%)
		left: 100%
		margin-left: 30px
		transition: .3s all ease-in-out
		z-index: 99
		@media screen and ( max-width: 1400px )
			position: static
			transform: translateY(0)
			margin-left: 5px
		&:hover
			background-color: $green
			em
				color: white
