.news-detail-page
	.fancybox-content
		max-width: 920px !important
		@extend .mx-auto
		padding: 0 60px !important
		border-radius: 20px 0
		background-color: $white
		overflow: hidden !important
.apply-frm
	padding: 30px 10px!important
	.title
		font-weight: 700
		font-size: 2rem
		@media screen and ( min-width: 1280px )
			font-size: 24px
	h4
		display: block
		margin-bottom: 15px
		font-weight: 700
		font-size: 16px
		span
			margin: 0 r(-3px)
	.form-group
		margin-bottom: 20px
		position: relative
		label
			color: $text-2
			font-size: r(16px)
			font-weight: 400
			transition: .5s all ease-in-out
			margin-bottom: 10px
			span
				color: #ff0000
		input,textarea
			padding: 10px 0
			width: 100%
			border: 0
			height: 40px
			outline: 0
			background-color: $tran
			border-bottom: thin solid #e0e0e0
			// &:focus,&:valid
			// 	+ label
			// 		top: -2px
			// 		font-size: r(14px)
	.frm-btnwrap
		padding: 0 15px
		.label
			display: none!important
		.frm-btn
			margin-left: 0
			position: relative
			&.btn-seemore
				&:hover
					input
						color: $red
		input
			width: 160px!important
			height: 40px!important
			background-color: $main!important
			color: white!important
			font-weight: bold!important
			padding-left: 0!important
			margin-top: 15px!important
	.attachfile1,.attachfile2
		flex: 0 0 100%
		padding: 0 15px
		label
			position: static
			span
				color: $text-2
		.left
			.ruFileWrap.ruStyled
				width: 160px
				height: 40px
				border-radius: 20px
				background-color: $bg_color !important
				transition: .3s all ease-in-out
				display: flex
				justify-content: center
				margin-top: 10px
				margin-bottom: 10px
				align-items: center
				input
					color: $text-3
					font-size: r(20px)
					font-weight: 400
					border: 0 !important
					outline: 0 !important
					background: none !important
.fancybox-slide
	@media screen and ( max-width: 575px )
		padding: 15px!important
