#page-banner
	width: 100%
	position: relative
	&::before
		content: ''
		background-image: linear-gradient(to top, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0) 100%)
		left: 0
		top: 0
		width: 100%
		height: 100%
		position: absolute
	ol.breadcrumb
		border-bottom: 0px
		animation-duration: 1.4s
		animation-delay: .25s
		animation-fill-mode: both
		animation-name: fade-in
		justify-content: center
	.box-text
		position: absolute
		left: 50%
		transform: translateX(-50%)
		bottom: 60px
		width: 100%
		display: flex
		flex-direction: column
		align-items: center
		@media screen and ( max-width: 575px )
			bottom: auto
			top: 50%
			transform: translateX(-50%) translateY(-50%)
		.title
			color: #ffffff
			font-size: r(52px)
			font-weight: 700
			text-transform: uppercase
			position: relative
			margin-bottom: 20px
			animation-duration: 1.4s
			animation-fill-mode: both
			animation-name: fade-in
			@media screen and ( max-width: 575px )
				font-size: r(25px)
				text-align: center
			&::before
				content: ''
				position: absolute
				background-color: rgba(255, 255, 255, 0.7)
				width: 180px
				height: 2px
				top: 100%
				left: 50%
				margin-top: 8px
				transform: translateX(-50%)
	.banner__pages
		width: 100%
		height: 55.5vh
		img
			width: 100%
			height: 100%
			object-fit: cover
			object-position: center
		@media screen and ( max-width: 1024px)
			height: 60vh
		@media screen and ( max-width: 575px )
			height: 35vh
