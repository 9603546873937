.gioi-thieu-2
	.box-right
		padding-right: 17%!important
		@media screen and ( max-width: 1400px )
			padding-right: 5%!important
		@media screen and ( max-width: 575px )
			height: auto!important
			padding-top: 50px
			padding-bottom: 50px
		ul
			list-style: none
			margin: 0
			padding: 0
			li
				&+li
					border-top: 1px solid #00aeef
					margin-top: 28px
					padding-top: 28px
				.box-content
					display: flex
					.box-left-2
						flex: 0 0 55px
						.img
							padding-top: 9px
					.box-right-2
						flex: 1
						padding-left: 30px
						.title-ct
							color: #ffffff
							font-size: r(36px)
							font-weight: 700
							margin-bottom: 10px
						.desc
							color: #ffffff
							font-size: 1rem
							font-weight: 400
							line-height: 24px
.gioi-thieu-3
	position: relative
	background-color: #e2f3ff
	overflow: hidden
	padding-bottom: 20px
	&::before
		content: ''
		background-image: url("../img/gt-i-3.png")
		width: 332px
		height: 414px
		position: absolute
		bottom: -40px
		right: 0
	.title-36
		margin-bottom: 6px
	.desc
		color: $text
		font-size: r(18px)
		font-weight: 500
		line-height: 24px
		max-width: 80%
		margin: 0 auto
		@media screen and ( max-width: 575px )
			max-width: 100%
		p
			margin-bottom: 25px
			text-align: center
	.wrap-circle
		display: flex
		align-items: center
		justify-content: space-between
		flex-wrap: wrap
		.circle
			position: relative
			width: 130px
			height: 130px
			border-radius: 65px
			background-color: #00aeef
			color: #ffffff
			font-size: r(14px)
			font-weight: 700
			text-transform: uppercase
			display: flex
			align-items: center
			justify-content: center
			text-align: center
			// margin: 0 auto
			margin-bottom: 63px
			@media screen and ( max-width: 575px )
				margin-bottom: 38px
			&::before
				content: ''
				background-image: url("../img/box-shadow-gt.png")
				position: absolute
				top: 94%
				left: 60%
				transform: translateX(-50%)
				width: 234px
				height: 46px
.gioi-thieu-4
	background-color: #fff
	padding-bottom: 0
	@media screen and ( min-width: 1230px )
		padding-top: 80px
	.wrap
		.tabslet-tab
			margin: 0
			padding: 0
			list-style: none
			width: 100%
			display: flex
			align-items: center
			justify-content: center
			li
				border-width: 1px 1px 0px 0px
				border-style: solid
				border-color: #e1e1e1
				&:hover
					a
						background-color: #f3f3f3
						.img
							img
								filter: brightness(1)
						span
							color: $green
				&.active
					a
						background-color: #f3f3f3
						.img
							img
								filter: brightness(1)
						span
							color: $green
				&:first-child
					border-left: 1px solid #e1e1e1
				&:last-child
					left: 1px solid #f1f1f1
				a
					width: 150px
					height: 150px
					background-color: #ffffff
					display: flex
					align-items: center
					justify-content: center
					flex-direction: column
					transition: .3s all ease-in-out
					.img
						margin-bottom: 20px
						img
							filter: brightness(0%)
							transition: .3s all ease-in-out
					span
						color: $text
						font-size: 1rem
						font-weight: 700
						text-transform: uppercase
						transition: .3s all ease-in-out
	.tabslet-content
		padding-top: r(70px)
		padding-bottom: r(80px)
		background-color: #f3f3f3
		.box-img
			width: 100%
			height: 400px
			@media screen and ( max-width: 1023.67px )
				height: auto
			a
				display: block
				height: 100%
				width: 100%
				img
					width: 100%
					height: 100%
					object-fit: cover
		.box-content
			height: 400px
			padding-left: 75px
			margin-top: -15px
			@media screen and ( max-width: 1023.67px )
				padding-left: 0
				margin-top: 0
				padding-top: 30px
				height: auto
			.title
				color: $text
				font-size: r(36px)
				font-weight: 400
				margin-bottom: 10px
				@media screen and ( max-width: 575px )
					font-size: 2.2rem
				span
					color: $main
			.desc
				*
					color: $text
					font-size: r(18px)
					font-weight: 400
					line-height: 24px
					&+*
						margin-top: 30px
.gioi-thieu-5
	@media screen and ( min-width: 1230px )
		padding-top: 7px!important
		padding-bottom: 79px!important
	.button-next, .button-prev
		background-color: #f4f4f4
	.button-prev
		margin-right: 18px
	.button-next
		margin-left: 18px
