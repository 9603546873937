.home-8
	padding-top: 0
	background-color: #fff
	.container
		@media screen and ( max-width: 575px )
			max-width: 100%
			padding-left: 0
			padding-right: 0
	.button-190
		justify-content: center
	.maps
		position: relative
		border-top: 1px solid #ebebeb
		height: 350px
		img
			width: 100%
			height: 100%
	.wrap-title-contact
		position: absolute
		top: 57%
		width: 100%
		transform: translateY(-50%)
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
		padding: 0 5px
		margin-top: -33px
	.button-190
		box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.1)
		margin-top: 17px
		.icon-la
			margin-left: 10px
		span
			font-weight: bold
