.home-5
	background-color: #fff
	@media screen and ( min-width:1280px )
		padding-top: r(45px)
		padding-bottom: r(70px)
	.title-36
		margin-bottom: 25px
	.button-190
		margin-top: 0
	.box-post-tin
		display: flex
		padding-right: 30px
		margin-bottom: 30px
		@media screen and ( max-width: 575px )
			flex-wrap: wrap
		.img
			flex: 0 0 calc( 50% + 30px )
			max-width: calc( 50% + 30px )
			height: 285px
			transition: all 500ms cubic-bezier(0.825, 0.09, 0.085, 1.01)
			outline: transparent solid 10px
			@media screen and ( max-width: 575px )
				flex: 0 0 100%
				max-width: 100%
				height: 200px
			&:hover
				outline: $main solid 10px
			a
				display: block
				width: 100%
				height: 100%
				img
					width: 100%
					height: 100%
					object-fit: cover
		.content
			flex: 0 0 calc( 50% + 30px )
			max-width: calc( 50% + 30px )
			margin-left: -60px
			margin-top: 30px
			height: 285px
			padding-right: 15px
			background-color: #fff
			padding-top: 40px
			padding-left: 30px
			@media screen and ( max-width: 575px )
				flex: 0 0 100%
				max-width: 100%
				height: auto
				margin-left: 30px
				padding-bottom: 30px
				margin-top: -90px


