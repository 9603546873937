.home-3
	padding-top: 60px
	padding-bottom: 60px
	position: relative
	background-color: #fff
	z-index: 2
	&::after
		content: ''
		background-image: url("../img/h3-cay.png")
		position: absolute
		max-width: 395px
		width: 100%
		pointer-events: none
		height: 394px
		bottom: 235px
		pointer-events: none
		right: 0
		z-index: -1
		@media screen and ( max-width: 1400px )
			bottom: 90px
	&::before
		content: ''
		height: 235px
		width: 100%
		position: absolute
		bottom: 0
		background-color: #fff
		left: 0
		z-index: -1
		@media screen and ( max-width: 1400px )
			height: 90px
	.title-36
		margin-top: 60px
		margin-bottom: 30px
		@media screen and ( max-width: 575px )
			margin-top: 30px
	.wrap
		.tabslet-tab
			margin: 0
			padding: 0
			list-style: none
			display: flex
			align-items: center
			justify-content: center
			@media screen and ( max-width: 575px )
				justify-content: space-around
			li
				&.active
					.img
						a
							&::before
								background-color: rgba(0, 0, 0, 0.0)
					a
						color: $green
				@media screen and ( max-width: 575px )
					width: 140px
				&:hover
					.img
						a
							&::before
								background-color: rgba(0, 0, 0, 0.0)
					a
						color: $green
				.img
					position: relative
					display: flex
					justify-content: center

					img
						width: 100%
						height: 100%
						object-fit: cover
					&::after
						content: ''
						background-image: url("../img/h3-shadow.png")
						position: absolute
						width: 234px
						height: 46px
						left: 55%
						transform: translateX(-50%)
						top: 100%
						@media screen and ( max-width: 575px )
							display: none
					a
						position: relative
						display: block
						border-radius: 100%
						overflow: hidden
						width: 180px
						border-radius: 100%
						height: 180px
						@media screen and ( max-width: 575px )
							width: 140px
							height: 140px
						&::before
							content: ''
							position: absolute
							left: 0
							top: 0
							width: 100%
							height: 100%
							background-color: rgba(0, 0, 0, 0.32)
							transition: .3s all ease-in-out
				.text
					margin-top: 40px
					@media screen and ( max-width: 575px )
						margin-top: 15px
						text-align: center
					a
						font-size: 1rem
						font-weight: 400
						transition: .3s all ease-in-out
				&+li
					margin-left: 50px
					@media screen and ( max-width: 575px )
						margin-left: 0

