body {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto; }

.container {
  padding-right: 30px;
  padding-left: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%; }

@media (min-width: 576px) {
  .container {
    max-width: 540px;
    padding-right: 15px;
    padding-left: 15px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 1024px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1280px) {
  .container {
    max-width: 1260px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }

.no-gutters {
  margin-left: 0;
  margin-right: 0; }

.no-gutters > *,
.no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0; }

[class*="col-"] {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1024px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1280px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

html {
  font-family: 'Philosopher', sans-serif;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: 'Philosopher', sans-serif;
  font-weight: 400;
  background-color: white;
  color: #232323;
  margin-top: 0;
  margin-bottom: 0;
  scroll-behavior: smooth; }

body, html {
  font-size: 16px; }

.mx-auto, .news-detail-page .fancybox-content {
  margin-left: auto;
  margin-right: auto; }

* {
  box-sizing: border-box; }
  *::before, *::after {
    box-sizing: border-box; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

body, html {
  font-size: 14px; }
  @media (min-width: 1024px) {
    body, html {
      font-size: 15px; } }
  @media (min-width: 1280px) {
    body, html {
      font-size: 16px; } }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 0; }

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0;
  margin-left: 0; }

blockquote {
  margin: 0; }

b, strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  text-decoration: none;
  background-color: transparent;
  font-size: inherit;
  font-weight: inherit;
  color: inherit; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

button:focus {
  outline: none;
  outline: 5px auto -webkit-focus-ring-color; }

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

select {
  word-wrap: normal; }

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden], .d-none, .hidden {
  display: none !important; }

input:focus, button:focus, a:focus, .button-prev:focus, .button-next:focus, textarea:focus, select:focus {
  outline: none; }

img {
  max-width: 100%;
  height: auto; }

.zoom-img {
  overflow: hidden; }
  .zoom-img:hover img {
    transform: scale(1.05) translateZ(0); }
  .zoom-img img {
    transition: .3s all ease-in-out; }

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(40px); }
  to {
    opacity: 1;
    transform: translateY(0px); } }

@keyframes fade-in2 {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(20px); }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0px); } }

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(45px);
    filter: blur(5); }
  100% {
    opacity: 1;
    transform: translateY(0);
    filter: blur(0); } }

@keyframes blurText {
  0% {
    filter: blur(2px); }
  100% {
    filter: blur(2px); } }

@keyframes loading {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

@keyframes loading1-circle {
  0% {
    stroke-dashoffset: 0; }
  100% {
    stroke-dashoffset: -600; } }

#load {
  width: 50px;
  display: inline-block;
  animation: loading 2s linear infinite; }

#loading-inner {
  stroke-dashoffset: 1;
  stroke-dasharray: 300;
  stroke-width: 10;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  animation: loading1-circle 2s linear infinite;
  stroke: #00a7e6;
  fill: transparent; }

@keyframes AlertError {
  0% {
    transform: translateX(30px); }
  20% {
    transform: translateX(-30px); }
  40% {
    transform: translateX(15px); }
  60% {
    transform: translateX(-15px); }
  80% {
    transform: translateX(8px); }
  100% {
    transform: translateX(0px); } }

.button-hotline {
  display: flex;
  width: 170px;
  height: 40px;
  border-radius: 30px 0;
  background-color: rgba(114, 191, 68, 0.15);
  align-items: center;
  padding-right: 20px;
  padding-left: 4px;
  overflow: hidden;
  margin-left: 19px;
  position: relative;
  z-index: 2; }
  .button-hotline.blue {
    background-color: #d8efff; }
    .button-hotline.blue .icon-la {
      background-color: #00a7e6; }
  .button-hotline a {
    color: #333333;
    font-size: 1rem;
    font-weight: 700;
    margin-left: 11px; }

.icon-la {
  width: 32px;
  height: 32px;
  border-radius: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #72bf44; }
  .icon-la em {
    color: #ffffff;
    font-size: 16px; }

.button-xemthem {
  width: 10.625rem;
  height: 3.125rem;
  border-radius: 40px 0;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  overflow: hidden;
  z-index: 2;
  color: #111111;
  font-size: 1.125rem;
  font-weight: 400;
  transition: .5s all ease-in-out; }
  .button-xemthem span {
    padding-right: 10px; }
  .button-xemthem::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    z-index: -1;
    height: 100%;
    background-color: rgba(0, 167, 230, 0.9);
    transition: all 600ms cubic-bezier(0.825, 0.09, 0.085, 1.01); }
  .button-xemthem:hover {
    color: white; }
    .button-xemthem:hover .icon-la {
      background-color: #fff; }
      .button-xemthem:hover .icon-la em {
        color: #111; }
    .button-xemthem:hover::before {
      width: 100%; }
  .button-xemthem .icon-la {
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 40px 0;
    background-color: #00a7e6;
    margin-right: 3px;
    margin-left: 16px;
    transition-delay: .2s;
    transition: .4s all ease-in-out; }
    .button-xemthem .icon-la em {
      color: #ffffff;
      font-size: 1rem;
      font-weight: 400;
      transform: rotate(-45deg);
      transition-delay: .2s;
      transition: .4s all ease-in-out; }

.button-190 {
  width: 190px;
  height: 50px;
  border-radius: 40px 0;
  background-color: #d8efff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 25px;
  padding-right: 5px;
  overflow: hidden;
  transition: .5s all ease-in-out;
  position: relative;
  z-index: 2; }
  .button-190.btn-buy {
    background-color: rgba(114, 191, 68, 0.15); }
    .button-190.btn-buy::before {
      background-color: rgba(114, 191, 68, 0.9); }
    .button-190.btn-buy .icon-la {
      background-color: #72bf44; }
  .button-190.white {
    background-color: #fff; }
  .button-190.center {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px; }
  .button-190:hover {
    color: white; }
    .button-190:hover span {
      color: white; }
    .button-190:hover .icon-la {
      background-color: #fff; }
      .button-190:hover .icon-la em {
        color: #111; }
    .button-190:hover::before {
      width: 100%; }
  .button-190::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    z-index: -1;
    height: 100%;
    background-color: rgba(0, 167, 230, 0.9);
    transition: all 600ms cubic-bezier(0.825, 0.09, 0.085, 1.01); }
  .button-190 span {
    color: #111111;
    font-size: 18px;
    font-weight: 400;
    transition: .6s all ease-in-out; }
  .button-190 .icon-la {
    width: 40px;
    height: 40px;
    border-radius: 35px 0;
    background-color: #00a7e6;
    margin-left: 20px;
    transition: .6s all ease-in-out; }
    .button-190 .icon-la em {
      transform: rotate(-45deg);
      transition: .3s all ease-in-out; }

.wrap-button-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px; }
  .wrap-button-slide .button-next, .wrap-button-slide .button-prev {
    width: 40px;
    height: 40px;
    box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    transition: .3s all ease-in-out; }
    .wrap-button-slide .button-next em, .wrap-button-slide .button-prev em {
      color: #999999;
      font-size: 24px;
      font-weight: 400;
      transition: .3s all ease-in-out; }
    .wrap-button-slide .button-next:hover, .wrap-button-slide .button-prev:hover {
      background-color: #00a7e6; }
      .wrap-button-slide .button-next:hover em, .wrap-button-slide .button-prev:hover em {
        color: white; }

.wrap-slide {
  position: relative; }

.wrap-button-slide-2 {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media screen and (max-width: 1400px) {
    .wrap-button-slide-2 {
      margin-top: 40px; } }
  .wrap-button-slide-2.min .button-prev, .wrap-button-slide-2.min .button-next {
    width: 50px;
    height: 50px; }
    .wrap-button-slide-2.min .button-prev:hover, .wrap-button-slide-2.min .button-next:hover {
      background-color: #00a7e6; }
  .wrap-button-slide-2 .button-prev, .wrap-button-slide-2 .button-next {
    width: 60px;
    height: 60px;
    box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%; }
    .wrap-button-slide-2 .button-prev em, .wrap-button-slide-2 .button-next em {
      color: #999999;
      font-size: 24px;
      font-weight: 400; }
  .wrap-button-slide-2 .button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 100%;
    margin-right: 30px;
    transition: .3s all ease-in-out;
    z-index: 99; }
    @media screen and (max-width: 1400px) {
      .wrap-button-slide-2 .button-prev {
        position: static;
        transform: translateY(0);
        margin-right: 5px; } }
    .wrap-button-slide-2 .button-prev:hover {
      background-color: #72bf44; }
      .wrap-button-slide-2 .button-prev:hover em {
        color: white; }
  .wrap-button-slide-2 .button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
    margin-left: 30px;
    transition: .3s all ease-in-out;
    z-index: 99; }
    @media screen and (max-width: 1400px) {
      .wrap-button-slide-2 .button-next {
        position: static;
        transform: translateY(0);
        margin-left: 5px; } }
    .wrap-button-slide-2 .button-next:hover {
      background-color: #72bf44; }
      .wrap-button-slide-2 .button-next:hover em {
        color: white; }

.dieu-khoan .wrap-dieu-khoan ul {
  margin-top: 15px;
  margin-bottom: 15px; }
  .dieu-khoan .wrap-dieu-khoan ul li + li {
    margin-top: 8px; }

.dieu-khoan .wrap-dieu-khoan p strong {
  font-size: 1.25rem;
  color: #974806; }

.title-sp {
  color: #232323;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.2;
  transition: .3s all ease-in-out; }
  .title-sp:hover {
    color: #00a7e6; }

.filed-sp {
  color: #999999;
  font-size: 1rem;
  font-weight: 400; }

.box-product-main .img {
  position: relative;
  overflow: hidden;
  box-shadow: 3px 4px 25px rgba(0, 0, 0, 0.1);
  z-index: 2;
  background-color: #fff; }
  .box-product-main .img::before {
    position: absolute;
    content: '';
    width: 100%;
    top: 100%;
    left: 0;
    height: 100%;
    z-index: 3;
    background-color: rgba(0, 167, 230, 0.3);
    transition: .3s all ease-in-out;
    opacity: 0;
    visibility: hidden; }
  .box-product-main .img:hover::before {
    top: 0;
    opacity: 1;
    visibility: visible; }
  .box-product-main .img:hover .button-190 {
    top: 45%; }
  .box-product-main .img .images-pic {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 82.45614%; }
    .box-product-main .img .images-pic img, .box-product-main .img .images-pic iframe, .box-product-main .img .images-pic video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .box-product-main .img .button-190 {
    position: absolute;
    margin-top: 0;
    top: 100%;
    z-index: 5;
    left: 50%;
    transform: translateX(-50%);
    transition: all 600ms cubic-bezier(0.825, 0.09, 0.085, 1.01);
    background-color: #fff; }
    .box-product-main .img .button-190:hover .icon-la {
      background-color: #fff; }
    .box-product-main .img .button-190 .icon-la {
      background-color: #00a7e6; }

.box-product-main .title-sp {
  margin-top: 26px;
  display: block; }

.box-product-main .filed-sp {
  margin-top: 18px; }

.post-title {
  color: #00001a;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
  transition: .3s all ease-in-out;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; }
  .post-title:hover {
    color: #00a7e6; }

.title-post-20 {
  color: #00001a;
  font-size: 1.25rem;
  font-weight: 700;
  height: 82px;
  line-height: 1.3;
  transition: .3s all ease-in-out;
  cursor: pointer;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; }
  .title-post-20:hover {
    color: #72bf44; }

.post-date {
  color: #999999;
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 8px;
  display: block;
  letter-spacing: 1.4px; }

.xem-them {
  color: #00a7e6;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-top: 21px; }
  .xem-them:hover em {
    margin-left: 15px; }
  .xem-them em {
    color: #00a7e6;
    font-size: 16px;
    font-weight: 400;
    margin-left: 7px;
    transition: .3s all ease-in-out; }

.news-detail-page .fancybox-content {
  max-width: 920px !important;
  padding: 0 60px !important;
  border-radius: 20px 0;
  background-color: white;
  overflow: hidden !important; }

.apply-frm {
  padding: 30px 10px !important; }
  .apply-frm .title {
    font-weight: 700;
    font-size: 2rem; }
    @media screen and (min-width: 1280px) {
      .apply-frm .title {
        font-size: 24px; } }
  .apply-frm h4 {
    display: block;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 16px; }
    .apply-frm h4 span {
      margin: 0 -0.1875rem; }
  .apply-frm .form-group {
    margin-bottom: 20px;
    position: relative; }
    .apply-frm .form-group label {
      color: #333;
      font-size: 1rem;
      font-weight: 400;
      transition: .5s all ease-in-out;
      margin-bottom: 10px; }
      .apply-frm .form-group label span {
        color: #ff0000; }
    .apply-frm .form-group input, .apply-frm .form-group textarea {
      padding: 10px 0;
      width: 100%;
      border: 0;
      height: 40px;
      outline: 0;
      background-color: transparent;
      border-bottom: thin solid #e0e0e0; }
  .apply-frm .frm-btnwrap {
    padding: 0 15px; }
    .apply-frm .frm-btnwrap .label {
      display: none !important; }
    .apply-frm .frm-btnwrap .frm-btn {
      margin-left: 0;
      position: relative; }
      .apply-frm .frm-btnwrap .frm-btn.btn-seemore:hover input {
        color: red; }
    .apply-frm .frm-btnwrap input {
      width: 160px !important;
      height: 40px !important;
      background-color: #00a7e6 !important;
      color: white !important;
      font-weight: bold !important;
      padding-left: 0 !important;
      margin-top: 15px !important; }
  .apply-frm .attachfile1, .apply-frm .attachfile2 {
    flex: 0 0 100%;
    padding: 0 15px; }
    .apply-frm .attachfile1 label, .apply-frm .attachfile2 label {
      position: static; }
      .apply-frm .attachfile1 label span, .apply-frm .attachfile2 label span {
        color: #333; }
    .apply-frm .attachfile1 .left .ruFileWrap.ruStyled, .apply-frm .attachfile2 .left .ruFileWrap.ruStyled {
      width: 160px;
      height: 40px;
      border-radius: 20px;
      background-color: #d5d5d5 !important;
      transition: .3s all ease-in-out;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;
      align-items: center; }
      .apply-frm .attachfile1 .left .ruFileWrap.ruStyled input, .apply-frm .attachfile2 .left .ruFileWrap.ruStyled input {
        color: #111;
        font-size: 1.25rem;
        font-weight: 400;
        border: 0 !important;
        outline: 0 !important;
        background: none !important; }

@media screen and (max-width: 575px) {
  .fancybox-slide {
    padding: 15px !important; } }

.main-section {
  padding: 70px 0px; }
  @media screen and (max-width: 767.67px) {
    .main-section {
      padding: 30px 0px; } }

.title-36 {
  color: #ffffff;
  font-size: 2.25rem;
  font-weight: 400;
  text-align: center; }
  @media screen and (max-width: 575px) {
    .title-36 {
      font-size: 1.8rem; } }
  .title-36.bold {
    font-weight: 700; }
  .title-36.black {
    color: #232323; }
  .title-36.center {
    text-align: center; }
  .title-36.mb {
    margin-bottom: 30px; }

.wrap-title-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .wrap-title-icon .icon {
    margin-bottom: 23px; }
  .wrap-title-icon .title36 {
    margin-bottom: 10px; }
  .wrap-title-icon .des {
    color: #232323;
    font-size: 1.125rem;
    font-style: italic; }

.secion-title-line {
  color: #111111;
  font-size: 1.875rem;
  font-weight: 700;
  border-bottom: 1px solid #00a7e6;
  padding-bottom: 15px; }

.d-flex {
  display: flex; }

.justify-between {
  justify-content: space-between; }

.justify-center {
  justify-content: center; }

.align-center {
  align-items: center; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.flex-wrap {
  flex-wrap: wrap; }

.text-white {
  color: #ffffff; }

.button-prev, .button-next {
  cursor: pointer; }

.line-bottom {
  border-bottom: 1px solid rgba(0, 167, 230, 0.28); }

header .ActionMenu .ActionMenuBody {
  top: 110%   !important;
  bottom: auto  !important; }

.ActionMenu .ActionMenuBody {
  top: auto   !important;
  bottom: 110%  !important; }

img[data-src] {
  opacity: 0;
  transition: .5s ease-in-out;
  max-width: 100%; }
  img[data-src].lazyloaded {
    opacity: 1; }

.hambuger {
  height: 25px;
  display: none;
  align-items: center;
  justify-content: center;
  width: 35px;
  position: relative;
  transition: .3s all ease-in-out;
  margin-left: 20px; }
  @media screen and (max-width: 1300px) {
    .hambuger {
      display: flex; } }
  .hambuger.active span:first-child {
    top: 18px;
    left: 50%;
    width: 0px; }
  .hambuger.active span:nth-child(2) {
    transform: rotate(45deg);
    background: #00a7e6; }
  .hambuger.active span:nth-child(3) {
    transform: rotate(-45deg);
    background: #00a7e6; }
  .hambuger.active span:nth-child(4) {
    top: 18px;
    left: 50%;
    width: 0px; }
  .hambuger span {
    display: block;
    position: absolute;
    left: 0;
    transform: rotate(0deg);
    opacity: 1;
    transition: .5s ease-in-out;
    border-radius: 9px;
    background: #00a7e6;
    width: 100%;
    height: 5px; }
    .hambuger span:first-child {
      top: 0; }
    .hambuger span:nth-child(2), .hambuger span:nth-child(3) {
      top: 10px; }
    .hambuger span:nth-child(4) {
      top: 20px; }

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 30px; }
  .pagination li:hover a, .pagination li:hover span {
    background-color: #00aeef;
    color: white; }
  .pagination li.active a, .pagination li.active span {
    background-color: #00aeef;
    color: white; }
  .pagination li + li {
    margin-left: 10px; }
  .pagination li a, .pagination li span {
    width: 40px;
    height: 40px;
    background-color: rgba(0, 174, 239, 0.1);
    color: #00aeef;
    font-size: 1rem;
    display: flex;
    transition: .3s all ease-in-out;
    align-items: center;
    justify-content: center; }

.wrap-button-drop {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px; }
  .wrap-button-drop .bt-xem-them, .wrap-button-drop .rut-gon {
    display: flex;
    cursor: pointer;
    align-items: center; }
    .wrap-button-drop .bt-xem-them span, .wrap-button-drop .rut-gon span {
      color: #00a7e6;
      font-size: 1rem;
      font-weight: 700; }
    .wrap-button-drop .bt-xem-them em, .wrap-button-drop .rut-gon em {
      color: #00a7e6; }
  .wrap-button-drop .rut-gon {
    display: none; }

.nav-scroll-1 {
  background-color: #fff;
  height: 50px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 800px) {
    .nav-scroll-1 {
      display: none; } }
  .nav-scroll-1 ul {
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center; }
    .nav-scroll-1 ul li {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative; }
      .nav-scroll-1 ul li:hover::before {
        width: 100%; }
      .nav-scroll-1 ul li:hover a {
        color: #00a7e6; }
      .nav-scroll-1 ul li::before {
        content: '';
        height: 2px;
        background-color: #00a7e6;
        position: absolute;
        width: 0%;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
        transition: .3s all ease-in-out; }
      .nav-scroll-1 ul li.active::before {
        width: 100%; }
      .nav-scroll-1 ul li.active a {
        color: #00a7e6; }
      .nav-scroll-1 ul li a {
        padding: 0 20px;
        color: #666666;
        font-size: 0.875rem;
        font-weight: 400;
        text-transform: uppercase;
        display: inline-block;
        transition: .3s all ease-in-out; }
        .nav-scroll-1 ul li a:hover {
          color: #00a7e6; }

.category-page-gt {
  padding: 0 15px; }
  @media screen and (min-width: 1024px) {
    .category-page-gt {
      display: none; } }
  @media screen and (max-width: 768px) {
    .category-page-gt {
      position: fixed;
      top: 100px;
      left: -100%;
      opacity: 0;
      visibility: hidden;
      transition: .3s all ease-in-out; }
      .category-page-gt.show-p {
        left: 0;
        opacity: 1;
        visibility: visible;
        z-index: 999;
        width: 100%; } }
  .category-page-gt.active .title-header {
    width: 100%;
    opacity: 1;
    visibility: visible; }
  .category-page-gt.active .list-menu-page {
    width: 100%;
    padding-left: 10px;
    opacity: 1;
    visibility: visible;
    max-height: 100%; }
  .category-page-gt .title-header {
    display: none;
    align-items: center;
    background-color: #00a7e6;
    padding: 7px 10px;
    border-radius: 5px;
    width: 43px;
    overflow: hidden;
    transition: .3s all ease-in-out; }
    @media screen and (max-width: 768px) {
      .category-page-gt .title-header {
        display: flex; } }
    .category-page-gt .title-header span {
      font-weight: bold;
      color: white;
      white-space: nowrap; }
    .category-page-gt .title-header em {
      color: white;
      margin-right: 10px; }
  .category-page-gt .list-menu-page {
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    list-style: none;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 2px 3px 10px #0000001c;
    overflow: hidden;
    width: 0;
    max-height: 0;
    padding-left: 0;
    opacity: 0;
    visibility: hidden;
    transition: .3s all ease-in-out; }
    .category-page-gt .list-menu-page li {
      display: flex;
      align-items: center; }
      .category-page-gt .list-menu-page li.active a {
        color: #00a7e6; }
      .category-page-gt .list-menu-page li a {
        white-space: nowrap; }
        .category-page-gt .list-menu-page li a.active {
          color: #00a7e6; }
      .category-page-gt .list-menu-page li::before {
        content: 'keyboard_arrow_right';
        font-family: 'Material Icons';
        font-size: 18px;
        color: #00a7e6;
        margin-right: 5px; }

.wrap-tab-link {
  margin-bottom: 30px; }
  .wrap-tab-link ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center; }
    @media screen and (max-width: 800px) {
      .wrap-tab-link ul {
        overflow: auto;
        justify-content: flex-start;
        scroll-snap-type: x mandatory;
        padding-bottom: 5px; }
        .wrap-tab-link ul::-webkit-scrollbar {
          height: 2px; }
        .wrap-tab-link ul::-webkit-scrollbar-track {
          background: white; }
        .wrap-tab-link ul::-webkit-scrollbar-thumb {
          background: #00a7e6; } }
    .wrap-tab-link ul li {
      scroll-snap-align: start; }
      .wrap-tab-link ul li + li {
        margin-left: 29px; }
        @media screen and (max-width: 575px) {
          .wrap-tab-link ul li + li {
            margin-left: 10px; } }
      .wrap-tab-link ul li.active a {
        background-color: #00aeef;
        color: white; }
      .wrap-tab-link ul li a {
        width: 250px;
        height: 50px;
        border-radius: 40px 0;
        background-color: #e5f7fd;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #00aeef;
        font-size: 1rem;
        font-weight: 400; }

.wrap-share {
  display: flex;
  margin: 10px 0px;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px; }
  @media screen and (max-width: 575px) {
    .wrap-share {
      flex-wrap: wrap; } }
  .wrap-share .date-current {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 575px) {
      .wrap-share .date-current {
        flex: 0 0 100%; } }
    .wrap-share .date-current::after {
      content: '';
      width: 1px;
      height: 30px;
      background-color: #00a7e6;
      margin-left: 20px;
      margin-right: 20px; }
      @media screen and (max-width: 575px) {
        .wrap-share .date-current::after {
          display: none; } }
    .wrap-share .date-current span {
      color: #232323;
      font-size: 1rem;
      font-weight: 400;
      margin-right: 20px; }
    .wrap-share .date-current p {
      color: #999999;
      font-size: 0.875rem;
      font-weight: 400;
      letter-spacing: 1.4px;
      padding-top: 2px; }
  .wrap-share .share-facebook {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 575px) {
      .wrap-share .share-facebook {
        flex: 0 0 100%;
        margin-top: 10px;
        border-top: 1px dashed #eaeaea;
        padding-top: 10px; } }
    .wrap-share .share-facebook span {
      color: #232323;
      font-size: 1rem;
      font-weight: 400; }
    .wrap-share .share-facebook em {
      color: #00a7e6;
      font-size: 20px;
      margin-left: 10px; }

.box-left-category {
  box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.08);
  background-color: #f8f8f8;
  padding: 30px 30px 20px 30px; }
  @media screen and (max-width: 1280px) {
    .box-left-category {
      margin-bottom: 30px; } }
  .box-left-category + .box-left-category {
    margin-top: 30px; }
  .box-left-category .title {
    color: #00a7e6;
    font-size: 1.375rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 6px; }
  .box-left-category .list-menu {
    margin: 0;
    padding: 0;
    list-style: none; }
    .box-left-category .list-menu .items.active .link {
      color: #72bf44; }
      .box-left-category .list-menu .items.active .link::before {
        color: #72bf44; }
    .box-left-category .list-menu .items + .items {
      border-top: 1px solid #e1e1e1; }
    .box-left-category .list-menu .items .link {
      color: #232323;
      font-size: 1rem;
      font-weight: 700;
      position: relative;
      display: flex;
      transition: .3s all ease-in-out;
      padding: 10px 0px; }
      .box-left-category .list-menu .items .link:hover {
        color: #72bf44; }
        .box-left-category .list-menu .items .link:hover::before {
          color: #72bf44; }
      .box-left-category .list-menu .items .link::before {
        content: 'arrow_right';
        font-family: "Material Icons";
        font-size: 22px;
        color: #999999;
        text-transform: none;
        display: flex;
        margin-top: -5px;
        margin-right: 5px;
        margin-left: -10px;
        transition: .3s all ease-in-out; }

.title-mapping {
  display: none; }

.product-detail-page .pagination {
  display: none !important; }

.form-subber {
  width: 100%;
  margin-top: 20px; }

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.full-content {
  position: relative; }
  .full-content .loading {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    display: none; }
    .full-content .loading.active {
      display: flex; }

.Module .breadcrumb {
  display: none; }

#page-banner .breadcrumb {
  display: flex !important; }

.searchresults main {
  background-color: #fff; }

.page-bai-viet {
  background-color: #fff; }
  @media screen and (max-width: 1280px) {
    .page-bai-viet .box-right {
      margin-top: 30px;
      border-top: 1px dashed #eaeaea;
      padding-top: 10px; } }
  .page-bai-viet .box-right .list-post-main {
    margin-top: 30px; }
  .page-bai-viet .box-right .post-item {
    display: flex; }
    @media screen and (max-width: 767.67px) {
      .page-bai-viet .box-right .post-item {
        flex-wrap: wrap; } }
    .page-bai-viet .box-right .post-item + .post-item {
      margin-top: 30px; }
    .page-bai-viet .box-right .post-item .img {
      flex: 0 0 36.06%;
      max-width: 36.06%; }
      @media screen and (max-width: 767.67px) {
        .page-bai-viet .box-right .post-item .img {
          flex: 0 0 100%;
          max-width: 100%; } }
      .page-bai-viet .box-right .post-item .img a {
        display: block;
        height: 215px; }
        .page-bai-viet .box-right .post-item .img a img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .page-bai-viet .box-right .post-item .content {
      flex: 0 0 63.94%;
      max-width: 63.94%;
      padding: 25px 100px 25px 28px;
      background-color: #fff;
      box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.08);
      height: 215px;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      @media screen and (max-width: 767.67px) {
        .page-bai-viet .box-right .post-item .content {
          flex: 0 0 100%;
          max-width: 100%; } }

.banner-home .swiper-container {
  width: 100%;
  height: 100%; }

.banner-home .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center; }
  .banner-home .swiper-slide-active .img img {
    transform: scale(1.1) !important; }

.banner-home .box-banner {
  position: relative;
  height: 990px; }
  @media screen and (max-width: 1600px) {
    .banner-home .box-banner {
      height: auto; } }
  @media screen and (max-width: 575px) {
    .banner-home .box-banner {
      height: 40vh; } }
  .banner-home .box-banner .button-xemthem {
    margin-top: 36px; }
  .banner-home .box-banner .box-text {
    max-width: 803px;
    width: 100%;
    text-align: center;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 15px; }
    @media screen and (max-width: 1600px) {
      .banner-home .box-banner .box-text {
        top: 30%; } }
    @media screen and (max-width: 575px) {
      .banner-home .box-banner .box-text {
        top: 30%; } }
    .banner-home .box-banner .box-text .title {
      color: #ffffff;
      font-size: 2.25rem;
      font-weight: 700;
      line-height: 1.2;
      animation-duration: 1.4s;
      animation-fill-mode: both;
      animation-name: fade-in; }
      @media screen and (max-width: 992px) {
        .banner-home .box-banner .box-text .title {
          font-size: 1.625rem; } }
    .banner-home .box-banner .box-text .button-xemthem {
      animation-delay: .3s;
      animation-duration: 1.4s;
      animation-fill-mode: both;
      animation-name: fade-in; }
  .banner-home .box-banner .img {
    height: 100%; }
    .banner-home .box-banner .img img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: all 5s cubic-bezier(0.22, 0.44, 0, 1);
      transform: scale(1); }

#page-banner {
  width: 100%;
  position: relative; }
  #page-banner::before {
    content: '';
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0) 100%);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute; }
  #page-banner ol.breadcrumb {
    border-bottom: 0px;
    animation-duration: 1.4s;
    animation-delay: .25s;
    animation-fill-mode: both;
    animation-name: fade-in;
    justify-content: center; }
  #page-banner .box-text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media screen and (max-width: 575px) {
      #page-banner .box-text {
        bottom: auto;
        top: 50%;
        transform: translateX(-50%) translateY(-50%); } }
    #page-banner .box-text .title {
      color: #ffffff;
      font-size: 3.25rem;
      font-weight: 700;
      text-transform: uppercase;
      position: relative;
      margin-bottom: 20px;
      animation-duration: 1.4s;
      animation-fill-mode: both;
      animation-name: fade-in; }
      @media screen and (max-width: 575px) {
        #page-banner .box-text .title {
          font-size: 1.5625rem;
          text-align: center; } }
      #page-banner .box-text .title::before {
        content: '';
        position: absolute;
        background-color: rgba(255, 255, 255, 0.7);
        width: 180px;
        height: 2px;
        top: 100%;
        left: 50%;
        margin-top: 8px;
        transform: translateX(-50%); }
  #page-banner .banner__pages {
    width: 100%;
    height: 55.5vh; }
    #page-banner .banner__pages img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
    @media screen and (max-width: 1024px) {
      #page-banner .banner__pages {
        height: 60vh; } }
    @media screen and (max-width: 575px) {
      #page-banner .banner__pages {
        height: 35vh; } }

ol.breadcrumb {
  display: flex;
  padding: 10px 0;
  align-items: center;
  list-style: none;
  padding-left: 0;
  border-bottom: 1px solid #e1e1e1; }
  ol.breadcrumb li {
    display: flex;
    position: relative;
    align-items: center;
    padding: 0 10px; }
    ol.breadcrumb li a {
      font-weight: 400;
      font-size: 0.875rem;
      color: rgba(255, 255, 255, 0.7) !important; }
      ol.breadcrumb li a.active {
        color: #999999; }
    ol.breadcrumb li:first-child {
      padding-left: 0; }
      ol.breadcrumb li:first-child a {
        font-size: 0; }
        ol.breadcrumb li:first-child a::before {
          font-family: "Material Icons";
          content: 'home';
          font-size: 1.5rem;
          color: rgba(255, 255, 255, 0.7);
          text-transform: none; }
    ol.breadcrumb li + li::before {
      font-family: "Material Icons";
      content: "arrow_right";
      top: 50%;
      transform: translateY(-50%);
      left: -9px;
      position: absolute;
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.25rem; }

.wrap-form span.fa-exclamation-triangle {
  font-size: 0.875rem;
  color: red; }
  .wrap-form span.fa-exclamation-triangle::before {
    margin-right: 5px; }

.wrap-form .form-row {
  display: flex;
  margin: 0 -15px; }
  @media screen and (max-width: 768px) {
    .wrap-form .form-row {
      flex-wrap: wrap; } }
  .wrap-form .form-row .form-group {
    flex: 0 0 50%;
    padding: 0 15px; }
    @media screen and (max-width: 768px) {
      .wrap-form .form-row .form-group {
        flex: 0 0 100%; } }
    .wrap-form .form-row .form-group input[type="text"] {
      width: 100%; }

span.fa-exclamation-triangle {
  font-weight: 400 !important;
  font-style: italic;
  color: red;
  font-family: "Philosopher", sans-serif !important;
  font-size: .9rem; }
  span.fa-exclamation-triangle::before {
    display: none !important; }

.frm-btn-reset {
  display: none !important; }

.frm-captcha {
  display: flex;
  float: left;
  width: calc(100% - 233px);
  padding-right: 20px; }
  @media screen and (max-width: 1280px) {
    .frm-captcha {
      width: calc(100% - 110px); } }
  @media screen and (max-width: 1023.67px) {
    .frm-captcha {
      float: none;
      padding-right: 0;
      flex: 1;
      width: 100%; } }
  @media screen and (max-width: 767.67px) {
    .frm-captcha {
      flex-direction: column; } }
  .frm-captcha .label {
    display: none !important; }
  .frm-captcha .frm-captcha-input {
    order: 2;
    flex: 1; }
    @media screen and (max-width: 767.67px) {
      .frm-captcha .frm-captcha-input {
        order: 1;
        margin-bottom: 20px; } }
  .frm-captcha .RadCaptcha_Default {
    position: relative; }
    @media screen and (max-width: 767.67px) {
      .frm-captcha .RadCaptcha_Default {
        flex: 0 0 100%;
        order: 2; } }
  .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl00 {
    position: absolute;
    top: 120%;
    color: red; }
  .frm-captcha #ctl00_mainContent_ctl02_txtCaptcha {
    width: 100%;
    height: 40px;
    margin-top: 0;
    border: 1px solid #e1e1e1;
    background-color: #fefefe; }
    @media screen and (max-width: 767.67px) {
      .frm-captcha #ctl00_mainContent_ctl02_txtCaptcha {
        width: 100%; } }
  .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl01 {
    display: flex;
    align-items: center; }
    .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl01 img {
      width: 100% !important;
      height: 40px !important; }
    .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl01 .rcRefreshImage {
      font-size: 0;
      margin-left: 5px;
      height: auto !important;
      margin-right: 10px; }
      .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl01 .rcRefreshImage::after {
        display: none !important; }
      .frm-captcha #ctl00_mainContent_ctl02_captcha_ctl01 .rcRefreshImage::before {
        content: "\e861";
        font-family: Linearicons-Free;
        color: #232323;
        font-size: 30px;
        font-weight: 400; }

.subscribefrm .fa-exclamation-triangle {
  top: 110% !important;
  right: 0 !important;
  width: auto !important;
  text-indent: 0 !important; }
  .subscribefrm .fa-exclamation-triangle::after {
    display: none; }

.signup-page {
  background-color: #fff;
  padding: 60px 0px; }
  .signup-page .signup-panel {
    margin: 0 auto;
    box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06);
    padding: 15px;
    border-radius: 20px;
    max-width: 450px;
    width: 100%; }
  .signup-page .col-xs-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important; }
  .signup-page .headtitle {
    font-size: 2.25rem;
    color: #00a7e6;
    margin-bottom: 15px;
    text-align: center; }
  .signup-page label {
    font-size: 1.125rem; }
  .signup-page input[type="text"], .signup-page input[type="password"] {
    padding: 5px !important;
    height: 35px !important;
    border: 0 !important;
    outline: 0 !important;
    width: 100% !important;
    border-bottom: 1px solid #eaeaea !important;
    margin-bottom: 5px !important; }
  .signup-page select {
    padding: 5px !important;
    height: 35px !important;
    border: 0 !important;
    outline: 0 !important;
    width: 100% !important;
    border-bottom: 1px solid #eaeaea !important;
    margin-bottom: 5px !important; }
  .signup-page .form-group {
    margin-bottom: 15px; }
  .signup-page #ctl00_mainContent_RegisterUser___CustomNav0_StepNextButtonButton {
    width: 100%;
    background-color: #00a7e6;
    color: white;
    margin-top: 10px;
    outline: 0;
    border: 0;
    padding: 5px 0px; }

.login-panel {
  background-color: #fff;
  margin: 0 auto;
  box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06);
  padding: 30px;
  border-radius: 20px; }
  .login-panel .headtitle {
    font-size: 2.25rem;
    text-align: center;
    color: #00a7e6; }
  .login-panel .panel-heading {
    text-align: center;
    margin-bottom: 20px; }
  .login-panel .btn-login input {
    width: 100%;
    background-color: #00a7e6;
    color: white;
    font-size: 1.125rem;
    padding: 5px 0px;
    border: 0;
    outline: none;
    margin: 9px 0px;
    transition: .3s all ease-in-out; }
    .login-panel .btn-login input:hover {
      background-color: #72bf44; }
  .login-panel .form-group {
    display: flex;
    width: 100%; }
    .login-panel .form-group.forget {
      display: flex;
      align-items: center; }
      .login-panel .form-group.forget input {
        margin-right: 10px; }
    .login-panel .form-group:nth-child(1), .login-panel .form-group:nth-child(2) {
      border-bottom: 1px solid #eaeaea;
      margin-bottom: 10px;
      align-items: center;
      padding: 5px 0px; }
      .login-panel .form-group:nth-child(1) label i, .login-panel .form-group:nth-child(2) label i {
        color: #00a7e6; }
      .login-panel .form-group:nth-child(1) input, .login-panel .form-group:nth-child(2) input {
        width: 100%;
        flex: 0 0 100%;
        border: 0;
        padding-left: 15px;
        outline: 0; }
    .login-panel .form-group:nth-child(5) {
      display: flex;
      justify-content: space-between; }
      .login-panel .form-group:nth-child(5) a {
        transition: .3s all ease-in-out; }
        .login-panel .form-group:nth-child(5) a:hover {
          color: #00a7e6; }
  .login-panel .panel-primary {
    width: 100%; }

.login-page {
  background-color: #fff;
  padding: 40px 0px; }

.loginpage main {
  padding-top: 90px; }

.loginpage .col-xs-12 {
  margin: 0 auto; }

.passwordrecovery .admin-content {
  background-color: #fff;
  padding: 60px 0px; }
  .passwordrecovery .admin-content .wrap-secure {
    margin: 0 auto;
    box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06);
    padding: 15px;
    border-radius: 20px;
    max-width: 350px;
    width: 100%; }

.passwordrecovery .col-sm-9 {
  flex: 0 0 100%;
  max-width: 100%; }

.passwordrecovery .panel-heading * {
  text-align: center;
  color: #00a7e6;
  font-size: 2.25rem; }

.passwordrecovery #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_UserName {
  border: 0;
  outline: none;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
  padding: 5px 0px; }

.passwordrecovery #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_captcha_CaptchaTextBox {
  border: 0;
  outline: none;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
  padding: 5px 0px; }

.passwordrecovery #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_lblEnterUserName {
  white-space: nowrap;
  text-align: left;
  margin-bottom: 5px; }

.passwordrecovery #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_SubmitButton {
  width: 100%;
  background-color: #00a7e6;
  color: white;
  margin-top: 10px;
  outline: 0;
  border: 0;
  padding: 5px 0px; }

.passwordrecovery #ctl00_mainContent_lblMailError {
  font-weight: 400 !important;
  font-style: italic;
  color: red;
  font-family: "Philosopher", sans-serif !important;
  font-size: .9rem;
  margin-top: 5px;
  padding: 0 15px; }

.passwordrecovery #ctl00_mainContent_PasswordRecovery1_UserNameContainerID_captcha_ctl00 {
  font-weight: 400 !important;
  font-style: italic;
  color: red;
  font-family: "Philosopher", sans-serif !important;
  font-size: .9rem; }

.modulepager .pagination {
  margin-top: 20px;
  display: flex;
  list-style-type: none;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0; }
  @media screen and (min-width: 1024px) {
    .modulepager .pagination {
      margin-top: 30px; } }
  .modulepager .pagination li {
    margin: 10px;
    color: #00a7e6;
    display: flex;
    justify-content: center;
    align-items: center; }
    .modulepager .pagination li:last-child {
      margin-right: 0; }
    .modulepager .pagination li a, .modulepager .pagination li span {
      width: 35px;
      height: 35px;
      font-size: 16px;
      color: #666666;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #e9f2fb;
      transition: .2s ease-in-out all;
      overflow: hidden;
      font-size: 1.125rem; }
    .modulepager .pagination li.active a, .modulepager .pagination li.active span, .modulepager .pagination li:hover a, .modulepager .pagination li:hover span {
      color: #ffffff;
      background: #00a7e6; }
    @media (min-width: 1024px) {
      .modulepager .pagination li a, .modulepager .pagination li span {
        width: 40px;
        height: 40px; } }

.search-page {
  padding: 40px 0; }
  .search-page h1 {
    letter-spacing: -2px;
    margin-bottom: 20px;
    font-size: 45px;
    line-height: 55px;
    font-weight: 500;
    color: #333333; }
    @media (max-width: 1024px) {
      .search-page h1 {
        font-size: 24px;
        line-height: 30px; } }
    .search-page h1.center {
      text-align: center; }
  .search-page .btn-reindex {
    display: none; }
  .search-page .searchcontrols .form-inline {
    position: relative; }
    .search-page .searchcontrols .form-inline:before {
      position: absolute;
      content: '\f349';
      font-family: "Material Design Icons";
      top: 0;
      right: 10px;
      z-index: 1;
      font-size: 24px;
      color: #4285f4;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .search-page .searchcontrols .form-inline .form-group:nth-child(2), .search-page .searchcontrols .form-inline .form-group:nth-child(3) {
      display: none; }
    .search-page .searchcontrols .form-inline .form-group:nth-child(4) {
      position: absolute;
      top: 0;
      right: 5px;
      z-index: 11; }
      .search-page .searchcontrols .form-inline .form-group:nth-child(4)::before {
        content: "\F0CD";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        font-family: remixicon !important;
        color: #00a7e6; }
  .search-page .searchcontrols .frm-btn {
    position: absolute;
    top: 0;
    right: 10px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2; }
  .search-page input[type="text"] {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 54px;
    border: 1px solid #dfe1e5;
    background-color: #fff;
    color: #333;
    font-size: .875rem;
    font-weight: 400;
    height: 44px;
    border-radius: 22px;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
    transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none; }
    .search-page input[type="text"]:focus {
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      border-color: rgba(223, 225, 229, 0); }
  .search-page input[type="submit"] {
    border-radius: 2px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    outline: none;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    font-size: 0; }
  .search-page .text-danger {
    margin-top: 20px;
    font-size: 15px;
    color: #333;
    font-style: italic;
    font-weight: 600; }
  .search-page .searchresultsummary {
    margin-bottom: 20px; }
  .search-page .searchresults {
    margin-top: 30px; }
    .search-page .searchresults .modulepager:first-child {
      display: none; }
  .search-page .searchresultlist {
    margin-bottom: 20px; }
  .search-page .searchresult {
    margin-bottom: 30px;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    padding: 16px;
    border-radius: 8px; }
    .search-page .searchresult:last-child {
      margin-bottom: 0; }
    .search-page .searchresult h3 {
      font-size: 18px;
      line-height: 1.33;
      font-weight: 400;
      margin-bottom: 10px; }
      .search-page .searchresult h3 a {
        color: #1A0DAB;
        text-decoration: none;
        font-weight: 500; }
        .search-page .searchresult h3 a:hover {
          text-decoration: underline; }
    .search-page .searchresult .searchresultdesc {
      color: #545454;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small;
      font-weight: 400;
      margin-bottom: 10px; }
    .search-page .searchresult .searchterm {
      color: #6a6a6a;
      font-weight: bold;
      font-style: normal;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small; }
    .search-page .searchresult hr {
      display: none !important; }

.faq {
  background-color: #fff; }
  @media screen and (min-width: 1280px) {
    .faq {
      padding-bottom: 71px; } }
  .faq .wrap-content {
    margin-top: 30px; }
  .faq .pagination {
    margin-top: 19px !important; }

.list-faq {
  padding-left: 30px;
  padding-right: 30px;
  background-color: #fff;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.08);
  height: auto;
  min-height: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 767.67px) {
    .list-faq {
      padding-left: 15px;
      padding-right: 20px; } }
  .list-faq.active .title {
    color: #72bf44; }
    .list-faq.active .title::before {
      color: #72bf44;
      transform: translateY(-50%) rotate(180deg); }
  .list-faq + .list-faq {
    margin-top: 30px; }
  .list-faq .title {
    color: #111111;
    font-size: 1.125rem;
    font-weight: 400;
    background-color: #ffffff;
    padding-top: 16px;
    transition: .3s all ease-in-out;
    position: relative;
    padding-bottom: 17px;
    padding-right: 20px; }
    @media screen and (max-width: 575px) {
      .list-faq .title {
        padding-right: 35px; } }
    .list-faq .title::before {
      content: 'arrow_drop_down';
      position: absolute;
      transition: .3s all ease-in-out;
      font-family: "Material Icons";
      color: #999999;
      font-size: 22px;
      text-transform: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0; }
  .list-faq .content {
    color: #111111;
    font-size: 1.125rem;
    font-weight: 400;
    background-color: #f8f8f8;
    display: none;
    padding: 20px 70px 20px 20px;
    margin-bottom: 30px; }
    @media screen and (max-width: 767.67px) {
      .list-faq .content {
        padding: 20px; } }
    .list-faq .content p {
      line-height: 1.2; }

.gioi-thieu-2 .box-right {
  padding-right: 17% !important; }
  @media screen and (max-width: 1400px) {
    .gioi-thieu-2 .box-right {
      padding-right: 5% !important; } }
  @media screen and (max-width: 575px) {
    .gioi-thieu-2 .box-right {
      height: auto !important;
      padding-top: 50px;
      padding-bottom: 50px; } }
  .gioi-thieu-2 .box-right ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .gioi-thieu-2 .box-right ul li + li {
      border-top: 1px solid #00aeef;
      margin-top: 28px;
      padding-top: 28px; }
    .gioi-thieu-2 .box-right ul li .box-content {
      display: flex; }
      .gioi-thieu-2 .box-right ul li .box-content .box-left-2 {
        flex: 0 0 55px; }
        .gioi-thieu-2 .box-right ul li .box-content .box-left-2 .img {
          padding-top: 9px; }
      .gioi-thieu-2 .box-right ul li .box-content .box-right-2 {
        flex: 1;
        padding-left: 30px; }
        .gioi-thieu-2 .box-right ul li .box-content .box-right-2 .title-ct {
          color: #ffffff;
          font-size: 2.25rem;
          font-weight: 700;
          margin-bottom: 10px; }
        .gioi-thieu-2 .box-right ul li .box-content .box-right-2 .desc {
          color: #ffffff;
          font-size: 1rem;
          font-weight: 400;
          line-height: 24px; }

.gioi-thieu-3 {
  position: relative;
  background-color: #e2f3ff;
  overflow: hidden;
  padding-bottom: 20px; }
  .gioi-thieu-3::before {
    content: '';
    background-image: url("../img/gt-i-3.png");
    width: 332px;
    height: 414px;
    position: absolute;
    bottom: -40px;
    right: 0; }
  .gioi-thieu-3 .title-36 {
    margin-bottom: 6px; }
  .gioi-thieu-3 .desc {
    color: #232323;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 24px;
    max-width: 80%;
    margin: 0 auto; }
    @media screen and (max-width: 575px) {
      .gioi-thieu-3 .desc {
        max-width: 100%; } }
    .gioi-thieu-3 .desc p {
      margin-bottom: 25px;
      text-align: center; }
  .gioi-thieu-3 .wrap-circle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; }
    .gioi-thieu-3 .wrap-circle .circle {
      position: relative;
      width: 130px;
      height: 130px;
      border-radius: 65px;
      background-color: #00aeef;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 700;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 63px; }
      @media screen and (max-width: 575px) {
        .gioi-thieu-3 .wrap-circle .circle {
          margin-bottom: 38px; } }
      .gioi-thieu-3 .wrap-circle .circle::before {
        content: '';
        background-image: url("../img/box-shadow-gt.png");
        position: absolute;
        top: 94%;
        left: 60%;
        transform: translateX(-50%);
        width: 234px;
        height: 46px; }

.gioi-thieu-4 {
  background-color: #fff;
  padding-bottom: 0; }
  @media screen and (min-width: 1230px) {
    .gioi-thieu-4 {
      padding-top: 80px; } }
  .gioi-thieu-4 .wrap .tabslet-tab {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .gioi-thieu-4 .wrap .tabslet-tab li {
      border-width: 1px 1px 0px 0px;
      border-style: solid;
      border-color: #e1e1e1; }
      .gioi-thieu-4 .wrap .tabslet-tab li:hover a {
        background-color: #f3f3f3; }
        .gioi-thieu-4 .wrap .tabslet-tab li:hover a .img img {
          filter: brightness(1); }
        .gioi-thieu-4 .wrap .tabslet-tab li:hover a span {
          color: #72bf44; }
      .gioi-thieu-4 .wrap .tabslet-tab li.active a {
        background-color: #f3f3f3; }
        .gioi-thieu-4 .wrap .tabslet-tab li.active a .img img {
          filter: brightness(1); }
        .gioi-thieu-4 .wrap .tabslet-tab li.active a span {
          color: #72bf44; }
      .gioi-thieu-4 .wrap .tabslet-tab li:first-child {
        border-left: 1px solid #e1e1e1; }
      .gioi-thieu-4 .wrap .tabslet-tab li:last-child {
        left: 1px solid #f1f1f1; }
      .gioi-thieu-4 .wrap .tabslet-tab li a {
        width: 150px;
        height: 150px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: .3s all ease-in-out; }
        .gioi-thieu-4 .wrap .tabslet-tab li a .img {
          margin-bottom: 20px; }
          .gioi-thieu-4 .wrap .tabslet-tab li a .img img {
            filter: brightness(0%);
            transition: .3s all ease-in-out; }
        .gioi-thieu-4 .wrap .tabslet-tab li a span {
          color: #232323;
          font-size: 1rem;
          font-weight: 700;
          text-transform: uppercase;
          transition: .3s all ease-in-out; }
  .gioi-thieu-4 .tabslet-content {
    padding-top: 4.375rem;
    padding-bottom: 5rem;
    background-color: #f3f3f3; }
    .gioi-thieu-4 .tabslet-content .box-img {
      width: 100%;
      height: 400px; }
      @media screen and (max-width: 1023.67px) {
        .gioi-thieu-4 .tabslet-content .box-img {
          height: auto; } }
      .gioi-thieu-4 .tabslet-content .box-img a {
        display: block;
        height: 100%;
        width: 100%; }
        .gioi-thieu-4 .tabslet-content .box-img a img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .gioi-thieu-4 .tabslet-content .box-content {
      height: 400px;
      padding-left: 75px;
      margin-top: -15px; }
      @media screen and (max-width: 1023.67px) {
        .gioi-thieu-4 .tabslet-content .box-content {
          padding-left: 0;
          margin-top: 0;
          padding-top: 30px;
          height: auto; } }
      .gioi-thieu-4 .tabslet-content .box-content .title {
        color: #232323;
        font-size: 2.25rem;
        font-weight: 400;
        margin-bottom: 10px; }
        @media screen and (max-width: 575px) {
          .gioi-thieu-4 .tabslet-content .box-content .title {
            font-size: 2.2rem; } }
        .gioi-thieu-4 .tabslet-content .box-content .title span {
          color: #00a7e6; }
      .gioi-thieu-4 .tabslet-content .box-content .desc * {
        color: #232323;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 24px; }
        .gioi-thieu-4 .tabslet-content .box-content .desc * + * {
          margin-top: 30px; }

@media screen and (min-width: 1230px) {
  .gioi-thieu-5 {
    padding-top: 7px !important;
    padding-bottom: 79px !important; } }

.gioi-thieu-5 .button-next, .gioi-thieu-5 .button-prev {
  background-color: #f4f4f4; }

.gioi-thieu-5 .button-prev {
  margin-right: 18px; }

.gioi-thieu-5 .button-next {
  margin-left: 18px; }

.he-thong-phan-phoi {
  background-color: #fff; }
  .he-thong-phan-phoi .box-left .title {
    color: #232323;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px; }
  .he-thong-phan-phoi .box-left .form-group, .he-thong-phan-phoi .box-left .select-wrap {
    position: relative; }
    .he-thong-phan-phoi .box-left .form-group::before, .he-thong-phan-phoi .box-left .select-wrap::before {
      position: absolute;
      content: 'arrow_drop_down';
      color: #999999;
      font-family: "Material Icons";
      font-size: 24px;
      top: 50%;
      transform: translateY(-50%);
      right: 20px; }
    .he-thong-phan-phoi .box-left .form-group:last-child select, .he-thong-phan-phoi .box-left .select-wrap:last-child select {
      color: #999999;
      font-size: 0.875rem;
      font-weight: 400; }
    .he-thong-phan-phoi .box-left .form-group + .select-wrap, .he-thong-phan-phoi .box-left .select-wrap + .select-wrap {
      margin-top: 10px; }
    .he-thong-phan-phoi .box-left .form-group select, .he-thong-phan-phoi .box-left .select-wrap select {
      width: 100%;
      height: 40px;
      border-radius: 30px 0;
      background-color: #ffffff;
      appearance: none;
      color: #232323;
      font-size: 0.875rem;
      font-weight: 400;
      border: 0;
      padding-left: 29px; }
  .he-thong-phan-phoi .tabslet-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 30px; }
    @media screen and (max-width: 767.67px) {
      .he-thong-phan-phoi .tabslet-tab {
        overflow: auto;
        justify-content: normal; } }
    .he-thong-phan-phoi .tabslet-tab li.active a {
      background-color: #00a7e6;
      color: white; }
    .he-thong-phan-phoi .tabslet-tab li + li {
      margin-left: 30px; }
      @media screen and (max-width: 575px) {
        .he-thong-phan-phoi .tabslet-tab li + li {
          margin-left: 5px; } }
    .he-thong-phan-phoi .tabslet-tab li a {
      width: 250px;
      height: 50px;
      border-radius: 40px 0;
      background-color: rgba(0, 167, 230, 0.1);
      color: #00aeef;
      font-size: 1rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .3s all ease-in-out; }
      .he-thong-phan-phoi .tabslet-tab li a:hover {
        background-color: #00a7e6;
        color: white; }
  .he-thong-phan-phoi .wrap-tim-kiem {
    height: 170px;
    padding: 25px 25px 20px 30px;
    background-color: #f3f3f3;
    border: 1px solid #e1e1e1;
    border-bottom: 0px solid; }
  .he-thong-phan-phoi .wrap-form-list {
    border: 1px solid;
    height: 440px;
    background-color: #fff;
    overflow: auto;
    border: 1px solid #e1e1e1;
    padding: 15px 40px 15px 30px; }
    @media screen and (max-width: 1280px) {
      .he-thong-phan-phoi .wrap-form-list {
        padding: 15px 15px 15px 15px; } }
    @media screen and (max-width: 1023.67px) {
      .he-thong-phan-phoi .wrap-form-list {
        height: 222px; } }
    .he-thong-phan-phoi .wrap-form-list::-webkit-scrollbar {
      width: 5px; }
    .he-thong-phan-phoi .wrap-form-list::-webkit-scrollbar-track {
      background: #eaeaea; }
    .he-thong-phan-phoi .wrap-form-list::-webkit-scrollbar-thumb {
      background: #00a7e6; }
    .he-thong-phan-phoi .wrap-form-list ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      .he-thong-phan-phoi .wrap-form-list ul li {
        cursor: pointer; }
        .he-thong-phan-phoi .wrap-form-list ul li .img {
          display: none; }
        .he-thong-phan-phoi .wrap-form-list ul li:hover .item .title {
          color: #00a7e6; }
        .he-thong-phan-phoi .wrap-form-list ul li + li .item {
          padding-top: 13px; }
    .he-thong-phan-phoi .wrap-form-list .item {
      border-bottom: 1px solid #e1e1e1;
      padding-bottom: 14.5px; }
      .he-thong-phan-phoi .wrap-form-list .item .title {
        color: #232323;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 7px;
        transition: .3s all ease-in-out; }
      .he-thong-phan-phoi .wrap-form-list .item li {
        display: flex;
        align-items: center; }
        .he-thong-phan-phoi .wrap-form-list .item li em {
          margin-right: 8px; }
      .he-thong-phan-phoi .wrap-form-list .item li:last-child {
        margin-top: 8px; }
      .he-thong-phan-phoi .wrap-form-list .item em {
        color: #00aeef;
        font-size: 0.875rem;
        font-weight: 400; }
      .he-thong-phan-phoi .wrap-form-list .item li {
        color: #666666;
        font-size: 0.875rem;
        font-weight: 400; }
  .he-thong-phan-phoi .wrap-flex {
    display: flex;
    flex-wrap: wrap; }
  .he-thong-phan-phoi .box-map {
    flex: 0 0 68.3%;
    max-width: 68.3%;
    height: 610px; }
    .he-thong-phan-phoi .box-map [class*="Module"] {
      height: 100%;
      width: 100%; }
    @media screen and (max-width: 1023.67px) {
      .he-thong-phan-phoi .box-map {
        flex: 0 0 100%;
        max-width: 100%;
        height: 50vh; } }
    .he-thong-phan-phoi .box-map * {
      width: 100% !important;
      height: 100% !important; }
  .he-thong-phan-phoi .box-left {
    flex: 0 0 31.70%;
    max-width: 31.70%; }
    @media screen and (max-width: 1023.67px) {
      .he-thong-phan-phoi .box-left {
        flex: 0 0 100%;
        max-width: 100%; } }
  .he-thong-phan-phoi .get-dir-button {
    display: none; }

.hop-tac {
  background-color: #fff; }
  .hop-tac .tabslet-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0; }
    @media screen and (max-width: 767.67px) {
      .hop-tac .tabslet-tab {
        overflow: auto;
        justify-content: normal; } }
    .hop-tac .tabslet-tab li.active a {
      background-color: #00a7e6;
      color: white; }
    .hop-tac .tabslet-tab li + li {
      margin-left: 30px; }
      @media screen and (max-width: 575px) {
        .hop-tac .tabslet-tab li + li {
          margin-left: 5px; } }
    .hop-tac .tabslet-tab li a {
      width: 250px;
      height: 50px;
      border-radius: 40px 0;
      background-color: rgba(0, 167, 230, 0.1);
      color: #00aeef;
      font-size: 1rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .3s all ease-in-out; }
      .hop-tac .tabslet-tab li a:hover {
        background-color: #00a7e6;
        color: white; }
  .hop-tac .wrap-box-select {
    display: flex;
    margin-top: 33px;
    justify-content: space-between;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 2px; }
    @media screen and (max-width: 1023.67px) {
      .hop-tac .wrap-box-select {
        flex-wrap: wrap;
        padding-bottom: 10px; } }
    @media screen and (max-width: 575px) {
      .hop-tac .wrap-box-select {
        border: 1px dashed #72bf44;
        padding: 10px; } }
    .hop-tac .wrap-box-select .wrap-left {
      display: flex; }
      @media screen and (max-width: 1023.67px) {
        .hop-tac .wrap-box-select .wrap-left {
          flex: 0 0 100%;
          order: 2;
          margin-top: 5px; } }
      .hop-tac .wrap-box-select .wrap-left .get-dir-button {
        display: none; }
      .hop-tac .wrap-box-select .wrap-left .wrap-form, .hop-tac .wrap-box-select .wrap-left .wrap-select {
        display: flex;
        align-items: baseline; }
        @media screen and (max-width: 575px) {
          .hop-tac .wrap-box-select .wrap-left .wrap-form, .hop-tac .wrap-box-select .wrap-left .wrap-select {
            flex-wrap: wrap; } }
        .hop-tac .wrap-box-select .wrap-left .wrap-form .select-city, .hop-tac .wrap-box-select .wrap-left .wrap-select .select-city {
          position: relative;
          margin-right: 100px;
          padding-right: 20px; }
          .hop-tac .wrap-box-select .wrap-left .wrap-form .select-city::before, .hop-tac .wrap-box-select .wrap-left .wrap-select .select-city::before {
            content: 'arrow_drop_down';
            font-family: "Material Icons";
            color: #999999;
            font-size: 24px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0; }
          .hop-tac .wrap-box-select .wrap-left .wrap-form .select-city select, .hop-tac .wrap-box-select .wrap-left .wrap-select .select-city select {
            color: #232323;
            font-size: 0.875rem;
            font-weight: 600;
            border: 0px;
            padding-right: 20px;
            appearance: none; }
        .hop-tac .wrap-box-select .wrap-left .wrap-form .select-quan, .hop-tac .wrap-box-select .wrap-left .wrap-select .select-quan {
          position: relative;
          padding-right: 20px; }
          .hop-tac .wrap-box-select .wrap-left .wrap-form .select-quan::before, .hop-tac .wrap-box-select .wrap-left .wrap-select .select-quan::before {
            content: 'arrow_drop_down';
            font-family: "Material Icons";
            color: #999999;
            font-size: 24px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0; }
          .hop-tac .wrap-box-select .wrap-left .wrap-form .select-quan select, .hop-tac .wrap-box-select .wrap-left .wrap-select .select-quan select {
            color: #999999;
            font-size: 0.875rem;
            font-weight: 400;
            border: 0;
            outline: 0;
            padding-right: 20px;
            appearance: none; }
    @media screen and (max-width: 1023.67px) {
      .hop-tac .wrap-box-select .wrap-right {
        flex: 0 0 100%;
        margin-top: 15px;
        order: 1; } }
    .hop-tac .wrap-box-select .wrap-right a {
      display: flex; }
      .hop-tac .wrap-box-select .wrap-right a em, .hop-tac .wrap-box-select .wrap-right a span {
        color: #72bf44; }
      .hop-tac .wrap-box-select .wrap-right a em {
        font-size: 1.125rem;
        font-weight: 400;
        padding-top: 3px; }
      .hop-tac .wrap-box-select .wrap-right a span {
        color: #72bf44;
        font-size: 1.125rem;
        font-weight: 700;
        margin-left: 8px;
        text-transform: uppercase; }
        @media screen and (max-width: 575px) {
          .hop-tac .wrap-box-select .wrap-right a span {
            font-size: 0.875rem; } }
  .hop-tac .wrap-address .full-content {
    overflow: hidden;
    position: relative; }
    .hop-tac .wrap-address .full-content > ul {
      display: flex !important;
      flex-wrap: wrap;
      margin: 0 -15px;
      padding: 0;
      list-style: none; }
      .hop-tac .wrap-address .full-content > ul > li {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding: 0 15px; }
        @media screen and (max-width: 1280px) {
          .hop-tac .wrap-address .full-content > ul > li {
            flex: 0 0 50%;
            max-width: 50%; } }
        @media screen and (max-width: 767.67px) {
          .hop-tac .wrap-address .full-content > ul > li {
            flex: 0 0 100%;
            max-width: 100%; } }
  .hop-tac .wrap-address .box-address, .hop-tac .wrap-address .item {
    height: 110px;
    box-shadow: -8px 0 46px rgba(0, 0, 0, 0.09);
    border: 1px dashed #72bf44;
    background-color: #ffffff;
    display: flex;
    margin-top: 30px; }
    @media screen and (max-width: 575px) {
      .hop-tac .wrap-address .box-address, .hop-tac .wrap-address .item {
        flex-wrap: wrap;
        height: auto;
        padding: 7px 0px;
        padding-top: 0; } }
    .hop-tac .wrap-address .box-address .img, .hop-tac .wrap-address .item .img {
      flex: 0 0 100px;
      max-width: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      background-color: #f3f3f3; }
      @media screen and (max-width: 575px) {
        .hop-tac .wrap-address .box-address .img, .hop-tac .wrap-address .item .img {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 10px 0px;
          margin-bottom: 15px; } }
      .hop-tac .wrap-address .box-address .img img, .hop-tac .wrap-address .item .img img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain; }
    .hop-tac .wrap-address .box-address .content, .hop-tac .wrap-address .item .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 18px; }
      @media screen and (max-width: 575px) {
        .hop-tac .wrap-address .box-address .content, .hop-tac .wrap-address .item .content {
          flex: 0 0 100%;
          max-width: 100%; } }
      .hop-tac .wrap-address .box-address .content ul, .hop-tac .wrap-address .item .content ul {
        margin: 0;
        padding: 0;
        list-style: none; }
    .hop-tac .wrap-address .box-address .title, .hop-tac .wrap-address .item .title {
      color: #00a7e6;
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      transition: .3s all ease-in-out;
      margin-bottom: 5px;
      cursor: pointer; }
      @media screen and (max-width: 575px) {
        .hop-tac .wrap-address .box-address .title, .hop-tac .wrap-address .item .title {
          font-size: 0.9rem; } }
      .hop-tac .wrap-address .box-address .title:hover, .hop-tac .wrap-address .item .title:hover {
        color: #72bf44; }
    .hop-tac .wrap-address .box-address li, .hop-tac .wrap-address .item li {
      display: flex;
      align-items: baseline; }
      .hop-tac .wrap-address .box-address li + li, .hop-tac .wrap-address .item li + li {
        margin-top: 5px; }
      .hop-tac .wrap-address .box-address li em, .hop-tac .wrap-address .item li em {
        color: #00aeef;
        font-size: 0.875rem;
        font-weight: 400;
        margin-right: 10px; }
      .hop-tac .wrap-address .box-address li span, .hop-tac .wrap-address .item li span {
        color: #666666;
        font-size: 1rem;
        font-weight: 400; }
  .hop-tac #map {
    display: none; }

.lien-he {
  background-color: #fff; }
  .lien-he .box-info-map {
    width: 100%;
    height: 380px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    border-radius: 35px 0;
    border: 1px solid #00a7e6;
    background-color: #ffffff;
    padding: 35px 27px 50px 27px; }
    @media screen and (max-width: 1023.67px) {
      .lien-he .box-info-map {
        margin-bottom: 40px;
        height: auto; } }
    .lien-he .box-info-map .title {
      color: #111111;
      font-size: 1.5rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      margin-bottom: 23px; }
      @media screen and (max-width: 1280px) {
        .lien-he .box-info-map .title {
          font-size: 1.125rem; } }
    .lien-he .box-info-map ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .lien-he .box-info-map ul li {
        display: flex; }
        .lien-he .box-info-map ul li:first-child {
          align-items: flex-start; }
          .lien-he .box-info-map ul li:first-child img {
            padding-top: 5px; }
        .lien-he .box-info-map ul li + li {
          margin-top: 34px;
          align-items: center; }
        .lien-he .box-info-map ul li img {
          padding-top: 0px; }
        .lien-he .box-info-map ul li span {
          color: #111111;
          font-size: 1.125rem;
          font-weight: 400;
          margin-left: 15px; }
          @media screen and (max-width: 1280px) {
            .lien-he .box-info-map ul li span {
              font-size: 1rem; } }
  .lien-he .wrap-form {
    margin-top: -5px; }
    .lien-he .wrap-form .form-group {
      margin-bottom: 13px;
      position: relative; }
    .lien-he .wrap-form span.fa-exclamation-triangle {
      position: absolute;
      right: 15px;
      top: 15px;
      font-weight: 400 !important;
      font-style: italic;
      font-size: .9rem;
      animation: AlertError 0.4s 1 linear !important; }
      .lien-he .wrap-form span.fa-exclamation-triangle::before {
        display: none !important; }
    .lien-he .wrap-form label {
      color: #232323;
      font-size: 1.125rem;
      font-weight: 400;
      margin-bottom: 7px; }
    .lien-he .wrap-form input[type="text"] {
      width: 100%;
      height: 40px;
      border-radius: 30px 0;
      background-color: #f5f5f5;
      border: 0;
      padding-left: 20px;
      outline: none; }
    .lien-he .wrap-form textarea {
      width: 100%;
      height: 100px;
      padding-left: 20px;
      padding-top: 15px;
      border: 0;
      border-radius: 30px 0;
      background-color: #f5f5f5; }
    .lien-he .wrap-form input[type="submit"] {
      width: 190px;
      height: 50px;
      border-radius: 40px 0;
      background-color: #d8efff;
      outline: none;
      border: 0;
      color: #111111;
      font-size: 1.125rem;
      font-weight: 400;
      transition: .3s all ease-in-out; }
    .lien-he .wrap-form .frm-btnwrap .label {
      display: none !important; }
    .lien-he .wrap-form .frm-btn {
      position: relative;
      width: fit-content;
      margin-left: auto;
      margin-top: 18px; }
      .lien-he .wrap-form .frm-btn:hover input {
        background-color: rgba(114, 191, 68, 0.7); }
      .lien-he .wrap-form .frm-btn:hover .icon-la {
        background-color: #72bf44; }
      .lien-he .wrap-form .frm-btn .icon-la {
        width: 40px;
        height: 40px;
        border-radius: 35px 0;
        display: flex;
        pointer-events: none;
        align-items: center;
        justify-content: center;
        background-color: #00a7e6;
        position: absolute;
        top: 5px;
        transition: .3s all ease-in-out;
        right: 5px; }
        .lien-he .wrap-form .frm-btn .icon-la em {
          transform: rotate(-45deg); }
  .lien-he .box-map {
    width: 100%;
    height: 690px;
    margin-top: 40px; }
    .lien-he .box-map [class*="Module"] {
      height: 100%;
      width: 100%; }
    @media screen and (max-width: 1023.67px) {
      .lien-he .box-map {
        height: 40vh; } }
    .lien-he .box-map iframe {
      width: 100%;
      height: 100%; }

.san-pham-ct {
  background-color: #fff; }
  .san-pham-ct .wrap-button {
    display: flex;
    align-items: center; }
    @media (max-width: 575.98px) {
      .san-pham-ct .wrap-button {
        flex-wrap: wrap; }
        .san-pham-ct .wrap-button .button-190 {
          flex: 0 0 100%;
          max-width: 100%;
          text-align: center; }
          .san-pham-ct .wrap-button .button-190 span {
            margin: 0 auto;
            padding-left: 68px; } }
    @media (min-width: 576px) {
      .san-pham-ct .wrap-button .btn-buy {
        margin-left: 20px; } }
  .san-pham-ct .box-img-product {
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 3px 4px 25px rgba(0, 0, 0, 0.1); }
    @media screen and (max-width: 1023.67px) {
      .san-pham-ct .box-img-product {
        margin-bottom: 30px;
        height: auto; } }
    .san-pham-ct .box-img-product .wrap-img-top .img-big a {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 100%; }
      .san-pham-ct .box-img-product .wrap-img-top .img-big a img, .san-pham-ct .box-img-product .wrap-img-top .img-big a iframe, .san-pham-ct .box-img-product .wrap-img-top .img-big a video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .san-pham-ct .box-img-product .wrap-slide-thums {
      padding: 0 48px;
      position: relative;
      margin-top: 26px; }
      .san-pham-ct .box-img-product .wrap-slide-thums .swiper-slide-thumb-active .img {
        border: 1px solid #72bf44; }
      .san-pham-ct .box-img-product .wrap-slide-thums .img {
        height: 70px;
        border: 1px solid #ebebeb;
        background-color: #ffffff;
        cursor: grab;
        display: flex;
        align-items: center;
        padding: 5px;
        justify-content: center;
        transition: .3s all ease-in-out;
        overflow: hidden; }
        .san-pham-ct .box-img-product .wrap-slide-thums .img img {
          transition: all .3s ease-in-out;
          max-width: 100%;
          max-height: 100%;
          width: 100%;
          object-fit: contain; }
      .san-pham-ct .box-img-product .wrap-slide-thums .wrap-button-detail .button-prev, .san-pham-ct .box-img-product .wrap-slide-thums .wrap-button-detail .button-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center; }
        .san-pham-ct .box-img-product .wrap-slide-thums .wrap-button-detail .button-prev:hover em, .san-pham-ct .box-img-product .wrap-slide-thums .wrap-button-detail .button-next:hover em {
          color: #72bf44; }
      .san-pham-ct .box-img-product .wrap-slide-thums .wrap-button-detail .button-next {
        right: 14px; }
      .san-pham-ct .box-img-product .wrap-slide-thums .wrap-button-detail .button-prev {
        left: 14px; }
      .san-pham-ct .box-img-product .wrap-slide-thums .wrap-button-detail em {
        color: #999999;
        font-size: 24px;
        font-weight: 400;
        transition: .3s all ease-in-out; }
  .san-pham-ct .box-content-product {
    height: 600px;
    padding-left: 20px; }
    @media screen and (max-width: 1280px) {
      .san-pham-ct .box-content-product {
        height: auto;
        padding-bottom: 30px;
        padding-left: 0px;
        margin-top: 30px; } }
    @media screen and (max-width: 575px) {
      .san-pham-ct .box-content-product {
        margin-top: 0; } }
    .san-pham-ct .box-content-product .title-product {
      color: #111111;
      font-size: 1.875rem;
      font-weight: 700;
      padding-bottom: 17px;
      border-bottom: 1px solid #00a7e6;
      margin-bottom: 25px; }
      @media screen and (max-width: 575px) {
        .san-pham-ct .box-content-product .title-product {
          font-size: 1.5625rem; } }
    .san-pham-ct .box-content-product .desc * {
      color: #232323;
      font-size: 1rem;
      font-weight: 700; }
    .san-pham-ct .box-content-product ul {
      margin: 0;
      padding: 0;
      border-top: 1px solid #eeeeee;
      margin-top: 20px;
      padding-top: 18px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 18px;
      padding-left: 19px; }
      .san-pham-ct .box-content-product ul li {
        color: #666666;
        font-size: 1rem;
        font-weight: 400; }
        .san-pham-ct .box-content-product ul li + li {
          margin-top: 10px; }
    .san-pham-ct .box-content-product .button-190 .icon-la {
      margin-left: 37px; }
    .san-pham-ct .box-content-product .wrap-share {
      margin-top: 28px;
      display: flex;
      align-items: center; }
      .san-pham-ct .box-content-product .wrap-share em {
        margin-left: 10px;
        font-size: 22px;
        color: #00a7e6; }
  .san-pham-ct .box-product-2 {
    margin-top: 80px; }
    @media screen and (max-width: 1280px) {
      .san-pham-ct .box-product-2 {
        margin-top: 20px; } }
    .san-pham-ct .box-product-2 .tabslet-tab {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0; }
      .san-pham-ct .box-product-2 .tabslet-tab::-webkit-scrollbar {
        height: 3px; }
      .san-pham-ct .box-product-2 .tabslet-tab::-webkit-scrollbar-track {
        background: #eaeaea; }
      .san-pham-ct .box-product-2 .tabslet-tab::-webkit-scrollbar-thumb {
        background: #00a7e6; }
      @media screen and (max-width: 1023.67px) {
        .san-pham-ct .box-product-2 .tabslet-tab {
          overflow: auto;
          scroll-snap-type: x mandatory; } }
      .san-pham-ct .box-product-2 .tabslet-tab li {
        height: 50px;
        min-width: 200px;
        background-color: rgba(0, 167, 230, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s all ease-in-out;
        cursor: pointer;
        scroll-snap-align: start; }
        .san-pham-ct .box-product-2 .tabslet-tab li.active {
          background-color: #00a7e6; }
          .san-pham-ct .box-product-2 .tabslet-tab li.active a {
            color: white; }
        .san-pham-ct .box-product-2 .tabslet-tab li + li {
          margin-left: 1px; }
        .san-pham-ct .box-product-2 .tabslet-tab li a {
          color: #00a7e6;
          font-size: 1rem;
          font-weight: 400; }
    .san-pham-ct .box-product-2 .full-content {
      padding-top: 30px;
      max-height: 700px;
      overflow: hidden; }
      @media screen and (max-width: 1230px) {
        .san-pham-ct .box-product-2 .full-content {
          max-height: 530px; } }
      @media screen and (max-width: 992px) {
        .san-pham-ct .box-product-2 .full-content {
          max-height: 300px; } }
      @media screen and (max-width: 575px) {
        .san-pham-ct .box-product-2 .full-content {
          max-height: 150px; } }
      .san-pham-ct .box-product-2 .full-content.active {
        max-height: 100%; }
      .san-pham-ct .box-product-2 .full-content p {
        color: #232323;
        font-size: 1rem;
        line-height: 24px;
        margin-bottom: 20px; }
      .san-pham-ct .box-product-2 .full-content h1, .san-pham-ct .box-product-2 .full-content h2, .san-pham-ct .box-product-2 .full-content h3, .san-pham-ct .box-product-2 .full-content h4, .san-pham-ct .box-product-2 .full-content h5 {
        color: #00a7e6;
        margin-top: 20px; }
      .san-pham-ct .box-product-2 .full-content h1 {
        font-size: 1.875rem !important; }
      .san-pham-ct .box-product-2 .full-content h2 {
        font-size: 1.625rem !important; }
      .san-pham-ct .box-product-2 .full-content h3 {
        font-size: 1.375rem !important; }
      .san-pham-ct .box-product-2 .full-content h4 {
        font-size: 1.25rem !important; }
      .san-pham-ct .box-product-2 .full-content h5 {
        font-size: 1.125rem !important; }
    .san-pham-ct .box-product-2 .wrap-button-drop {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px; }
      .san-pham-ct .box-product-2 .wrap-button-drop .bt-xem-them, .san-pham-ct .box-product-2 .wrap-button-drop .rut-gon {
        display: flex;
        cursor: pointer;
        align-items: center;
        user-select: none; }
        .san-pham-ct .box-product-2 .wrap-button-drop .bt-xem-them span, .san-pham-ct .box-product-2 .wrap-button-drop .rut-gon span {
          color: #00a7e6;
          font-size: 1rem;
          font-weight: 700; }
        .san-pham-ct .box-product-2 .wrap-button-drop .bt-xem-them em, .san-pham-ct .box-product-2 .wrap-button-drop .rut-gon em {
          color: #00a7e6; }
      .san-pham-ct .box-product-2 .wrap-button-drop .rut-gon {
        display: none; }

.section-other-product {
  background-color: #fff; }
  @media screen and (min-width: 1280px) {
    .section-other-product {
      padding-bottom: 90px; } }
  .section-other-product .title {
    color: #111111;
    font-size: 1.875rem;
    font-weight: 700;
    padding-bottom: 20px;
    border-bottom: 1px solid #00a7e6;
    margin-bottom: 30px; }
  .section-other-product .box-product-main {
    margin-bottom: 30px; }
    .section-other-product .box-product-main .img-other {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 82.05128%; }
      .section-other-product .box-product-main .img-other img, .section-other-product .box-product-main .img-other iframe, .section-other-product .box-product-main .img-other video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }

.san-pham-ds {
  background-color: #fff; }
  .san-pham-ds .box-filter-product {
    box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.11);
    background-color: #f8f8f8;
    padding: 25px 30px 5px 25px; }
    .san-pham-ds .box-filter-product + .box-filter-product {
      margin-top: 30px; }
    .san-pham-ds .box-filter-product .title {
      color: #00a7e6;
      font-size: 1.375rem;
      font-weight: 700;
      text-transform: uppercase; }
    .san-pham-ds .box-filter-product .list-menu {
      margin: 0;
      padding: 0;
      list-style: none; }
      .san-pham-ds .box-filter-product .list-menu .items + .items {
        border-top: 1px solid #e1e1e1; }
      .san-pham-ds .box-filter-product .list-menu .items.active .link {
        color: #72bf44; }
        .san-pham-ds .box-filter-product .list-menu .items.active .link::before {
          color: #72bf44; }
      .san-pham-ds .box-filter-product .list-menu .items .link {
        color: #232323;
        font-size: 1rem;
        font-weight: 700;
        position: relative;
        display: flex;
        transition: .3s all ease-in-out;
        padding: 15px 0px; }
        .san-pham-ds .box-filter-product .list-menu .items .link:hover {
          color: #72bf44; }
          .san-pham-ds .box-filter-product .list-menu .items .link:hover::before {
            color: #72bf44; }
        .san-pham-ds .box-filter-product .list-menu .items .link::before {
          content: 'arrow_right';
          font-family: "Material Icons";
          font-size: 22px;
          color: #999999;
          text-transform: none;
          display: flex;
          margin-top: -5px;
          margin-right: 5px;
          margin-left: -10px;
          transition: .3s all ease-in-out; }
    .san-pham-ds .box-filter-product .list-menu-checked {
      list-style: none;
      margin: 0;
      padding: 0; }
      .san-pham-ds .box-filter-product .list-menu-checked li + li {
        border-top: 1px solid #e1e1e1; }
      .san-pham-ds .box-filter-product .list-menu-checked li a {
        display: flex;
        align-items: center;
        padding: 10px 0px; }
        .san-pham-ds .box-filter-product .list-menu-checked li a .checked {
          margin-right: 10px;
          display: flex;
          align-items: center; }
          .san-pham-ds .box-filter-product .list-menu-checked li a .checked.active::before {
            content: 'check_box';
            color: #00a7e6; }
          .san-pham-ds .box-filter-product .list-menu-checked li a .checked::before {
            content: 'check_box_outline_blank';
            font-family: "Material Icons";
            text-transform: none;
            color: #999999;
            font-size: 0.875rem;
            font-weight: 400; }
        .san-pham-ds .box-filter-product .list-menu-checked li a .text-check {
          color: #232323;
          font-size: 1rem;
          font-weight: 700; }
  .san-pham-ds .box-product-show .box-product-main {
    margin-bottom: 30px; }
  .san-pham-ds .box-product-show .title-product {
    color: #111111;
    font-size: 1.875rem;
    font-weight: 700;
    border-bottom: 1px solid #00a7e6;
    padding-bottom: 15px;
    margin-bottom: 30px;
    margin-right: 20px; }
    @media screen and (max-width: 575px) {
      .san-pham-ds .box-product-show .title-product {
        font-size: 1.375rem; } }

.wrap-filter-product {
  position: fixed;
  left: 0;
  bottom: calc(-100% + 48px);
  opacity: 1;
  visibility: visible;
  width: 100%;
  height: 100%;
  z-index: 9999;
  transition: .3s all ease-in-out; }
  @media screen and (min-width: 1280px) {
    .wrap-filter-product {
      display: none; } }
  .wrap-filter-product.active {
    bottom: 0;
    opacity: 1;
    visibility: visible; }
    .wrap-filter-product.active .close-button {
      opacity: 1 !important;
      visibility: visible !important;
      transform: translateY(0px) !important; }
    .wrap-filter-product.active .material-icons {
      transform: rotate(180deg); }
  .wrap-filter-product .wrap-header {
    height: 48px;
    padding: 0 15px;
    background-color: #00a7e6;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 1024px) {
      .wrap-filter-product .wrap-header {
        display: flex; } }
    .wrap-filter-product .wrap-header .title {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between; }
      .wrap-filter-product .wrap-header .title em {
        margin-right: 10px;
        color: white;
        transition: .3s all ease-in-out;
        font-size: 18px; }
      .wrap-filter-product .wrap-header .title span {
        font-weight: 700;
        color: white;
        font-size: 18px; }
  .wrap-filter-product .wrap-main-content {
    padding: 20px;
    background-color: #fff;
    height: 100%; }
    .wrap-filter-product .wrap-main-content .close-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      margin-top: 40px;
      width: 100%;
      border: 1px dashed #00a7e6;
      transform: translateY(20px);
      opacity: 0;
      visibility: hidden;
      transition-delay: .25s;
      transition: .3s all ease-in-out; }
      .wrap-filter-product .wrap-main-content .close-button span {
        font-size: 1.25rem;
        font-weight: bold;
        margin-left: 5px;
        color: #00a7e6; }
      .wrap-filter-product .wrap-main-content .close-button em {
        font-size: 1.25rem;
        color: #00a7e6; }
    .wrap-filter-product .wrap-main-content .title {
      font-size: 18px;
      color: #00a7e6;
      font-weight: bold; }
    .wrap-filter-product .wrap-main-content .box-slide-price {
      padding: 0 20px; }
      .wrap-filter-product .wrap-main-content .box-slide-price .wrap-box {
        padding-left: 0;
        padding-right: 0;
        padding-top: 20px; }

.page-tai-lieu {
  background-color: #fff; }
  @media screen and (max-width: 1023.67px) {
    .page-tai-lieu .box-right {
      border-top: 1px dashed #eaeaea;
      margin-top: 30px;
      padding-top: 15px; } }
  .page-tai-lieu .content {
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.08);
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    padding: 0 30px;
    margin-top: 30px;
    padding-top: 5px; }
    .page-tai-lieu .content ul {
      margin: 0;
      padding: 0;
      list-style: none; }
    .page-tai-lieu .content li {
      padding-left: 19px; }
      .page-tai-lieu .content li a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 11px 0px; }
        .page-tai-lieu .content li a:hover span {
          color: #72bf44; }
        .page-tai-lieu .content li a:hover em {
          color: #72bf44; }
      .page-tai-lieu .content li + li {
        border-top: 1px solid #e1e1e1; }
      .page-tai-lieu .content li span {
        color: #111111;
        font-size: 1.125rem;
        font-weight: 400;
        transition: .3s all ease-in-out; }
      .page-tai-lieu .content li em {
        color: #999999;
        font-size: 1.125rem;
        transition: .3s all ease-in-out; }

.tin-tuc-ct {
  background-color: #fff; }
  .tin-tuc-ct .title-post {
    color: #111111;
    font-size: 1.875rem;
    font-weight: 700;
    padding-bottom: 15px;
    border-bottom: 1px solid #00a7e6; }
  .tin-tuc-ct .full-content p {
    color: #232323;
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    margin-top: 20px; }
  .tin-tuc-ct .full-content a {
    color: #00a7e6; }
  .tin-tuc-ct .full-content strong * {
    font-size: 1.25rem !important;
    font-weight: 700; }
  .tin-tuc-ct .full-content h1, .tin-tuc-ct .full-content h2, .tin-tuc-ct .full-content h3, .tin-tuc-ct .full-content h4, .tin-tuc-ct .full-content h5 {
    color: #00a7e6;
    margin-top: 20px; }
  .tin-tuc-ct .full-content h1 {
    font-size: 1.875rem !important; }
  .tin-tuc-ct .full-content h2 {
    font-size: 1.625rem !important; }
  .tin-tuc-ct .full-content h3 {
    font-size: 1.375rem !important; }
  .tin-tuc-ct .full-content h4 {
    font-size: 1.25rem !important; }
  .tin-tuc-ct .full-content h5 {
    font-size: 1.125rem !important; }
  @media screen and (max-width: 1023.67px) {
    .tin-tuc-ct .box-right {
      margin-top: 20px;
      border-top: 1px dashed #eaeaea;
      padding-top: 20px; } }
  .tin-tuc-ct .box-right .title-other {
    color: #00a7e6;
    font-size: 1.375rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 30px; }
  .tin-tuc-ct .box-right ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .tin-tuc-ct .box-right .box-post-other {
    box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.08);
    background-color: #f8f8f8;
    padding: 20px;
    margin-bottom: 20px;
    display: flex; }
    .tin-tuc-ct .box-right .box-post-other .img {
      flex: 0 0 90px;
      max-width: 90px; }
      .tin-tuc-ct .box-right .box-post-other .img a {
        display: block;
        width: 100%;
        height: 100%; }
        .tin-tuc-ct .box-right .box-post-other .img a img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .tin-tuc-ct .box-right .box-post-other .content {
      padding-left: 15px; }
    .tin-tuc-ct .box-right .box-post-other .date {
      color: #999999;
      font-size: 0.75rem;
      font-weight: 400;
      letter-spacing: 1.2px;
      display: block;
      margin-bottom: 10px; }
    .tin-tuc-ct .box-right .box-post-other .title {
      color: #00001a;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.2;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      transition: .3s all ease-in-out; }
      .tin-tuc-ct .box-right .box-post-other .title:hover {
        color: #72bf44; }

.tin-tuc-ds {
  background-color: #fff; }
  @media screen and (min-width: 1280px) {
    .tin-tuc-ds {
      padding-top: 81px;
      padding-bottom: 70px; } }
  .tin-tuc-ds .wrap-content-post .box-post {
    margin-bottom: 52px; }
    @media screen and (max-width: 575px) {
      .tin-tuc-ds .wrap-content-post .box-post {
        margin-bottom: 30px; } }
    .tin-tuc-ds .wrap-content-post .box-post .img a {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 65.38462%; }
      .tin-tuc-ds .wrap-content-post .box-post .img a img, .tin-tuc-ds .wrap-content-post .box-post .img a iframe, .tin-tuc-ds .wrap-content-post .box-post .img a video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
    .tin-tuc-ds .wrap-content-post .box-post .content {
      padding-top: 13px;
      padding-left: 18px; }
    .tin-tuc-ds .wrap-content-post .box-post .xem-them {
      margin-top: 13px; }
  .tin-tuc-ds .pagination {
    margin-top: -12px; }

.tuyen-dung-ct {
  background-color: #fff; }
  .tuyen-dung-ct .wrap-button-ut {
    margin-top: 30px;
    display: flex; }
    @media screen and (max-width: 575px) {
      .tuyen-dung-ct .wrap-button-ut {
        flex-wrap: wrap; } }
    .tuyen-dung-ct .wrap-button-ut .button-ut {
      padding-right: 48px;
      padding-left: 30px;
      min-width: 210px;
      height: 50px;
      border-radius: 40px 0;
      background-color: #d8efff;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      transition: .3s all ease-in-out; }
      @media screen and (max-width: 575px) {
        .tuyen-dung-ct .wrap-button-ut .button-ut {
          flex: 0 0 100%;
          width: 100%; } }
      .tuyen-dung-ct .wrap-button-ut .button-ut:hover {
        transform: translateY(-3px); }
      .tuyen-dung-ct .wrap-button-ut .button-ut:last-child {
        background-color: #eaf5e3; }
        .tuyen-dung-ct .wrap-button-ut .button-ut:last-child .icon-la {
          background-color: #72bf44; }
      .tuyen-dung-ct .wrap-button-ut .button-ut + .button-ut {
        margin-left: 30px; }
        @media screen and (max-width: 575px) {
          .tuyen-dung-ct .wrap-button-ut .button-ut + .button-ut {
            margin-left: 0;
            margin-top: 30px; } }
      .tuyen-dung-ct .wrap-button-ut .button-ut span {
        color: #111111;
        font-size: 18px;
        font-weight: 400; }
      .tuyen-dung-ct .wrap-button-ut .button-ut .icon-la {
        width: 40px;
        height: 40px;
        border-radius: 35px 0;
        background-color: #00a7e6;
        position: absolute;
        right: 5px; }
        .tuyen-dung-ct .wrap-button-ut .button-ut .icon-la em {
          transform: rotate(-45deg); }
  .tuyen-dung-ct .section-title {
    color: #111111;
    font-size: 1.875rem;
    font-weight: 700;
    padding-bottom: 17px;
    border-bottom: 1px solid #00a7e6; }
  .tuyen-dung-ct .box-infomation tr td:first-child {
    color: #0a0a0a;
    font-size: 1rem;
    font-weight: 700; }
  .tuyen-dung-ct .box-infomation tr td:last-child {
    color: #666666;
    font-size: 1rem;
    font-weight: 400; }
  .tuyen-dung-ct .full-content {
    margin-top: 30px; }
    .tuyen-dung-ct .full-content .img {
      width: 100%;
      height: 100%; }
      .tuyen-dung-ct .full-content .img img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .tuyen-dung-ct .full-content .box-infomation {
      padding-left: 26px;
      margin-top: -9px; }
      @media screen and (max-width: 1023.67px) {
        .tuyen-dung-ct .full-content .box-infomation {
          margin-top: 15px;
          border-top: 1px dashed #eaeaea;
          border-bottom: 1px dashed #eaeaea;
          padding: 10px 0px; } }
      .tuyen-dung-ct .full-content .box-infomation table td {
        padding: 6px 0px; }
        @media screen and (max-width: 1023.67px) {
          .tuyen-dung-ct .full-content .box-infomation table td {
            padding: 3px 0px; } }
        .tuyen-dung-ct .full-content .box-infomation table td:last-child {
          padding-left: 53px; }
    .tuyen-dung-ct .full-content .content {
      margin-top: 66px; }
      @media screen and (max-width: 1023.67px) {
        .tuyen-dung-ct .full-content .content {
          margin-top: 30px; } }
      .tuyen-dung-ct .full-content .content .section-title {
        margin-top: 60px; }
        @media screen and (max-width: 1023.67px) {
          .tuyen-dung-ct .full-content .content .section-title {
            margin-top: 30px; } }
      .tuyen-dung-ct .full-content .content ul {
        margin: 0;
        padding: 0;
        padding-top: 23px;
        padding-left: 18px; }
        .tuyen-dung-ct .full-content .content ul li {
          color: #232323;
          font-size: 1rem;
          font-family: 'Libre Franklin', sans-serif;
          font-weight: 400;
          letter-spacing: 0.8px; }
          .tuyen-dung-ct .full-content .content ul li + li {
            margin-top: 10px; }
          .tuyen-dung-ct .full-content .content ul li::marker {
            color: #999999;
            font-size: 20px; }
  .tuyen-dung-ct .box-left {
    position: sticky;
    top: 120px; }
    @media screen and (max-width: 1280px) {
      .tuyen-dung-ct .box-left {
        border-top: 1px dashed #eaeaea;
        padding-top: 20px;
        margin-top: 20px; } }
    .tuyen-dung-ct .box-left .title-sec {
      color: #00a7e6;
      font-size: 1.375rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 15px; }
    .tuyen-dung-ct .box-left .box-tuyen-dung {
      height: 110px;
      box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.08);
      background-color: #f8f8f8;
      padding: 20px;
      margin-bottom: 20px; }
      .tuyen-dung-ct .box-left .box-tuyen-dung .title {
        color: #232323;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 5px;
        display: block;
        transition: .3s all ease-in-out; }
        .tuyen-dung-ct .box-left .box-tuyen-dung .title:hover {
          color: #00a7e6; }
      .tuyen-dung-ct .box-left .box-tuyen-dung + .box-tuyen-dung {
        margin-top: 20px; }
      .tuyen-dung-ct .box-left .box-tuyen-dung .wrap-share-date {
        display: flex;
        justify-content: space-between; }
        .tuyen-dung-ct .box-left .box-tuyen-dung .wrap-share-date em {
          color: #999999;
          font-size: 1rem;
          font-weight: 400; }
        .tuyen-dung-ct .box-left .box-tuyen-dung .wrap-share-date span {
          color: #999999;
          font-size: 0.9375rem;
          font-weight: 400;
          margin-left: 6px; }
        .tuyen-dung-ct .box-left .box-tuyen-dung .wrap-share-date .location {
          display: flex;
          align-items: center; }
        .tuyen-dung-ct .box-left .box-tuyen-dung .wrap-share-date .date {
          display: flex;
          align-items: center; }
      .tuyen-dung-ct .box-left .box-tuyen-dung .salary {
        display: flex;
        align-items: center;
        margin-top: 6px; }
        .tuyen-dung-ct .box-left .box-tuyen-dung .salary em {
          color: #00aeef;
          font-size: 1rem;
          font-weight: 400; }
        .tuyen-dung-ct .box-left .box-tuyen-dung .salary span {
          color: #00a7e6;
          color: #00aeef;
          font-size: 0.9375rem;
          margin-left: 6px;
          font-weight: 400; }

.tuyen-dung-ds-2 {
  background-color: #e2f3ff; }
  .tuyen-dung-ds-2 .full-content {
    overflow: hidden; }
  @media screen and (max-width: 575px) {
    .tuyen-dung-ds-2 .title-36 {
      text-align: right; } }
  .tuyen-dung-ds-2 .desc {
    color: #232323;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 24px;
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px; }
    @media screen and (max-width: 767.67px) {
      .tuyen-dung-ds-2 .desc {
        max-width: 100%; } }
    @media screen and (max-width: 575px) {
      .tuyen-dung-ds-2 .desc {
        text-align: right; } }
  .tuyen-dung-ds-2 .box-tuyen-dung {
    padding: 13px 20px;
    height: 110px;
    border: 1px dashed #00aeef;
    background-color: #ffffff;
    margin-bottom: 20px; }
    @media screen and (max-width: 575px) {
      .tuyen-dung-ds-2 .box-tuyen-dung {
        height: auto; } }
    .tuyen-dung-ds-2 .box-tuyen-dung:hover .title-td {
      color: #00a7e6; }
    .tuyen-dung-ds-2 .box-tuyen-dung:hover .icon-view em {
      color: #00a7e6 !important; }
    .tuyen-dung-ds-2 .box-tuyen-dung .title-td {
      color: #232323;
      font-size: 1rem;
      font-weight: 700;
      text-transform: uppercase;
      transition: .3s all ease-in-out;
      margin-bottom: 5px;
      display: block; }
      .tuyen-dung-ds-2 .box-tuyen-dung .title-td:hover {
        color: #00a7e6; }
    .tuyen-dung-ds-2 .box-tuyen-dung .wrap-location {
      display: flex;
      align-items: center;
      margin-bottom: 6px; }
      @media screen and (max-width: 575px) {
        .tuyen-dung-ds-2 .box-tuyen-dung .wrap-location {
          flex-wrap: wrap; } }
      .tuyen-dung-ds-2 .box-tuyen-dung .wrap-location .location {
        display: flex;
        align-items: center; }
        @media screen and (max-width: 575px) {
          .tuyen-dung-ds-2 .box-tuyen-dung .wrap-location .location {
            flex: 0 0 100%; } }
        .tuyen-dung-ds-2 .box-tuyen-dung .wrap-location .location em {
          color: #999999;
          font-size: 1rem;
          font-weight: 400; }
        .tuyen-dung-ds-2 .box-tuyen-dung .wrap-location .location span {
          color: #999999;
          font-size: 0.9375rem;
          font-weight: 400;
          margin-left: 6px; }
      .tuyen-dung-ds-2 .box-tuyen-dung .wrap-location .date {
        display: flex;
        align-items: center;
        margin-left: 40px; }
        @media screen and (max-width: 575px) {
          .tuyen-dung-ds-2 .box-tuyen-dung .wrap-location .date {
            flex: 0 0 100%;
            margin-left: 0px;
            margin-top: 6px; } }
        .tuyen-dung-ds-2 .box-tuyen-dung .wrap-location .date em {
          color: #999999;
          font-size: 16px;
          font-weight: 400; }
        .tuyen-dung-ds-2 .box-tuyen-dung .wrap-location .date span {
          color: #999999;
          font-size: 0.9375rem;
          margin-left: 6px; }
    .tuyen-dung-ds-2 .box-tuyen-dung .wrap-salary {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .tuyen-dung-ds-2 .box-tuyen-dung .wrap-salary .icon-view em {
        padding-top: 6px;
        color: #72bf44;
        font-size: 16px;
        font-weight: 400;
        transition: .3s all ease-in-out; }
      .tuyen-dung-ds-2 .box-tuyen-dung .wrap-salary .salary {
        display: flex;
        align-items: center; }
        .tuyen-dung-ds-2 .box-tuyen-dung .wrap-salary .salary em {
          color: #00aeef;
          font-size: 1rem;
          font-weight: 400; }
        .tuyen-dung-ds-2 .box-tuyen-dung .wrap-salary .salary span {
          color: #00aeef;
          font-size: 0.9375rem;
          font-weight: 400;
          margin-left: 6px; }
  .tuyen-dung-ds-2 .wrap-button-drop {
    margin-top: 14px; }

.home-1 .wrap-flex {
  display: flex; }
  @media screen and (max-width: 767.67px) {
    .home-1 .wrap-flex {
      flex-wrap: wrap; } }
  .home-1 .wrap-flex .box-left {
    flex: 0 0 50%;
    max-width: 50%;
    height: 540px;
    background-image: url("../img/background-home-1.png");
    padding-right: 90px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-left: 90px; }
    @media screen and (max-width: 1280px) {
      .home-1 .wrap-flex .box-left {
        flex: 0 0 70%;
        max-width: 70%;
        padding-left: 5%;
        padding-right: 5%; } }
    @media screen and (max-width: 767.67px) {
      .home-1 .wrap-flex .box-left {
        flex: 0 0 100%;
        max-width: 100%;
        height: auto;
        padding-top: 5.625rem;
        padding-bottom: 5.625rem; } }
    @media screen and (max-width: 575px) {
      .home-1 .wrap-flex .box-left {
        padding-top: 3.75rem;
        padding-bottom: 3.75rem;
        align-items: flex-start; } }
    .home-1 .wrap-flex .box-left .title {
      color: #232323;
      font-size: 2.25rem;
      font-weight: 700;
      text-align: right;
      line-height: 1.2;
      margin-bottom: 18px; }
      @media (max-width: 575.98px) {
        .home-1 .wrap-flex .box-left .title {
          text-align: left; } }
    .home-1 .wrap-flex .box-left .desc {
      color: #000;
      font-size: 1.125rem;
      text-align: right;
      max-width: 519px;
      font-weight: 400;
      line-height: 24px; }
      @media screen and (max-width: 767.67px) {
        .home-1 .wrap-flex .box-left .desc {
          text-align: right; } }
  @media screen and (max-width: 767.67px) and (max-width: 575.98px) {
    .home-1 .wrap-flex .box-left .desc {
      text-align: left; } }
  .home-1 .wrap-flex .box-right {
    flex: 0 0 50%;
    max-width: 50%;
    height: 540px; }
    @media screen and (max-width: 1280px) {
      .home-1 .wrap-flex .box-right {
        flex: 0 0 30%;
        max-width: 30%;
        height: auto; } }
    @media screen and (max-width: 767.67px) {
      .home-1 .wrap-flex .box-right {
        flex: 0 0 100%;
        max-width: 100%; } }
    .home-1 .wrap-flex .box-right .img {
      width: 100%;
      height: 100%; }
      .home-1 .wrap-flex .box-right .img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top; }

.home-2 .wrap-box-flex {
  display: flex;
  flex-wrap: wrap; }
  .home-2 .wrap-box-flex .box-left, .home-2 .wrap-box-flex .box-right {
    flex: 0 0 50%;
    max-width: 50%;
    height: 540px; }
  .home-2 .wrap-box-flex .box-left {
    position: relative; }
    .home-2 .wrap-box-flex .box-left [class*="Module"] {
      height: 100%; }
    @media screen and (max-width: 1280px) {
      .home-2 .wrap-box-flex .box-left {
        flex: 0 0 30%;
        max-width: 30%; } }
    @media screen and (max-width: 767.67px) {
      .home-2 .wrap-box-flex .box-left {
        flex: 0 0 100%;
        max-width: 100%;
        order: 2;
        height: auto; } }
    .home-2 .wrap-box-flex .box-left .img {
      height: 100%;
      position: relative; }
      .home-2 .wrap-box-flex .box-left .img::before {
        content: '';
        position: absolute;
        left: 0;
        pointer-events: none;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #00000080; }
      .home-2 .wrap-box-flex .box-left .img a {
        display: block;
        height: 100%;
        width: 100%; }
        .home-2 .wrap-box-flex .box-left .img a img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .home-2 .wrap-box-flex .box-left .wrap-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 70%;
      padding: 0 15px;
      pointer-events: none; }
      @media screen and (max-width: 1280px) {
        .home-2 .wrap-box-flex .box-left .wrap-icon {
          width: 100%; } }
      .home-2 .wrap-box-flex .box-left .wrap-icon em {
        color: #ffffff;
        font-size: 5rem;
        font-weight: 400; }
      .home-2 .wrap-box-flex .box-left .wrap-icon .text {
        color: #ffffff;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.2;
        text-align: center;
        margin-top: 10px; }
  .home-2 .wrap-box-flex .box-right {
    display: flex;
    align-items: center;
    padding-left: 90px;
    padding-right: 210px; }
    .home-2 .wrap-box-flex .box-right [class*="Module"] {
      width: 100%; }
    @media screen and (max-width: 1280px) {
      .home-2 .wrap-box-flex .box-right {
        flex: 0 0 70%;
        max-width: 70%; } }
    @media screen and (max-width: 1600px) {
      .home-2 .wrap-box-flex .box-right {
        padding-right: 90px; } }
    @media screen and (max-width: 1023.67px) {
      .home-2 .wrap-box-flex .box-right {
        padding-left: 30px;
        padding-right: 30px; } }
    @media screen and (max-width: 767.67px) {
      .home-2 .wrap-box-flex .box-right {
        flex: 0 0 100%;
        max-width: 100%;
        order: 1;
        height: auto;
        padding: 30px 20px;
        padding-right: 20px !important; } }

.home-2 .wrap-slide {
  max-width: 660px;
  width: 100%; }
  @media screen and (max-width: 767.67px) {
    .home-2 .wrap-slide {
      max-width: 100%;
      padding: 0 15px; } }
  .home-2 .wrap-slide .title-36 {
    margin-bottom: 22px;
    text-align: left; }
  .home-2 .wrap-slide .box-content {
    height: 280px;
    border-radius: 35px;
    background-color: #ffffff;
    position: relative; }
    @media screen and (max-width: 575px) {
      .home-2 .wrap-slide .box-content {
        height: 220px; } }
    .home-2 .wrap-slide .box-content .img {
      height: 67%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px; }
      .home-2 .wrap-slide .box-content .img img {
        width: 100%;
        height: 70px;
        object-fit: contain; }
    .home-2 .wrap-slide .box-content .text {
      color: #232323;
      font-size: 1.125rem;
      font-weight: 400;
      text-align: center;
      line-height: 1.3;
      padding: 0 15%; }
      @media screen and (max-width: 575px) {
        .home-2 .wrap-slide .box-content .text {
          font-size: 1.1rem;
          padding: 0 5px; } }
    .home-2 .wrap-slide .box-content .icon-la {
      width: 40px;
      height: 40px;
      border-radius: 35px 0;
      background-color: #00a7e6;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 0;
      right: 0;
      transition: .3s all ease-in-out; }
      .home-2 .wrap-slide .box-content .icon-la:hover {
        background-color: #72bf44; }
      .home-2 .wrap-slide .box-content .icon-la em {
        transform: rotate(-45deg); }

.home-3 {
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
  background-color: #fff;
  z-index: 2; }
  .home-3::after {
    content: '';
    background-image: url("../img/h3-cay.png");
    position: absolute;
    max-width: 395px;
    width: 100%;
    pointer-events: none;
    height: 394px;
    bottom: 235px;
    pointer-events: none;
    right: 0;
    z-index: -1; }
    @media screen and (max-width: 1400px) {
      .home-3::after {
        bottom: 90px; } }
  .home-3::before {
    content: '';
    height: 235px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: #fff;
    left: 0;
    z-index: -1; }
    @media screen and (max-width: 1400px) {
      .home-3::before {
        height: 90px; } }
  .home-3 .title-36 {
    margin-top: 60px;
    margin-bottom: 30px; }
    @media screen and (max-width: 575px) {
      .home-3 .title-36 {
        margin-top: 30px; } }
  .home-3 .wrap .tabslet-tab {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 575px) {
      .home-3 .wrap .tabslet-tab {
        justify-content: space-around; } }
    .home-3 .wrap .tabslet-tab li.active .img a::before {
      background-color: rgba(0, 0, 0, 0); }
    .home-3 .wrap .tabslet-tab li.active a {
      color: #72bf44; }
    @media screen and (max-width: 575px) {
      .home-3 .wrap .tabslet-tab li {
        width: 140px; } }
    .home-3 .wrap .tabslet-tab li:hover .img a::before {
      background-color: rgba(0, 0, 0, 0); }
    .home-3 .wrap .tabslet-tab li:hover a {
      color: #72bf44; }
    .home-3 .wrap .tabslet-tab li .img {
      position: relative;
      display: flex;
      justify-content: center; }
      .home-3 .wrap .tabslet-tab li .img img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .home-3 .wrap .tabslet-tab li .img::after {
        content: '';
        background-image: url("../img/h3-shadow.png");
        position: absolute;
        width: 234px;
        height: 46px;
        left: 55%;
        transform: translateX(-50%);
        top: 100%; }
        @media screen and (max-width: 575px) {
          .home-3 .wrap .tabslet-tab li .img::after {
            display: none; } }
      .home-3 .wrap .tabslet-tab li .img a {
        position: relative;
        display: block;
        border-radius: 100%;
        overflow: hidden;
        width: 180px;
        border-radius: 100%;
        height: 180px; }
        @media screen and (max-width: 575px) {
          .home-3 .wrap .tabslet-tab li .img a {
            width: 140px;
            height: 140px; } }
        .home-3 .wrap .tabslet-tab li .img a::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.32);
          transition: .3s all ease-in-out; }
    .home-3 .wrap .tabslet-tab li .text {
      margin-top: 40px; }
      @media screen and (max-width: 575px) {
        .home-3 .wrap .tabslet-tab li .text {
          margin-top: 15px;
          text-align: center; } }
      .home-3 .wrap .tabslet-tab li .text a {
        font-size: 1rem;
        font-weight: 400;
        transition: .3s all ease-in-out; }
    .home-3 .wrap .tabslet-tab li + li {
      margin-left: 50px; }
      @media screen and (max-width: 575px) {
        .home-3 .wrap .tabslet-tab li + li {
          margin-left: 0; } }

.home-4 {
  padding-top: 13px;
  padding-bottom: 80px;
  position: relative;
  background-color: #fff;
  z-index: 2; }
  .home-4::before {
    content: '';
    background-image: url(../img/h4-4.png);
    position: absolute;
    left: 0;
    top: -40%;
    width: 183px;
    height: 241px;
    pointer-events: none;
    z-index: -1; }
    @media screen and (max-width: 1600px) {
      .home-4::before {
        top: 0; } }
  .home-4::after {
    content: '';
    width: 509px;
    height: 509px;
    background-image: url(../img/h4-3.png);
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: -1; }
  .home-4 .desc {
    color: #232323;
    font-size: 1.125rem;
    text-align: center;
    font-style: italic;
    line-height: 24px;
    line-height: normal; }
  .home-4 .wrap-title-icon .icon {
    margin-bottom: 12px; }
  .home-4 .wrap-product-main {
    margin-top: 40px; }
    .home-4 .wrap-product-main .col-lg-6:last-child .box-text {
      color: white; }
    .home-4 .wrap-product-main .box-product {
      border-radius: 0 0 60px;
      overflow: hidden;
      position: relative;
      margin-bottom: 30px; }
      .home-4 .wrap-product-main .box-product .box-text {
        color: #042070;
        font-size: 1.125rem;
        font-weight: 700;
        font-style: italic;
        line-height: 24px;
        position: absolute;
        top: 25%;
        left: 43px;
        text-shadow: 2px 2px 5px #00000026;
        max-width: 203px; }
        @media screen and (max-width: 575px) {
          .home-4 .wrap-product-main .box-product .box-text {
            left: 25px; } }
      .home-4 .wrap-product-main .box-product .icon-la {
        width: 80px;
        height: 80px;
        border-radius: 60px 0;
        background-color: #72bf44;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        cursor: pointer;
        right: 0;
        transition: .3s all ease-in-out; }
        .home-4 .wrap-product-main .box-product .icon-la:hover {
          background-color: #00a7e6; }
        .home-4 .wrap-product-main .box-product .icon-la em {
          color: #ffffff;
          font-size: 28px;
          font-weight: 400;
          transform: rotate(-45deg); }
      .home-4 .wrap-product-main .box-product .img a {
        display: block;
        height: 260px; }
        @media screen and (max-width: 767.67px) {
          .home-4 .wrap-product-main .box-product .img a {
            height: 200px; } }
        .home-4 .wrap-product-main .box-product .img a img {
          width: 100%;
          height: 100%;
          object-fit: cover; }

.home-5 {
  background-color: #fff; }
  @media screen and (min-width: 1280px) {
    .home-5 {
      padding-top: 2.8125rem;
      padding-bottom: 4.375rem; } }
  .home-5 .title-36 {
    margin-bottom: 25px; }
  .home-5 .button-190 {
    margin-top: 0; }
  .home-5 .box-post-tin {
    display: flex;
    padding-right: 30px;
    margin-bottom: 30px; }
    @media screen and (max-width: 575px) {
      .home-5 .box-post-tin {
        flex-wrap: wrap; } }
    .home-5 .box-post-tin .img {
      flex: 0 0 calc( 50% + 30px);
      max-width: calc( 50% + 30px);
      height: 285px;
      transition: all 500ms cubic-bezier(0.825, 0.09, 0.085, 1.01);
      outline: transparent solid 10px; }
      @media screen and (max-width: 575px) {
        .home-5 .box-post-tin .img {
          flex: 0 0 100%;
          max-width: 100%;
          height: 200px; } }
      .home-5 .box-post-tin .img:hover {
        outline: #00a7e6 solid 10px; }
      .home-5 .box-post-tin .img a {
        display: block;
        width: 100%;
        height: 100%; }
        .home-5 .box-post-tin .img a img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .home-5 .box-post-tin .content {
      flex: 0 0 calc( 50% + 30px);
      max-width: calc( 50% + 30px);
      margin-left: -60px;
      margin-top: 30px;
      height: 285px;
      padding-right: 15px;
      background-color: #fff;
      padding-top: 40px;
      padding-left: 30px; }
      @media screen and (max-width: 575px) {
        .home-5 .box-post-tin .content {
          flex: 0 0 100%;
          max-width: 100%;
          height: auto;
          margin-left: 30px;
          padding-bottom: 30px;
          margin-top: -90px; } }

.home-6 {
  background-color: #e6f8ff; }
  @media screen and (min-width: 1280px) {
    .home-6 {
      padding-top: 2.75rem; } }
  .home-6 .swiper-container .swiper-wrapper {
    padding: 0 20px; }
    @media screen and (max-width: 575px) {
      .home-6 .swiper-container .swiper-wrapper {
        padding: 0px; } }
  .home-6 .wrap-box-flex {
    display: flex;
    margin-top: 40px; }
    @media screen and (max-width: 575px) {
      .home-6 .wrap-box-flex {
        flex-wrap: wrap; } }
    .home-6 .wrap-box-flex .box-img {
      flex: 0 0 180px; }
      @media screen and (max-width: 575px) {
        .home-6 .wrap-box-flex .box-img {
          flex: 0 0 100%;
          margin-bottom: 40px;
          display: flex;
          justify-content: center; } }
      .home-6 .wrap-box-flex .box-img .img {
        width: 140px;
        height: 140px;
        border-radius: 100%;
        position: relative;
        z-index: 2; }
        .home-6 .wrap-box-flex .box-img .img::before {
          content: '';
          position: absolute;
          top: 56px;
          left: -31px;
          width: 162px;
          height: 88px;
          transform: rotate(-147deg);
          background-color: white;
          border: 6px solid #00a7e6;
          border-top-left-radius: 100%;
          border-top-right-radius: 100%;
          border-bottom: 0px;
          border-top-left-radius: 100px;
          border-top-right-radius: 100px;
          z-index: -1; }
        .home-6 .wrap-box-flex .box-img .img img {
          width: 100%;
          height: 100%;
          border: 2px solid #00a7e6;
          border-radius: 100%;
          object-fit: cover; }
    .home-6 .wrap-box-flex .content {
      flex: 1; }
      @media screen and (max-width: 575px) {
        .home-6 .wrap-box-flex .content {
          flex: 0 0 100%; } }
      .home-6 .wrap-box-flex .content .title-name {
        margin-bottom: 15px; }
        .home-6 .wrap-box-flex .content .title-name .title {
          font-size: 1.5rem;
          font-weight: 700;
          color: #00a7e6; }
        .home-6 .wrap-box-flex .content .title-name .pos {
          font-size: 1rem;
          font-weight: 400;
          color: #00a7e6; }
      .home-6 .wrap-box-flex .content .desc {
        color: #232323;
        font-size: 1.125rem;
        font-weight: 400;
        line-height: 24px;
        padding-right: 20px; }

.home-7 {
  background-color: #fff; }
  @media screen and (min-width: 1280px) {
    .home-7 {
      padding-top: 2.625rem; } }
  .home-7 .swiper-wrapper {
    align-items: flex-end !important; }
  .home-7 .desc {
    color: #232323;
    font-size: 1.125rem;
    font-weight: 400;
    text-align: center;
    display: block; }
  .home-7 .wrap-title {
    padding-bottom: 30px; }
    .home-7 .wrap-title .title-36 {
      margin-bottom: 5px; }
    @media screen and (max-width: 767.67px) {
      .home-7 .wrap-title {
        margin-bottom: 25px; } }
  .home-7 .wrap-slide {
    border-bottom: 1px solid #ebebeb;
    padding-top: 40px;
    padding-bottom: 54px;
    border-top: 1px solid #ebebeb; }
    @media screen and (max-width: 767.67px) {
      .home-7 .wrap-slide {
        padding-bottom: 20px;
        padding-top: 20px; } }
    .home-7 .wrap-slide .swiper-slide .img img {
      transition: .3s all ease-in-out; }

.home-8 {
  padding-top: 0;
  background-color: #fff; }
  @media screen and (max-width: 575px) {
    .home-8 .container {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0; } }
  .home-8 .button-190 {
    justify-content: center; }
  .home-8 .maps {
    position: relative;
    border-top: 1px solid #ebebeb;
    height: 350px; }
    .home-8 .maps img {
      width: 100%;
      height: 100%; }
  .home-8 .wrap-title-contact {
    position: absolute;
    top: 57%;
    width: 100%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    margin-top: -33px; }
  .home-8 .button-190 {
    box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.1);
    margin-top: 17px; }
    .home-8 .button-190 .icon-la {
      margin-left: 10px; }
    .home-8 .button-190 span {
      font-weight: bold; }

.home-9 {
  padding-top: 11px;
  padding-bottom: 90px;
  background-color: #fff; }
  @media screen and (max-width: 767.67px) {
    .home-9 {
      padding-top: 11px;
      padding-bottom: 30px; } }
  .home-9 .swiper-container {
    height: 100%; }
    .home-9 .swiper-container .swiper-slide {
      height: 100%; }
      .home-9 .swiper-container .swiper-slide .img {
        height: 100%; }
        .home-9 .swiper-container .swiper-slide .img img {
          height: 100%;
          object-fit: cover;
          width: 100%; }
  .home-9 .wrap-flex {
    width: 100%;
    display: flex; }
    @media screen and (max-width: 1400px) {
      .home-9 .wrap-flex {
        flex-wrap: wrap; } }
    .home-9 .wrap-flex .box-left {
      flex: 0 0 51%;
      max-width: 51%;
      display: flex; }
      @media screen and (max-width: 1400px) {
        .home-9 .wrap-flex .box-left {
          flex: 0 0 100%;
          max-width: 1260px;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 30px;
          padding-left: 15px;
          padding-right: 15px; } }
      @media screen and (max-width: 1280px) {
        .home-9 .wrap-flex .box-left {
          max-width: 960px; } }
      @media screen and (max-width: 1024px) {
        .home-9 .wrap-flex .box-left {
          max-width: 720px; } }
      @media screen and (max-width: 992px) {
        .home-9 .wrap-flex .box-left {
          max-width: 100%;
          padding-left: 0;
          padding-right: 0; } }
      .home-9 .wrap-flex .box-left .img {
        flex: 0 0 285px; }
        @media screen and (max-width: 767.67px) {
          .home-9 .wrap-flex .box-left .img {
            display: none; } }
      .home-9 .wrap-flex .box-left .box-content {
        flex: 1;
        padding-top: 38px;
        padding-left: 119px;
        padding-right: 80px; }
        @media screen and (max-width: 1600px) {
          .home-9 .wrap-flex .box-left .box-content {
            padding-left: 62px; } }
        @media screen and (max-width: 1400px) {
          .home-9 .wrap-flex .box-left .box-content {
            padding-left: 20px; } }
        @media screen and (max-width: 767.67px) {
          .home-9 .wrap-flex .box-left .box-content {
            padding-left: 30px;
            padding-right: 30px;
            align-items: center; } }
        .home-9 .wrap-flex .box-left .box-content .title-mail {
          margin-bottom: 10px; }
      .home-9 .wrap-flex .box-left .title-mail {
        color: #232323;
        font-size: 2.25rem;
        font-weight: 400; }
        @media screen and (max-width: 575px) {
          .home-9 .wrap-flex .box-left .title-mail {
            font-size: 1.875rem;
            text-align: center; } }
      .home-9 .wrap-flex .box-left .desc-mail {
        color: #232323;
        font-size: 1.125rem;
        margin-bottom: 10px; }
        @media screen and (max-width: 767.67px) {
          .home-9 .wrap-flex .box-left .desc-mail {
            text-align: center; } }
      .home-9 .wrap-flex .box-left .wrap-form, .home-9 .wrap-flex .box-left .subscribefrm {
        position: relative;
        width: 100%;
        margin-top: 10px; }
        .home-9 .wrap-flex .box-left .wrap-form span.fa-exclamation-triangle, .home-9 .wrap-flex .box-left .subscribefrm span.fa-exclamation-triangle {
          color: red;
          position: absolute;
          top: auto !important;
          bottom: 112% !important;
          right: 0;
          animation: AlertError 0.4s 1 linear !important; }
          .home-9 .wrap-flex .box-left .wrap-form span.fa-exclamation-triangle::before, .home-9 .wrap-flex .box-left .subscribefrm span.fa-exclamation-triangle::before {
            padding-right: 5px; }
        .home-9 .wrap-flex .box-left .wrap-form input, .home-9 .wrap-flex .box-left .subscribefrm input {
          width: 100%;
          height: 50px;
          border-radius: 40px 0;
          background-color: #f5f5f5;
          border: 0;
          padding-left: 35px;
          outline: 0; }
        .home-9 .wrap-flex .box-left .wrap-form button, .home-9 .wrap-flex .box-left .subscribefrm button {
          width: 40px;
          height: 40px;
          position: absolute;
          top: 5px;
          font-size: 0;
          right: 3px;
          border-radius: 35px 0;
          outline: 0;
          border: 0;
          background-color: #00a7e6;
          display: flex;
          align-items: center;
          justify-content: center; }
          .home-9 .wrap-flex .box-left .wrap-form button::before, .home-9 .wrap-flex .box-left .subscribefrm button::before {
            content: '';
            background-image: url(../img/right2.png);
            width: 14px;
            height: 14px;
            position: absolute;
            right: 13px;
            top: 13px; }
    .home-9 .wrap-flex .box-right {
      flex: 0 0 49%;
      max-width: 49%; }
      .home-9 .wrap-flex .box-right .img a {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 100%; }
        .home-9 .wrap-flex .box-right .img a img, .home-9 .wrap-flex .box-right .img a iframe, .home-9 .wrap-flex .box-right .img a video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; }
      @media screen and (max-width: 1400px) {
        .home-9 .wrap-flex .box-right {
          flex: 0 0 100%;
          max-width: 100%; } }

footer {
  background-color: #00a7e6;
  height: 480px;
  background-image: url("../img/bg-footer.png");
  background-position: center bottom -26px;
  background-repeat: no-repeat;
  padding-top: 60px; }
  @media screen and (max-width: 1024px) {
    footer {
      height: auto;
      padding-bottom: 30px; } }
  @media screen and (min-width: 1280px) {
    footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0; } }
  footer .box-logo-footer {
    display: flex;
    padding-right: 50px; }
  footer .title-footer {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 22px;
    text-transform: uppercase; }
  @media screen and (max-width: 1024px) {
    footer .box-logo-footer {
      margin-bottom: 30px;
      flex-wrap: wrap; } }
  footer .box-logo-footer .logo {
    flex: 0 0 127px;
    max-width: 127px; }
    @media screen and (max-width: 1024px) {
      footer .box-logo-footer .logo {
        flex: 0 0 100%;
        max-width: 100%; } }
  footer .box-logo-footer .wrap-list-menu {
    padding-left: 23px; }
    @media screen and (max-width: 1024px) {
      footer .box-logo-footer .wrap-list-menu {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        margin-top: 30px; } }
    footer .box-logo-footer .wrap-list-menu .des {
      color: rgba(255, 255, 255, 0.75);
      font-size: 0.875rem;
      font-weight: 400;
      margin-bottom: 17px;
      padding-right: 15px; }
    footer .box-logo-footer .wrap-list-menu .list-footer {
      margin: 0;
      padding: 0;
      list-style: none; }
      footer .box-logo-footer .wrap-list-menu .list-footer li {
        color: rgba(255, 255, 255, 0.75);
        font-size: 0.875rem;
        font-weight: 400; }
        footer .box-logo-footer .wrap-list-menu .list-footer li + li {
          margin-top: 10px; }
  @media screen and (max-width: 1024px) {
    footer .box-footer {
      margin-bottom: 30px; } }
  footer .box-footer.bf-4 {
    padding-left: 23px; }
    @media screen and (max-width: 1023.67px) {
      footer .box-footer.bf-4 {
        padding-left: 0; } }
  footer .box-footer .list-footer {
    margin: 0;
    padding: 0;
    list-style: none; }
    footer .box-footer .list-footer li a {
      opacity: 0.75;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 400;
      padding-bottom: 4px;
      position: relative;
      transition: background-size .6s ease;
      background-image: linear-gradient(180deg, transparent 95%, rgba(255, 255, 255, 0.75) 0);
      background-repeat: no-repeat;
      background-size: 0 100%; }
      footer .box-footer .list-footer li a:hover {
        background-size: 100% 100%; }
    footer .box-footer .list-footer li + li {
      margin-top: 6px; }
  footer .list-social {
    display: flex;
    align-items: center;
    margin-bottom: 25px; }
    footer .list-social .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 18px;
      background-color: rgba(0, 0, 0, 0.1);
      transition: .3s all ease-in-out;
      padding: 5px; }
      footer .list-social .icon:hover {
        transform: translateY(-3px) translateZ(0); }
      footer .list-social .icon + .icon {
        margin-left: 4px; }
      footer .list-social .icon em {
        color: #ffffff;
        font-size: 18px;
        font-weight: 400; }
  footer .sub-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    align-items: center; }
    @media screen and (max-width: 767.67px) {
      footer .sub-footer {
        flex-direction: column;
        margin-top: 30px;
        margin-bottom: 30px; } }
    footer .sub-footer .text {
      color: #ffffff;
      font-size: 0.625rem;
      font-style: italic;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-top: 5px; }
      footer .sub-footer .text a {
        position: relative;
        transition: background-size .6s ease;
        background-image: linear-gradient(180deg, transparent 95%, rgba(255, 255, 255, 0.75) 0);
        background-repeat: no-repeat;
        background-size: 0 100%; }
        footer .sub-footer .text a:hover {
          background-size: 100% 100%; }
    footer .sub-footer .wrap-link a {
      height: 12px;
      color: #ffffff;
      font-size: 0.625rem;
      font-style: italic;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding-bottom: 5px;
      position: relative;
      transition: background-size .6s ease;
      background-image: linear-gradient(180deg, transparent 95%, rgba(255, 255, 255, 0.75) 0);
      background-repeat: no-repeat;
      background-size: 0 100%; }
      footer .sub-footer .wrap-link a:hover {
        background-size: 100% 100%; }
      footer .sub-footer .wrap-link a + a {
        margin-left: 17px; }

@media screen and (min-width: 1280px) {
  main {
    position: relative;
    z-index: 1;
    margin-bottom: 480px; } }

header {
  position: fixed;
  z-index: 9999;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  transition: .3s all ease-in-out; }
  header.menu-opend nav::before {
    width: 100%; }
  @media screen and (max-width: 1280px) {
    header .container {
      max-width: 100%;
      padding-right: 10px;
      padding-left: 10px; } }
  header.active {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.09); }
  header nav {
    display: flex;
    height: 90px;
    position: relative; }
    header nav::before {
      content: '';
      position: absolute;
      top: calc( 100% + 20px);
      left: 0;
      width: 0;
      height: 1px;
      z-index: 99;
      transition: .4s all ease-in-out;
      transition-delay: .3s;
      background-color: #eaeaea; }
    @media screen and (max-width: 1280px) {
      header nav {
        height: 75px; } }
    header nav .logo {
      flex: 0 0 200px;
      max-width: 200px;
      display: flex;
      align-items: center;
      z-index: 2; }
      @media screen and (max-width: 575px) {
        header nav .logo {
          max-width: 150px; } }
      header nav .logo a {
        display: flex;
        align-items: center;
        height: 100%; }
  header .main-menu {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 9; }
    @media screen and (max-width: 1280px) {
      header .main-menu {
        justify-content: center; } }
  header .menu-1 {
    display: flex;
    padding-top: 5px;
    justify-content: flex-end;
    align-items: center; }
    header .menu-1 .wrap-button-hotline {
      display: flex; }
    header .menu-1 .wrap-search-lang {
      display: flex;
      margin-right: 20px; }
      @media screen and (max-width: 1280px) {
        header .menu-1 .wrap-search-lang {
          margin-right: 0px;
          background-color: #ebebeb;
          padding: 5px;
          border-radius: 20px; }
          header .menu-1 .wrap-search-lang .wrap-search {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin: 0;
            border-left: 0;
            border-right: 0;
            background-color: #00a7e6;
            width: 25px;
            height: 25px;
            border-radius: 100%; }
            header .menu-1 .wrap-search-lang .wrap-search em {
              color: white;
              font-size: 1.125rem; }
          header .menu-1 .wrap-search-lang .wrap-lang {
            padding: 0 10px;
            height: 100%; } }
    header .menu-1 .list-menu {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex; }
      @media screen and (max-width: 992px) {
        header .menu-1 .list-menu {
          display: none; } }
      header .menu-1 .list-menu .item.active .link {
        color: #00a7e6; }
      header .menu-1 .list-menu .item + .item {
        margin-left: 33px; }
      header .menu-1 .list-menu .link {
        color: #999898;
        font-size: 0.875rem;
        font-weight: 400;
        transition: .3s all ease-in-out; }
        header .menu-1 .list-menu .link:hover {
          color: #00a7e6; }
    header .menu-1 .wrap-search {
      border-right: 1px solid #ebebeb;
      border-left: 1px solid #ebebeb;
      padding: 0 15px;
      display: flex;
      align-items: center;
      margin: 0 15px; }
      header .menu-1 .wrap-search .search {
        display: flex;
        cursor: pointer; }
      header .menu-1 .wrap-search em {
        color: #999898;
        font-size: 1rem; }
    header .menu-1 .wrap-lang {
      display: flex;
      position: relative;
      align-items: center;
      cursor: pointer; }
      header .menu-1 .wrap-lang:hover .wrap-down {
        opacity: 1;
        visibility: visible; }
      header .menu-1 .wrap-lang span {
        color: #999898;
        font-size: 0.875rem;
        font-weight: 400;
        text-transform: uppercase; }
      header .menu-1 .wrap-lang em {
        color: #9a9999;
        font-size: 0.875rem;
        font-weight: 400;
        margin-left: 3px; }
      header .menu-1 .wrap-lang .wrap-down {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        z-index: 2;
        visibility: hidden;
        transition: .3s all ease-in-out;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); }
        header .menu-1 .wrap-lang .wrap-down a {
          display: block;
          text-align: center;
          padding: 5px 15px;
          background-color: #fff;
          transition: .3s all ease-in-out; }
          header .menu-1 .wrap-lang .wrap-down a:hover {
            background-color: #00a7e6;
            color: white; }
          header .menu-1 .wrap-lang .wrap-down a + a {
            border-top: 1px solid #f1f1f1; }
    @media screen and (max-width: 992px) {
      header .menu-1 .button-hotline {
        display: none; } }
  @media screen and (max-width: 1280px) {
    header .menu-2 {
      display: none; } }
  header .menu-2 .list-menu {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-end; }
    header .menu-2 .list-menu .item {
      height: 42px;
      position: relative;
      display: flex;
      align-items: center; }
      header .menu-2 .list-menu .item.active .link {
        color: #00a7e6; }
      header .menu-2 .list-menu .item.active::before {
        width: 100%; }
      header .menu-2 .list-menu .item:hover .link {
        color: #00a7e6; }
      header .menu-2 .list-menu .item:hover::before {
        width: 100%; }
      header .menu-2 .list-menu .item::before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 0%;
        height: 3px;
        background-color: #00a7e6;
        transform: translateX(-50%);
        transition: .3s all ease-in-out; }
      header .menu-2 .list-menu .item:first-child a {
        font-size: 0; }
        header .menu-2 .list-menu .item:first-child a::after {
          content: 'home';
          color: #00a7e6;
          font-family: "Material Icons";
          font-size: 1.5rem;
          font-weight: 400;
          text-transform: none;
          height: 18px;
          padding-right: 5px; }
      header .menu-2 .list-menu .item + .item {
        margin-left: 30px; }
      header .menu-2 .list-menu .item .link {
        color: #333333;
        font-size: 0.875rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.7px;
        display: inline-block;
        transition: .3s all ease-in-out;
        position: relative; }
  header .wrap-search-main {
    background-color: #00a7e6;
    padding: 10px 0px;
    padding-bottom: 15px;
    position: absolute;
    width: 100%;
    top: calc( 100% + 15px);
    opacity: 0;
    visibility: hidden;
    transition: .3s all ease-in-out;
    z-index: 99; }
    header .wrap-search-main .close {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      display: flex;
      cursor: pointer; }
      @media screen and (max-width: 1400px) {
        header .wrap-search-main .close {
          top: 100%;
          transform: translateY(0);
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          background-color: #00a7e6;
          border-radius: 0px 0px 20px 20px;
          transition: .9s all ease-in-out;
          right: -100%;
          transition-delay: .1s; } }
      header .wrap-search-main .close em {
        color: white;
        font-size: 30px; }
    header .wrap-search-main.active {
      opacity: 1;
      visibility: visible;
      top: 100%; }
      @media screen and (max-width: 1400px) {
        header .wrap-search-main.active .close {
          right: 0; } }
      header .wrap-search-main.active .searchbox button em {
        transform: translateY(0px);
        opacity: 1; }
      header .wrap-search-main.active .searchbox input {
        transform: translateX(0px);
        opacity: 1; }
      header .wrap-search-main.active .searchbox::before {
        width: 100%;
        opacity: 1; }
    header .wrap-search-main .searchbox {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%; }
      header .wrap-search-main .searchbox::before {
        content: '';
        width: 0%;
        opacity: 0;
        height: 1px;
        position: absolute;
        left: 0;
        top: calc( 100% + 5px);
        background-color: #fff;
        transition-delay: .1s;
        transition: .75s all ease-in-out; }
      header .wrap-search-main .searchbox input {
        flex: 1;
        border: 0;
        background-color: transparent;
        color: white;
        transition: .6s all ease-in-out;
        transition-delay: .2s;
        transform: translateX(-30px);
        opacity: 0; }
        header .wrap-search-main .searchbox input::placeholder {
          color: white; }
      header .wrap-search-main .searchbox button {
        outline: none;
        border: 0;
        background-color: transparent;
        display: flex;
        padding: 0; }
        header .wrap-search-main .searchbox button em {
          color: white;
          opacity: 0;
          transition-delay: .2s;
          transform: translateY(-5px);
          transition: .3s all ease-in-out; }
  header .main-menu-mobile {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transition-delay: .5s;
    transition: all 0.7s cubic-bezier(0.6, 0, 0, 1);
    padding-top: 45px; }
    header .main-menu-mobile::before {
      content: '';
      background-image: url("../img/icon-menu-mobile.png");
      width: 431px;
      height: 221px;
      position: absolute;
      bottom: 0;
      left: 0; }
    header .main-menu-mobile .wrap-button-hotline {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      overflow: hidden; }
      header .main-menu-mobile .wrap-button-hotline .button-hotline {
        transform: translateY(100%);
        transition: .3s all ease-in-out; }
        @media screen and (max-width: 575px) {
          header .main-menu-mobile .wrap-button-hotline .button-hotline {
            margin-left: 0px; } }
    header .main-menu-mobile.active {
      top: 75px;
      transition-delay: 0.2s; }
      header .main-menu-mobile.active .list-menu .item.active::before {
        width: 100%;
        transition: .3s all ease-in-out;
        transition-delay: 1.2s; }
      header .main-menu-mobile.active .wrap-button-hotline .button-hotline {
        transform: none;
        transition: transform 0.5s cubic-beziewr(0.3, 0.1, 0.3, 0.1) 0.8s, color 0.3s cubic-bezier(0.3, 0.1, 0.3, 0.1); }
      header .main-menu-mobile.active .list-menu .item {
        transition: all 0.5s;
        animation-duration: 0.25s;
        animation-fill-mode: both;
        animation-name: moveInTop; }
        header .main-menu-mobile.active .list-menu .item:nth-child(1) {
          animation-delay: .2s; }
        header .main-menu-mobile.active .list-menu .item:nth-child(2) {
          animation-delay: .4s; }
        header .main-menu-mobile.active .list-menu .item:nth-child(3) {
          animation-delay: .6s; }
        header .main-menu-mobile.active .list-menu .item:nth-child(4) {
          animation-delay: .8s; }
        header .main-menu-mobile.active .list-menu .item:nth-child(5) {
          animation-delay: 1s; }
        header .main-menu-mobile.active .list-menu .item:nth-child(6) {
          animation-delay: 1.2s; }
        header .main-menu-mobile.active .list-menu .item:nth-child(7) {
          animation-delay: 1.4s; }
        header .main-menu-mobile.active .list-menu .item:nth-child(8) {
          animation-delay: 1.6s; }
    header .main-menu-mobile .list-menu {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center; }
      header .main-menu-mobile .list-menu .item {
        overflow: hidden;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative; }
        header .main-menu-mobile .list-menu .item.active .link {
          color: #00a7e6; }
        header .main-menu-mobile .list-menu .item:hover .link {
          color: #00a7e6; }
        header .main-menu-mobile .list-menu .item:hover::before {
          width: 100%; }
        header .main-menu-mobile .list-menu .item::before {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          width: 0%;
          height: 3px;
          background-color: #00a7e6;
          transform: translateX(-50%);
          transition: .3s all ease-in-out;
          transition-delay: .2s; }
        header .main-menu-mobile .list-menu .item:first-child a {
          font-size: 0; }
          header .main-menu-mobile .list-menu .item:first-child a::after {
            content: 'home';
            color: #00a7e6;
            font-family: "Material Icons";
            font-size: 2rem;
            font-weight: 400;
            text-transform: none;
            height: 18px; }
        header .main-menu-mobile .list-menu .item + .item {
          margin-top: 1rem; }
        header .main-menu-mobile .list-menu .item .link {
          font-size: 2rem; }
          @media screen and (max-width: 992px) {
            header .main-menu-mobile .list-menu .item .link {
              font-size: 1.75rem; } }
          @media screen and (max-width: 575px) {
            header .main-menu-mobile .list-menu .item .link {
              font-size: 1.5rem; } }
