.login-panel
	background-color: #fff
	margin: 0 auto
	box-shadow: 4px 3px 12px rgba(0, 0, 0, 0.06)
	padding: 30px
	border-radius: 20px
	.headtitle
		font-size: r(36px)
		text-align: center
		color: $main
	.panel-heading
		text-align: center
		margin-bottom: 20px
	.btn-login
		input
			width: 100%
			background-color: $main
			color: white
			font-size: r(18px)
			padding: 5px 0px
			border: 0
			outline: none
			margin: 9px 0px
			transition: .3s all ease-in-out
			&:hover
				background-color: $green
	.form-group
		display: flex
		width: 100%
		&.forget
			display: flex
			align-items: center
			input
				margin-right: 10px
		&:nth-child(1),&:nth-child(2)
			border-bottom: 1px solid #eaeaea
			margin-bottom: 10px
			align-items: center
			padding: 5px 0px
			label
				i
					color: $main
			input
				width: 100%
				flex: 0 0 100%
				border: 0
				padding-left: 15px
				outline: 0
		&:nth-child(5)
			display: flex
			justify-content: space-between
			a
				transition: .3s all ease-in-out
				&:hover
					color: $main

	.panel-primary
		width: 100%
.login-page
	background-color: #fff
	padding: 40px 0px
.loginpage
	main
		padding-top: 90px
	.col-xs-12
		margin: 0 auto
