.page-bai-viet
	background-color: #fff
	.box-right
		@media screen and ( max-width: 1280px )
			margin-top: 30px
			border-top: 1px dashed #eaeaea
			padding-top: 10px
		.list-post-main
			margin-top: 30px
		.post-item
			display: flex
			@media screen and ( max-width: 767.67px )
				flex-wrap: wrap
			&+.post-item
				margin-top: 30px
			.img
				flex: 0 0 36.06%
				max-width: 36.06%
				@media screen and ( max-width: 767.67px )
					flex: 0 0 100%
					max-width: 100%
				a
					display: block
					height: 215px
					img
						width: 100%
						height: 100%
						object-fit: cover
			.content
				flex: 0 0 63.94%
				max-width: 63.94%
				padding: 25px 100px 25px 28px
				background-color: #fff
				box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.08)
				height: 215px
				display: flex
				flex-direction: column
				justify-content: space-between
				@media screen and ( max-width: 767.67px )
					flex: 0 0 100%
					max-width: 100%

