.san-pham-ct
	background-color: #fff
	.wrap-button
		display: flex
		align-items: center
		@media (max-width: 575.98px)
			flex-wrap: wrap
			.button-190
				flex: 0 0 100%
				max-width: 100%
				text-align: center
				span
					margin: 0 auto
					padding-left: 68px
		.btn-buy
			@media (min-width: 576px)
				margin-left: 20px
	.box-img-product
		// min-height: 600px
		padding-bottom: 40px
		display: flex
		flex-direction: column
		justify-content: space-between
		box-shadow: 3px 4px 25px rgba(0, 0, 0, 0.1)
		@media screen and ( max-width: 1023.67px )
			margin-bottom: 30px
			height: auto
		.wrap-img-top
			.img-big
				// min-height: 600px
				// padding: 60px
				@media screen and ( max-width: 992px )
					// min-height: 50vh
				@media screen and ( max-width: 575px )
					// padding: 20px
					// min-height: 30vh
				a
					+center-image()
					// display: flex
					// align-items: center
					// justify-content: center
					// width: 100%
					// overflow: hidden
					// height: 100%
					// img
					// 	transition: all .3s ease-in-out
					// 	max-width: 100%
					// 	max-height: 100%
					// 	object-fit: contain
		.wrap-slide-thums
			padding: 0 48px
			position: relative
			margin-top: 26px
			.swiper-slide-thumb-active
				.img
					border: 1px solid $green
			.img
				height: 70px
				border: 1px solid #ebebeb
				background-color: #ffffff
				cursor: grab
				display: flex
				align-items: center
				padding: 5px
				justify-content: center
				transition: .3s all ease-in-out
				overflow: hidden
				img
					transition: all .3s ease-in-out
					max-width: 100%
					max-height: 100%
					width: 100%
					// transform: scale(1.5)
					object-fit: contain

			.wrap-button-detail
				.button-prev,.button-next
					position: absolute
					top: 50%
					transform: translateY(-50%)
					display: flex
					align-items: center
					&:hover
						em
							color: $green
				.button-next
					right: 14px
				.button-prev
					left: 14px
				em
					color: #999999
					font-size: 24px
					font-weight: 400
					transition: .3s all ease-in-out
	.box-content-product
		height: 600px
		padding-left: 20px
		@media screen and ( max-width: 1280px )
			height: auto
			padding-bottom: 30px
			padding-left: 0px
			margin-top: 30px
		@media screen and ( max-width: 575px )
			margin-top: 0
		.title-product
			color: #111111
			font-size: r(30px)
			font-weight: 700
			padding-bottom: 17px
			border-bottom: 1px solid $main
			margin-bottom: 25px
			@media screen and ( max-width: 575px )
				font-size: r(25px)
		.desc
			*
				color: $text
				font-size: 1rem
				font-weight: 700
		// .list-content
		// 	border-top: 1px solid #eeeeee
		// 	margin-top: 20px
		// 	padding-top: 18px
		// 	border-bottom: 1px solid #eeeeee
		// 	padding-bottom: 18px
		ul
			margin: 0
			padding: 0
			border-top: 1px solid #eeeeee
			margin-top: 20px
			padding-top: 18px
			border-bottom: 1px solid #eeeeee
			padding-bottom: 18px
			padding-left: 19px
			li
				color: #666666
				font-size: 1rem
				font-weight: 400
				&+li
					margin-top: 10px
		.button-190
			.icon-la
				margin-left: 37px
		.wrap-share
			margin-top: 28px
			display: flex
			align-items: center
			em
				margin-left: 10px
				font-size: 22px
				color: $main
	.box-product-2
		margin-top: 80px
		@media screen and ( max-width: 1280px )
			margin-top: 20px
		.tabslet-tab
			display: flex
			align-items: center
			list-style: none
			margin: 0
			padding: 0
			&::-webkit-scrollbar
				height: 3px
			&::-webkit-scrollbar-track
				background: #eaeaea
			&::-webkit-scrollbar-thumb
				background: $main
			@media screen and ( max-width: 1023.67px )
				overflow: auto
				scroll-snap-type: x mandatory
			li
				height: 50px
				min-width: 200px
				background-color: rgba($main , 0.1)
				display: flex
				align-items: center
				justify-content: center
				transition: .3s all ease-in-out
				cursor: pointer
				scroll-snap-align: start
				&.active
					background-color: rgba($main , 1)
					a
						color: white
				&+li
					margin-left: 1px
				a
					color: $main
					font-size: 1rem
					font-weight: 400
		.full-content
			padding-top: 30px
			max-height: 700px
			overflow: hidden
			@media screen and ( max-width: 1230px )
				max-height: 530px
			@media screen and ( max-width: 992px )
				max-height: 300px
			@media screen and ( max-width: 575px )
				max-height: 150px
			&.active
				max-height: 100%
			p
				color: $text
				font-size: 1rem
				line-height: 24px
				margin-bottom: 20px
			h1,h2,h3,h4,h5
				color: $main
				margin-top: 20px
			h1
				font-size: r(30px) !important
			h2
				font-size: r(26px) !important
			h3
				font-size: r(22px) !important
			h4
				font-size: r(20px) !important
			h5
				font-size: r(18px) !important
		.wrap-button-drop
			display: flex
			flex-direction: column
			align-items: center
			margin-top: 30px
			.bt-xem-them,.rut-gon
				display: flex
				cursor: pointer
				align-items: center
				user-select: none
				span
					color: $main
					font-size: 1rem
					font-weight: 700
				em
					color: $main
			.rut-gon
				display: none
.section-other-product
	background-color: #fff
	@media screen and ( min-width: 1280px )
		padding-bottom: 90px
	.title
		color: #111111
		font-size: r(30px)
		font-weight: 700
		padding-bottom: 20px
		border-bottom: 1px solid $main
		margin-bottom: 30px
	.box-product-main
		margin-bottom: 30px
		.img
			// height: 320px
		.img-other
			+img-ratio(320/390)
			// display: flex
			// align-items: center
			// justify-content: center
			// width: 100%
			// overflow: hidden
			// height: 100%
			// img
			// 	transition: all .3s ease-in-out
			// 	max-width: 100%
			// 	max-height: 100%
			// 	object-fit: contain


