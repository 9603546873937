.he-thong-phan-phoi
	background-color: #fff
	.box-left
		.title
			color: $text
			font-size: 1rem
			font-weight: 700
			text-transform: uppercase
			margin-bottom: 10px
		.form-group,.select-wrap
			position: relative
			&::before
				position: absolute
				content: 'arrow_drop_down'
				color: #999999
				font-family: "Material Icons"
				font-size: 24px
				top: 50%
				transform: translateY(-50%)
				right: 20px
			&:last-child
				select
					color: #999999
					font-size: r(14px)
					font-weight: 400
			&+.select-wrap
				margin-top: 10px
			select
				width: 100%
				height: 40px
				border-radius: 30px 0
				background-color: #ffffff
				appearance: none
				color: $text
				font-size: r(14px)
				font-weight: 400
				border: 0
				padding-left: 29px
	.tabslet-tab
		display: flex
		align-items: center
		justify-content: center
		list-style: none
		margin: 0
		padding: 0
		margin-bottom: 30px
		@media screen and ( max-width: 767.67px )
			overflow: auto
			justify-content: normal
		li
			&.active
				a
					background-color: $main
					color: white
			&+li
				margin-left: 30px
				@media screen and ( max-width: 575px )
					margin-left: 5px
			a
				width: 250px
				height: 50px
				border-radius: 40px 0
				background-color: rgba($main, .1)
				color: #00aeef
				font-size: 1rem
				font-weight: 400
				display: flex
				align-items: center
				justify-content: center
				transition: .3s all ease-in-out
				&:hover
					background-color: rgba($main, 1)
					color: white
	.wrap-tim-kiem
		height: 170px
		padding: 25px 25px 20px 30px
		background-color: #f3f3f3
		border: 1px solid #e1e1e1
		border-bottom: 0px solid
	.wrap-form-list
		border: 1px solid
		height: 440px
		background-color: #fff
		overflow: auto
		border: 1px solid #e1e1e1
		padding: 15px 40px 15px 30px
		@media screen and ( max-width: 1280px )
			padding: 15px 15px 15px 15px
		@media screen and ( max-width: 1023.67px )
			height: 222px
		&::-webkit-scrollbar
			width: 5px
		&::-webkit-scrollbar-track
			background: #eaeaea
		&::-webkit-scrollbar-thumb
			background: $main
		ul
			margin: 0
			padding: 0
			list-style: none
			li
				cursor: pointer
				.img
					display: none
				&:hover
					.item
						.title
							color: $main
				&+li
					.item
						padding-top: 13px
		.item
			border-bottom: 1px solid #e1e1e1
			padding-bottom: 14.5px
			.title
				color: $text
				font-size: 1rem
				font-weight: 700
				text-transform: uppercase
				margin-bottom: 7px
				transition: .3s all ease-in-out
			li
				display: flex
				align-items: center
				em
					margin-right: 8px
			li
				&:last-child
					margin-top: 8px
			em
				color: #00aeef
				font-size: r(14px)
				font-weight: 400
			li
				color: #666666
				font-size: r(14px)
				font-weight: 400
	.wrap-flex
		display: flex
		flex-wrap: wrap
	.box-map
		flex: 0 0 68.3%
		max-width: 68.3%
		height: 610px
		[class*="Module"]
			height: 100%
			width: 100%
		@media screen and ( max-width: 1023.67px )
			flex: 0 0 100%
			max-width: 100%
			height: 50vh
		*
			width: 100%!important
			height: 100%!important
	.box-left
		flex: 0 0 31.70%
		max-width: 31.70%
		@media screen and ( max-width: 1023.67px )
			flex: 0 0 100%
			max-width: 100%
	.get-dir-button
		display: none
