.home-9
	padding-top: 11px
	padding-bottom: 90px
	background-color: #fff
	@media screen and ( max-width: 767.67px )
		padding-top: 11px
		padding-bottom: 30px
	.swiper-container
		height: 100%
		.swiper-slide
			height: 100%
			.img
				height: 100%
				img
					height: 100%
					object-fit: cover
					width: 100%
	.wrap-flex
		width: 100%
		display: flex
		@media screen and ( max-width: 1400px )
			flex-wrap: wrap
		.box-left
			flex: 0 0 51%
			max-width: 51%
			display: flex
			@media screen and ( max-width: 1400px )
				flex: 0 0 100%
				max-width: 1260px
				margin-left: auto
				margin-right: auto
				margin-bottom: 30px
				padding-left: 15px
				padding-right: 15px
			@media screen and ( max-width: 1280px )
				max-width: 960px
			@media screen and ( max-width: 1024px )
				max-width: 720px
			@media screen and ( max-width: 992px )
				max-width: 100%
				padding-left: 0
				padding-right: 0

			.img
				flex: 0 0 285px
				@media screen and ( max-width: 767.67px )
					display: none
			.box-content
				flex: 1
				// display: flex
				// flex-direction: column
				// justify-content: center
				padding-top: 38px
				padding-left: 119px
				padding-right: 80px
				@media screen and ( max-width: 1600px )
					padding-left: 62px
				@media screen and ( max-width: 1400px )
					padding-left: 20px
				@media screen and ( max-width: 767.67px )
					padding-left: 30px
					padding-right: 30px
					align-items: center
				.title-mail
					margin-bottom: 10px
			.title-mail
				color: $text
				font-size: r(36px)
				font-weight: 400
				@media screen and ( max-width: 575px )
					font-size: r(30px)
					text-align: center
			.desc-mail
				color: $text
				font-size: r(18px)
				margin-bottom: 10px
				@media screen and ( max-width: 767.67px )
					text-align: center
			.wrap-form,.subscribefrm
				position: relative
				width: 100%
				margin-top: 10px
				span.fa-exclamation-triangle
					color: red
					position: absolute
					top: auto!important
					bottom: 112%!important
					right: 0
					animation: AlertError 0.4s 1 linear!important
					&::before
						padding-right: 5px
				input
					width: 100%
					height: 50px
					border-radius: 40px 0
					background-color: #f5f5f5
					border: 0
					padding-left: 35px
					outline: 0
				button
					width: 40px
					height: 40px
					position: absolute
					top: 5px
					font-size: 0
					right: 3px
					border-radius: 35px 0
					outline: 0
					border: 0
					background-color: $main
					display: flex
					align-items: center
					justify-content: center
					&::before
						content: ''
						background-image: url(../img/right2.png)
						width: 14px
						height: 14px
						position: absolute
						right: 13px
						top: 13px
		.box-right
			flex: 0 0 49%
			max-width: 49%
			.img
				a
					+img-ratio(285/285)
			@media screen and ( max-width: 1400px )
				flex: 0 0 100%
				max-width: 100%
