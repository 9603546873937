.d-flex
	display: flex
.justify-between
	justify-content: space-between
.justify-center
	justify-content: center
.align-center
	align-items: center
.text-center
	text-align: center
.text-right
	text-align: right
.flex-wrap
	flex-wrap: wrap
.text-white
	color: #ffffff
.button-prev, .button-next
	cursor: pointer
.line-bottom
	border-bottom: 1px solid rgba($main, 0.28)
header
	.ActionMenu .ActionMenuBody
		top: 110%   !important
		bottom: auto  !important
.ActionMenu .ActionMenuBody
	top: auto   !important
	bottom: 110%  !important
img[data-src]
	opacity: 0
	transition: .5s ease-in-out
	max-width: 100%
	&.lazyloaded
		opacity: 1
.hambuger
	height: 25px
	display: none
	align-items: center
	justify-content: center
	width: 35px
	position: relative
	transition: .3s all ease-in-out
	margin-left: 20px
	@media screen and ( max-width: 1300px )
		display: flex
	&.active
		span
			&:first-child
				top: 18px
				left: 50%
				width: 0px
			&:nth-child(2)
				transform: rotate(45deg)
				background: $main
			&:nth-child(3)
				transform: rotate(-45deg)
				background: $main
			&:nth-child(4)
				top: 18px
				left: 50%
				width: 0px
	span
		display: block
		position: absolute
		left: 0
		transform: rotate(0deg)
		opacity: 1
		transition: .5s ease-in-out
		border-radius: 9px
		background: $main
		width: 100%
		height: 5px
		&:first-child
			top: 0
		&:nth-child(2), &:nth-child(3)
			top: 10px
		&:nth-child(4)
			top: 20px
.pagination
	display: flex
	align-items: center
	justify-content: center
	margin: 0
	padding: 0
	list-style: none
	margin-top: 30px
	li
		&:hover
			a,span
				background-color: #00aeef
				color: white
		&.active
			a,span
				background-color: #00aeef
				color: white
		&+li
			margin-left: 10px
		a,span
			width: 40px
			height: 40px
			background-color: rgba(#00aeef, .1)
			color: #00aeef
			font-size: 1rem
			display: flex
			transition: .3s all ease-in-out
			align-items: center
			justify-content: center
.wrap-button-drop
	display: flex
	flex-direction: column
	align-items: center
	margin-top: 30px
	.bt-xem-them,.rut-gon
		display: flex
		cursor: pointer
		align-items: center
		span
			color: $main
			font-size: 1rem
			font-weight: 700
		em
			color: $main
	.rut-gon
		display: none
.nav-scroll-1
	background-color: #fff
	height: 50px
	border-bottom: 1px solid #e8e8e8
	display: flex
	align-items: center
	@media screen and ( max-width: 800px )
		display: none
	ul
		list-style: none
		width: 100%
		display: flex
		align-items: center
		height: 100%
		justify-content: center
		li
			height: 100%
			display: flex
			align-items: center
			justify-content: center
			position: relative
			&:hover
				&::before
					width: 100%
				a
					color: $main
			&::before
				content: ''
				height: 2px
				background-color: $main
				position: absolute
				width: 0%
				bottom: -1px
				left: 50%
				transform: translateX(-50%)
				transition: .3s all ease-in-out
			&.active
				&::before
					width: 100%
				a
					color: $main

			a
				padding: 0 20px
				color: #666666
				font-size: r(14px)
				font-weight: 400
				text-transform: uppercase
				display: inline-block
				transition: .3s all ease-in-out
				&:hover
					color: $main
.category-page-gt
	padding: 0 15px
	@media screen and ( min-width: 1024px )
		display: none
	@media screen and ( max-width: 768px )
		position: fixed
		top: 100px
		left: -100%
		opacity: 0
		visibility: hidden
		transition: .3s all ease-in-out
		&.show-p
			left: 0
			opacity: 1
			visibility: visible
			z-index: 999
			width: 100%
	&.active
		.title-header
			width: 100%
			opacity: 1
			visibility: visible
		.list-menu-page
			width: 100%
			padding-left: 10px
			opacity: 1
			visibility: visible
			max-height: 100%
	.title-header
		display: none
		align-items: center
		background-color: $main
		padding: 7px 10px
		border-radius: 5px
		width: 43px
		overflow: hidden
		transition: .3s all ease-in-out
		@media screen and ( max-width: 768px )
			display: flex

		span
			font-weight: bold
			color: white
			white-space: nowrap
		em
			color: white
			margin-right: 10px
	.list-menu-page
		background-color: #fff
		padding-top: 5px
		padding-bottom: 5px
		list-style: none
		border-radius: 0px 0px 5px 5px
		box-shadow: 2px 3px 10px #0000001c
		overflow: hidden
		width: 0
		max-height: 0
		padding-left: 0
		opacity: 0
		visibility: hidden
		transition: .3s all ease-in-out
		li
			display: flex
			align-items: center
			&.active
				a
					color: $main
			a
				white-space: nowrap
				&.active
					color: $main
			&::before
				content: 'keyboard_arrow_right'
				font-family: 'Material Icons'
				font-size: 18px
				color: $main
				margin-right: 5px
.wrap-tab-link
	margin-bottom: 30px
	ul
		list-style: none
		margin: 0
		padding: 0
		display: flex
		justify-content: center
		@media screen and ( max-width: 800px )
			overflow: auto
			justify-content: flex-start
			scroll-snap-type: x mandatory
			padding-bottom: 5px

			&::-webkit-scrollbar
				height: 2px
			&::-webkit-scrollbar-track
				background: white
			&::-webkit-scrollbar-thumb
				background: $main
		li
			scroll-snap-align: start
			&+li
				margin-left: 29px
				@media screen and ( max-width: 575px)
					margin-left: 10px
			&.active
				a
					background-color: #00aeef
					color: white
			a
				width: 250px
				height: 50px
				border-radius: 40px 0
				background-color: #e5f7fd
				display: flex
				align-items: center
				justify-content: center
				color: #00aeef
				font-size: 1rem
				font-weight: 400
.wrap-share
	display: flex
	margin: 10px 0px
	border-bottom: 1px solid #e1e1e1
	padding-bottom: 10px
	@media screen and ( max-width: 575px )
		flex-wrap: wrap
	.date-current
		display: flex
		align-items: center
		@media screen and ( max-width: 575px )
			flex: 0 0 100%
		&::after
			content: ''
			width: 1px
			height: 30px
			background-color: $main
			margin-left: 20px
			margin-right: 20px
			@media screen and ( max-width: 575px )
				display: none

		span
			color: $text
			font-size: 1rem
			font-weight: 400
			margin-right: 20px
		p
			color: #999999
			font-size: r(14px)
			font-weight: 400
			letter-spacing: 1.4px
			padding-top: 2px
	.share-facebook
		display: flex
		align-items: center
		@media screen and ( max-width: 575px )
			flex: 0 0 100%
			margin-top: 10px
			border-top: 1px dashed #eaeaea
			padding-top: 10px
		span
			color: $text
			font-size: 1rem
			font-weight: 400
		em
			color: $main
			font-size: 20px
			margin-left: 10px
.box-left-category
	box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.08)
	background-color: #f8f8f8
	padding: 30px 30px 20px 30px
	@media screen and ( max-width: 1280px )
		margin-bottom: 30px
	&+.box-left-category
		margin-top: 30px
	.title
		color: $main
		font-size: r(22px)
		font-weight: 700
		text-transform: uppercase
		margin-bottom: 6px
	.list-menu
		margin: 0
		padding: 0
		list-style: none
		.items
			&.active
				.link
					color: $green
					&::before
						color: $green
			&+.items
				border-top: 1px solid #e1e1e1
			.link
				color: $text
				font-size: 1rem
				font-weight: 700
				position: relative
				display: flex
				transition: .3s all ease-in-out
				padding: 10px 0px
				&:hover
					color: $green
					&::before
						color: $green
				&::before
					content: 'arrow_right'
					font-family: "Material Icons"
					font-size: 22px
					color: #999999
					text-transform: none
					display: flex
					margin-top: -5px
					margin-right: 5px
					margin-left: -10px
					transition: .3s all ease-in-out
.title-mapping
	display: none
.product-detail-page .pagination
	display: none!important
.form-subber
	width: 100%
	margin-top: 20px
.visually-hidden
	position: absolute
	overflow: hidden
	clip: rect(0 0 0 0)
	height: 1px
	width: 1px
	margin: -1px
	padding: 0
	border: 0
.full-content
	position: relative
	.loading
		position: absolute
		background-color: rgba(255, 255, 255, 0.7)
		z-index: 2
		width: 100%
		height: 100%
		top: 0
		left: 0
		align-items: center
		justify-content: center
		display: none
		&.active
			display: flex
.Module
	.breadcrumb
		display: none
#page-banner
	.breadcrumb
		display: flex!important
.searchresults
	main
		background-color: #fff
