.title-36
	color: #ffffff
	font-size: r(36px)
	font-weight: 400
	text-align: center
	@media screen and ( max-width: 575px )
		font-size: 1.8rem
	&.bold
		font-weight: 700
	&.black
		color: $text
	&.center
		text-align: center
	&.mb
		margin-bottom: 30px
.wrap-title-icon
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	.icon
		margin-bottom: 23px
	.title36
		margin-bottom: 10px
	.des
		color: $text
		font-size: r(18px)
		font-style: italic
.secion-title-line
	color: #111111
	font-size: r(30px)
	font-weight: 700
	border-bottom: 1px solid $main
	padding-bottom: 15px
