.lien-he
	background-color: #fff
	.box-info-map
		width: 100%
		height: 380px
		box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1)
		border-radius: 35px 0
		border: 1px solid $main
		background-color: #ffffff
		padding: 35px 27px 50px 27px
		@media screen and ( max-width: 1023.67px )
			margin-bottom: 40px
			height: auto
		.title
			color: #111111
			font-size: r(24px)
			font-weight: 700
			text-transform: uppercase
			letter-spacing: 1.2px
			margin-bottom: 23px
			@media screen and ( max-width: 1280px )
				font-size: r(18px)
		ul
			list-style: none
			margin: 0
			padding: 0
			li
				display: flex
				&:first-child
					align-items: flex-start
					img
						padding-top: 5px
				&+li
					margin-top: 34px
					align-items: center
				img
					padding-top: 0px
				span
					color: #111111
					font-size: r(18px)
					font-weight: 400
					margin-left: 15px
					@media screen and ( max-width: 1280px )
						font-size: 1rem
	.wrap-form
		margin-top: -5px
		.form-group
			margin-bottom: 13px
			position: relative
		span.fa-exclamation-triangle
			position: absolute
			right: 15px
			top: 15px
			font-weight: 400!important
			font-style: italic
			font-size: .9rem
			animation: AlertError 0.4s 1 linear!important
			&::before
				display: none!important
		label
			color: $text
			font-size: r(18px)
			font-weight: 400
			margin-bottom: 7px
		input[type="text"]
			width: 100%
			height: 40px
			border-radius: 30px 0
			background-color: #f5f5f5
			border: 0
			padding-left: 20px
			outline: none
		textarea
			width: 100%
			height: 100px
			padding-left: 20px
			padding-top: 15px
			border: 0
			border-radius: 30px 0
			background-color: #f5f5f5
		input[type="submit"]
			width: 190px
			height: 50px
			border-radius: 40px 0
			background-color: #d8efff
			outline: none
			border: 0
			color: #111111
			font-size: r(18px)
			font-weight: 400
			transition: .3s all ease-in-out
		.frm-btnwrap
			.label
				display: none!important
		.frm-btn
			position: relative
			width: fit-content
			margin-left: auto
			margin-top: 18px

			&:hover
				input
					background-color: rgba($green , .7)
				.icon-la
					background-color: $green
			.icon-la
				width: 40px
				height: 40px
				border-radius: 35px 0
				display: flex
				pointer-events: none
				align-items: center
				justify-content: center
				background-color: $main
				position: absolute
				top: 5px
				transition: .3s all ease-in-out
				right: 5px
				em
					transform: rotate(-45deg)
	.box-map
		width: 100%
		height: 690px
		margin-top: 40px
		[class*="Module"]
			height: 100%
			width: 100%
		@media screen and ( max-width: 1023.67px )
			height: 40vh
		iframe
			width: 100%
			height: 100%
