.faq
	background-color: #fff
	@media screen and ( min-width: 1280px )
		padding-bottom: 71px
	.wrap-content
		margin-top: 30px
	.pagination
		margin-top: 19px!important
.list-faq
	padding-left: 30px
	padding-right: 30px
	background-color: #fff
	box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.08)
	height: auto
	min-height: auto
	cursor: pointer
	display: flex
	flex-direction: column
	@media screen and ( max-width: 767.67px )
		padding-left: 15px
		padding-right: 20px
	&.active
		.title
			color: $green
			&::before
				color: $green
				transform: translateY(-50%) rotate(180deg)
	&+.list-faq
		margin-top: 30px
	.title
		color: #111111
		font-size: r(18px)
		font-weight: 400
		background-color: #ffffff
		padding-top: 16px
		transition: .3s all ease-in-out
		position: relative
		padding-bottom: 17px
		padding-right: 20px
		@media screen and ( max-width: 575px )
			padding-right: 35px
		&::before
			content: 'arrow_drop_down'
			position: absolute
			transition: .3s all ease-in-out
			font-family: "Material Icons"
			color: #999999
			font-size: 22px
			text-transform: none
			position: absolute
			top: 50%
			transform: translateY(-50%)
			right: 0
	.content
		color: #111111
		font-size: r(18px)
		font-weight: 400
		background-color: #f8f8f8
		display: none
		padding: 20px 70px 20px 20px
		margin-bottom: 30px
		@media screen and ( max-width: 767.67px )
			padding: 20px
		p
			line-height: 1.2
