.banner-home
	.swiper-container
		width: 100%
		height: 100%
	.swiper-slide
		text-align: center
		font-size: 18px
		background: #fff
		display: flex
		justify-content: center
		align-items: center
		&-active
			.img
				img
					transform: scale(1.1)!important
	.box-banner
		position: relative
		height: 990px
		@media screen and ( max-width: 1600px )
			height: auto
		@media screen and ( max-width: 575px )
			height: 40vh
		.button-xemthem
			margin-top: 36px
		.box-text
			max-width: 803px
			width: 100%
			text-align: center
			position: absolute
			display: flex
			flex-direction: column
			align-items: center
			top: 15%
			left: 50%
			transform: translateX(-50%)
			padding: 0 15px
			@media screen and ( max-width: 1600px )
				top: 30%
			@media screen and ( max-width: 575px )
				top: 30%
			.title
				color: #ffffff
				font-size: r(36px)
				font-weight: 700
				line-height: 1.2
				animation-duration: 1.4s
				animation-fill-mode: both
				animation-name: fade-in
				@media screen and ( max-width: 992px )
					font-size: r(26px)
			.button-xemthem
				animation-delay: .3s
				animation-duration: 1.4s
				animation-fill-mode: both
				animation-name: fade-in
		.img
			height: 100%
			img
				height: 100%
				width: 100%
				object-fit: cover
				transition: all 5s cubic-bezier(.22, .44, 0, 1)
				transform: scale(1)
