.home-1
	.wrap-flex
		display: flex
		@media screen and ( max-width: 767.67px )
			flex-wrap: wrap
		.box-left
			flex: 0 0 50%
			max-width: 50%
			height: 540px
			background-image: url('../img/background-home-1.png')
			padding-right: 90px
			display: flex
			flex-direction: column
			align-items: flex-end
			justify-content: center
			padding-left: 90px
			@media screen and ( max-width: 1280px )
				flex: 0 0 70%
				max-width: 70%
				padding-left: 5%
				padding-right: 5%
			@media screen and ( max-width: 767.67px )
				flex: 0 0 100%
				max-width: 100%
				height: auto
				padding-top: r(90px)
				padding-bottom: r(90px)
			@media screen and ( max-width: 575px )
				padding-top: r(60px)
				padding-bottom: r(60px)
				align-items: flex-start
			.title
				color: $text
				font-size: r(36px)
				font-weight: 700
				text-align: right
				line-height: 1.2
				margin-bottom: 18px
				@media (max-width: 575.98px)
					text-align: left
			.desc
				color: #000
				font-size: r(18px)
				text-align: right
				max-width: 519px
				font-weight: 400
				line-height: 24px
				@media screen and ( max-width: 767.67px )
					text-align: right
					@media (max-width: 575.98px)
						text-align: left
		.box-right
			flex: 0 0 50%
			max-width: 50%
			height: 540px
			@media screen and ( max-width: 1280px )
				flex: 0 0 30%
				max-width: 30%
				height: auto
			@media screen and ( max-width: 767.67px )
				flex: 0 0 100%
				max-width: 100%
			.img
				width: 100%
				height: 100%
				img
					width: 100%
					height: 100%
					object-fit: cover
					object-position: top
