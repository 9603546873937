.tin-tuc-ds
	background-color: #fff
	@media screen and ( min-width: 1280px )
		padding-top: 81px
		padding-bottom: 70px
	.wrap-content-post
		.box-post
			margin-bottom: 52px
			@media screen and ( max-width: 575px )
				margin-bottom: 30px
			.img
				a
					+img-ratio(255/390)
			.content
				padding-top: 13px
				padding-left: 18px
			.xem-them
				margin-top: 13px
	.pagination
		margin-top: -12px
