footer
	background-color: $main
	height: 480px
	background-image: url("../img/bg-footer.png")
	background-position: center bottom -26px
	background-repeat: no-repeat
	padding-top: 60px
	@media screen and ( max-width: 1024px )
		height: auto
		padding-bottom: 30px
	@media screen and ( min-width: 1280px )
		position: fixed
		bottom: 0
		left: 0
		right: 0
	.box-logo-footer
		display: flex
		padding-right: 50px
	.title-footer
		color: #ffffff
		font-size: 1rem
		font-weight: 700
		margin-bottom: 22px
		text-transform: uppercase
	.box-logo-footer
		@media screen and ( max-width: 1024px )
			margin-bottom: 30px
			flex-wrap: wrap
		.logo
			flex: 0 0 127px
			max-width: 127px
			@media screen and ( max-width: 1024px )
				flex: 0 0 100%
				max-width: 100%
		.wrap-list-menu
			padding-left: 23px
			@media screen and ( max-width: 1024px )
				flex: 0 0 100%
				max-width: 100%
				padding-left: 0
				margin-top: 30px
			.des
				color: rgba(255, 255, 255, 0.75)
				font-size: r(14px)
				font-weight: 400
				margin-bottom: 17px
				padding-right: 15px
			.list-footer
				margin: 0
				padding: 0
				list-style: none
				li
					color: rgba(255, 255, 255, 0.75)
					font-size: r(14px)
					font-weight: 400
					&+li
						margin-top: 10px
	.box-footer
		@media screen and ( max-width: 1024px )
			margin-bottom: 30px
		&.bf-4
			padding-left: 23px
			@media screen and ( max-width: 1023.67px )
				padding-left: 0
		&.bf-2
		.list-footer
			margin: 0
			padding: 0
			list-style: none
			li
				a
					opacity: 0.75
					color: #ffffff
					font-size: r(14px)
					font-weight: 400
					padding-bottom: 4px
					+hover-underline(95%, rgba(255,255,255,0.75))
				&+li
					margin-top: 6px
	.list-social
		display: flex
		align-items: center
		margin-bottom: 25px
		.icon
			display: flex
			align-items: center
			justify-content: center
			width: 36px
			height: 36px
			border-radius: 18px
			background-color: rgba(0, 0, 0, 0.1)
			transition: .3s all ease-in-out
			padding: 5px
			&:hover
				transform: translateY(-3px) translateZ(0)
			&+.icon
				margin-left: 4px
			em
				color: #ffffff
				font-size: 18px
				font-weight: 400
	.sub-footer
		display: flex
		justify-content: space-between
		margin-top: 35px
		align-items: center
		@media screen and ( max-width: 767.67px )
			flex-direction: column
			margin-top: 30px
			margin-bottom: 30px
		.text
			color: #ffffff
			font-size: r(10px)
			font-style: italic
			text-transform: uppercase
			letter-spacing: 1px
			margin-top: 5px
			a
				+hover-underline(95%, rgba(255,255,255,0.75))
		.wrap-link
			a
				height: 12px
				color: #ffffff
				font-size: r(10px)
				font-style: italic
				text-transform: uppercase
				letter-spacing: 1px
				padding-bottom: 5px
				+hover-underline(95%, rgba(255,255,255,0.75))
				&+a
					margin-left: 17px
main
	@media screen and ( min-width: 1280px )
		position: relative
		z-index: 1
		margin-bottom: 480px
