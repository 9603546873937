.san-pham-ds
	background-color: #fff
	.box-filter-product
		box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.11)
		background-color: #f8f8f8
		padding: 25px 30px 5px 25px
		&+.box-filter-product
			margin-top: 30px
		.title
			color: $main
			font-size: r(22px)
			font-weight: 700
			text-transform: uppercase
		.list-menu
			margin: 0
			padding: 0
			list-style: none
			.items
				&+.items
					border-top: 1px solid #e1e1e1
				&.active
					.link
						color: $green
						&::before
							color: $green
				.link
					color: $text
					font-size: 1rem
					font-weight: 700
					position: relative
					display: flex
					transition: .3s all ease-in-out
					padding: 15px 0px
					&:hover
						color: $green
						&::before
							color: $green
					&::before
						content: 'arrow_right'
						font-family: "Material Icons"
						font-size: 22px
						color: #999999
						text-transform: none
						display: flex
						margin-top: -5px
						margin-right: 5px
						margin-left: -10px
						transition: .3s all ease-in-out
		.list-menu-checked
			list-style: none
			margin: 0
			padding: 0
			li
				&+li
					border-top: 1px solid #e1e1e1
				a
					display: flex
					align-items: center
					padding: 10px 0px
					.checked
						margin-right: 10px
						display: flex
						align-items: center
						&.active
							&::before
								content: 'check_box'
								color: $main
						&::before
							content: 'check_box_outline_blank'
							font-family: "Material Icons"
							text-transform: none
							color: #999999
							font-size: r(14px)
							font-weight: 400
					.text-check
						color: $text
						font-size: 1rem
						font-weight: 700
	.box-product-show
		.box-product-main
			margin-bottom: 30px
		.title-product
			color: #111111
			font-size: r(30px)
			font-weight: 700
			border-bottom: 1px solid $main
			padding-bottom: 15px
			margin-bottom: 30px
			margin-right: 20px
			@media screen and ( max-width: 575px )
				font-size: r(22px)
.wrap-filter-product
	position: fixed
	left: 0
	bottom: calc(-100% + 48px)
	opacity: 1
	visibility: visible
	width: 100%
	height: 100%
	z-index: 9999
	transition: .3s all ease-in-out
	@media screen and ( min-width:1280px )
		display: none
	&.active
		bottom: 0
		opacity: 1
		visibility: visible
		.close-button
			opacity: 1!important
			visibility: visible!important
			transform: translateY(0px)!important
		.material-icons
			transform: rotate(180deg)
	.wrap-header
		height: 48px
		padding: 0 15px
		background-color: $main
		border-radius: 20px 20px 0px 0px
		display: flex
		justify-content: space-between
		align-items: center
		@media screen and ( max-width: 1024px )
			display: flex
		.title
			display: flex
			align-items: center
			width: 100%
			justify-content: space-between
			em
				margin-right: 10px
				color: white
				transition: .3s all ease-in-out
				font-size: 18px
			span
				font-weight: 700
				color: white
				font-size: 18px
	.wrap-main-content
		padding: 20px
		background-color: #fff
		height: 100%
		.close-button
			display: flex
			justify-content: center
			align-items: center
			height: 50px
			margin-top: 40px
			width: 100%
			border: 1px dashed $main
			transform: translateY(20px)
			opacity: 0
			visibility: hidden
			transition-delay: .25s
			transition: .3s all ease-in-out
			span
				font-size: r(20px)
				font-weight: bold
				margin-left: 5px
				color: $main
			em
				font-size: r(20px)
				color: $main
		.title
			font-size: 18px
			color: $main
			font-weight: bold
		.box-slide-price
			padding: 0 20px
			.wrap-box
				padding-left: 0
				padding-right: 0
				padding-top: 20px
