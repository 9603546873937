header
	position: fixed
	z-index: 9999
	width: 100%
	left: 0
	right: 0
	top: 0
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.08)
	background-color: #ffffff
	transition: .3s all ease-in-out
	&.menu-opend
		nav
			&::before
				width: 100%
	@media screen and ( max-width: 1280px )
		.container
			max-width: 100%
			padding-right: 10px
			padding-left: 10px
	&.active
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.09)
	nav
		display: flex
		height: 90px
		position: relative
		&::before
			content: ''
			position: absolute
			top: calc( 100% + 20px )
			left: 0
			width: 0
			height: 1px
			z-index: 99
			transition: .4s all ease-in-out
			transition-delay: .3s
			background-color: #eaeaea
		@media screen and ( max-width: 1280px )
			height: 75px
		.logo
			flex: 0 0 200px
			max-width: 200px
			display: flex
			align-items: center
			z-index: 2
			@media screen and ( max-width: 575px )
				max-width: 150px
			a
				display: flex
				align-items: center
				height: 100%
	.main-menu
		flex: 1
		display: flex
		flex-direction: column
		justify-content: space-between
		z-index: 9
		@media screen and ( max-width: 1280px )
			justify-content: center
	.menu-1
		display: flex
		padding-top: 5px
		justify-content: flex-end
		align-items: center
		.wrap-button-hotline
			display: flex
		.wrap-search-lang
			display: flex
			margin-right: 20px
			@media screen and ( max-width: 1280px )
				margin-right: 0px
				background-color: #ebebeb
				padding: 5px
				border-radius: 20px
				.wrap-search
					display: flex
					align-items: center
					justify-content: center
					padding: 0
					margin: 0
					border-left: 0
					border-right: 0
					background-color: $main
					width: 25px
					height: 25px
					border-radius: 100%
					em
						color: white
						font-size: r(18px)
				.wrap-lang
					padding: 0 10px
					height: 100%
		.list-menu
			list-style: none
			margin: 0
			padding: 0
			display: flex
			@media screen and ( max-width: 992px )
				display: none
			.item
				&.active
					.link
						color: $main
				&+.item
					margin-left: 33px
			.link
				color: #999898
				font-size: r(14px)
				font-weight: 400
				transition: .3s all ease-in-out
				&:hover
					color: $main
		.wrap-search
			border-right: 1px solid #ebebeb
			border-left: 1px solid #ebebeb
			padding: 0 15px
			display: flex
			align-items: center
			margin: 0 15px
			.search
				display: flex
				cursor: pointer
			em
				color: #999898
				font-size: 1rem
		.wrap-lang
			display: flex
			position: relative
			align-items: center
			cursor: pointer
			&:hover
				.wrap-down
					opacity: 1
					visibility: visible
			span
				color: #999898
				font-size: r(14px)
				font-weight: 400
				text-transform: uppercase
			em
				color: #9a9999
				font-size: r(14px)
				font-weight: 400
				margin-left: 3px
			.wrap-down
				position: absolute
				top: 100%
				left: 50%
				transform: translateX(-50%)
				opacity: 0
				z-index: 2
				visibility: hidden
				transition: .3s all ease-in-out
				box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2)
				a
					display: block
					text-align: center
					padding: 5px 15px
					background-color: #fff
					transition: .3s all ease-in-out
					&:hover
						background-color: $main
						color: white
					&+a
						border-top: 1px solid #f1f1f1
		.button-hotline
			@media screen and ( max-width: 992px )
				display: none
	.menu-2
		@media screen and ( max-width: 1280px )
			display: none
		.list-menu
			margin: 0
			padding: 0
			list-style: none
			display: flex
			justify-content: flex-end
			.item
				height: 42px
				position: relative
				display: flex
				align-items: center
				&.active
					.link
						color: $main
					&::before
						width: 100%
				&:hover
					.link
						color: $main
					&::before
						width: 100%
				&::before
					content: ''
					position: absolute
					left: 50%
					bottom: 0
					width: 0%
					height: 3px
					background-color: $main
					transform: translateX(-50%)
					transition: .3s all ease-in-out
				&:first-child
					a
						font-size: 0
						&::after
							content: 'home'
							color: #00a7e6
							font-family: "Material Icons"
							font-size: r(24px)
							font-weight: 400
							text-transform: none
							height: 18px
							padding-right: 5px
				&+.item
					margin-left: 30px
				.link
					color: #333333
					font-size: r(14px)
					font-weight: 700
					text-transform: uppercase
					letter-spacing: 0.7px
					display: inline-block
					transition: .3s all ease-in-out
					position: relative
	.wrap-search-main
		background-color: $main
		padding: 10px 0px
		padding-bottom: 15px
		position: absolute
		width: 100%
		top: calc( 100% + 15px )
		opacity: 0
		visibility: hidden
		transition: .3s all ease-in-out
		z-index: 99
		.close
			position: absolute
			top: 50%
			transform: translateY(-50%)
			right: 20px
			display: flex
			cursor: pointer
			@media screen and ( max-width: 1400px )
				top: 100%
				transform: translateY(0)
				justify-content: center
				align-items: center
				width: 40px
				height: 40px
				background-color: $main
				border-radius: 0px 0px 20px 20px
				transition: .9s all ease-in-out
				right: -100%
				transition-delay: .1s
			em
				color: white
				font-size: 30px
		&.active
			opacity: 1
			visibility: visible
			top: 100%
			@media screen and ( max-width: 1400px )
				.close
					right: 0
			.searchbox
				button
					em
						transform: translateY(0px)
						opacity: 1
				input
					transform: translateX(0px)
					opacity: 1
				&::before
					width: 100%
					opacity: 1
		.searchbox
			display: flex
			align-items: center
			position: relative
			width: 100%
			&::before
				content: ''
				width: 0%
				opacity: 0
				height: 1px
				position: absolute
				left: 0
				top: calc( 100% + 5px )
				background-color: #fff
				transition-delay: .1s
				transition: .75s all ease-in-out
				// transition-timing-function: ease-in-out
			input
				flex: 1
				border: 0
				background-color: transparent
				color: white
				transition: .6s all ease-in-out
				transition-delay: .2s
				transform: translateX(-30px)
				opacity: 0
				&::placeholder
					color: white
			button
				outline: none
				border: 0
				background-color: transparent
				display: flex
				padding: 0
				em
					color: white
					opacity: 0
					transition-delay: .2s
					transform: translateY(-5px)
					transition: .3s all ease-in-out
	.main-menu-mobile
		position: fixed
		top: -100%
		left: 0
		width: 100%
		height: 100%
		background-color: #fff
		transition-delay: .5s
		transition: all .7s cubic-bezier(.6,0,0,1)
		padding-top: 45px
		&::before
			content: ''
			background-image: url("../img/icon-menu-mobile.png")
			width: 431px
			height: 221px
			position: absolute
			bottom: 0
			left: 0
		.wrap-button-hotline
			display: flex
			align-items: center
			justify-content: center
			margin-top: 30px
			overflow: hidden
			.button-hotline
				transform: translateY(100%)
				transition: .3s all ease-in-out
				@media screen and ( max-width: 575px )
					margin-left: 0px
		&.active
			top: 75px
			transition-delay: 0.2s
			.list-menu
				.item
					&.active
						&::before
							width: 100%
							transition: .3s all ease-in-out
							transition-delay: 1.2s
			.wrap-button-hotline
				.button-hotline
					transform: none
					transition: transform .5s cubic-beziewr(.3,.1,.3,.1) .8s,color .3s cubic-bezier(.3,.1,.3,.1)
			.list-menu
				.item
					transition: all 0.5s
					animation-duration: 0.25s
					animation-fill-mode: both
					animation-name: moveInTop
					&:nth-child(1)
						animation-delay: .2s
					&:nth-child(2)
						animation-delay: .4s
					&:nth-child(3)
						animation-delay: .6s
					&:nth-child(4)
						animation-delay: .8s
					&:nth-child(5)
						animation-delay: 1s
					&:nth-child(6)
						animation-delay: 1.2s
					&:nth-child(7)
						animation-delay: 1.4s
					&:nth-child(8)
						animation-delay: 1.6s
						// transform: none
						// transition: transform .3s cubic-bezier(.3,.1,.3,.1) .8s,color .3s cubic-bezier(.3,.1,.3,.1)
		.list-menu
			margin: 0
			padding: 0
			list-style: none
			display: flex
			flex-direction: column
			align-items: center
			.item
				overflow: hidden
				display: flex
				justify-content: center
				overflow: hidden
				position: relative
				&.active
					.link
						color: $main
				&:hover
					.link
						color: $main
					&::before
						width: 100%
				&::before
					content: ''
					position: absolute
					left: 50%
					bottom: 0
					width: 0%
					height: 3px
					background-color: $main
					transform: translateX(-50%)
					transition: .3s all ease-in-out
					transition-delay: .2s
				&:first-child
					a
						font-size: 0
						&::after
							content: 'home'
							color: #00a7e6
							font-family: "Material Icons"
							font-size: r(32px)
							font-weight: 400
							text-transform: none
							height: 18px
				&+.item
					margin-top: r(16px)
				.link
					font-size: r(32px)
					// transform: translateY(100%) translateZ(0)
					// transition: .2s all ease-in-out
					@media screen and ( max-width: 992px )
						font-size: r(28px)
					@media screen and ( max-width: 575px )
						font-size: 1.5rem



