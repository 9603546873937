.title-sp
	color: $text
	font-size: 1.2rem
	font-weight: 400
	line-height: 1.2
	transition: .3s all ease-in-out
	&:hover
		color: $main
.filed-sp
	color: #999999
	font-size: 1rem
	font-weight: 400
.box-product-main
	.img
		position: relative
		overflow: hidden
		box-shadow: 3px 4px 25px rgba(0, 0, 0, 0.1)
		z-index: 2
		background-color: #fff
		// padding: 40px
		// height: 235px
		@media screen and ( max-width: 992px )
			// padding: 30px
			// height: 200px
		&::before
			position: absolute
			content: ''
			width: 100%
			top: 100%
			left: 0
			height: 100%
			z-index: 3
			background-color: 	rgba($main ,.3 )
			transition: .3s all ease-in-out
			opacity: 0
			visibility: hidden
		&:hover
			&::before
				top: 0
				opacity: 1
				visibility: visible
			.button-190
				top: 45%
		.images-pic
			+img-ratio(235/285)
			// width: 100%
			// height: 100%
			// display: flex
			// align-items: center
			// justify-content: center
			// img
			// 	width: 100%
			// 	height: 100%
			// 	object-fit: cover
		.button-190
			position: absolute
			margin-top: 0
			top: 100%
			z-index: 5
			left: 50%
			transform: translateX(-50%)
			transition: all 600ms cubic-bezier(0.825, 0.09, 0.085, 1.01)
			background-color: #fff
			&:hover
				.icon-la
					background-color: #fff
			.icon-la
				background-color: $main
	.title-sp
		margin-top: 26px
		display: block
	.filed-sp
		margin-top: 18px
.post-title
	color: #00001a
	font-size: r(24px)
	font-weight: 700
	line-height: 1.2
	transition: .3s all ease-in-out
	&:hover
		color: $main
	+rgtext(4)
.title-post-20
	color: #00001a
	font-size: r(20px)
	font-weight: 700
	height: 82px
	line-height: 1.3
	transition: .3s all ease-in-out
	cursor: pointer
	&:hover
		color: $green
	+rgtext(3)
.post-date
	color: #999999
	font-size: r(14px)
	font-weight: 400
	margin-bottom: 8px
	display: block
	letter-spacing: 1.4px
.xem-them
	color: $main
	font-size: 1rem
	font-weight: 700
	display: flex
	align-items: center
	margin-top: 21px
	&:hover
		em
			margin-left: 15px
	em
		color: $main
		font-size: 16px
		font-weight: 400
		margin-left: 7px
		transition: .3s all ease-in-out
